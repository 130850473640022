import {
  CustomReward,
  CustomRewardCurrency,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import RewardRedeemButton from "./RewardRedeemButton"
import { useFamilyQuery } from "../../../networking/queries"
import { useCustomRewardBalancesQuery } from "../../../networking/rewards/queries"
import { FamilyQuerySelect } from "../../../types"

type Props = {
  childId: number
  reward: CustomReward
  style?: React.CSSProperties
}

const RewardChildRedeemCard = ({ childId, reward, style }: Props) => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { data: rewardsBalances } = useCustomRewardBalancesQuery()

  const rewardBalance = rewardsBalances?.find(
    (balance) => balance.user_id === childId && balance.reward.id === reward.id
  )
  const currencyText = `${
    rewardBalance?.reward.currency &&
    rewardBalance.reward.currency !== CustomRewardCurrency.ITEM
      ? ` ${rewardBalance.reward.currency}${
          rewardBalance.balance > 1 ? "s" : ""
        }`
      : ""
  }`

  const child = children?.find((child) => child.user.id === childId)

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: "1px solid JoonUIColor.border.default",
        borderRadius: SPACING.space2,
        background: JoonUIColor.background.primaryNeutral,
        ...style,
      }}
    >
      <FlexBox
        direction="row"
        align="center"
        justify="flex-start"
        wrap={false}
        gap={SPACING.space1}
        style={{ width: "60%" }}
      >
        <Typography
          variant="caption"
          style={{
            backgroundColor: rewardBalance?.balance
              ? JoonColorExpanded.green200
              : JoonUIColor.icon.light,
            padding: `${SPACING.space05} ${SPACING.space1}`,
            borderRadius: SPACING.space1,
            whiteSpace: "nowrap",
          }}
        >
          {rewardBalance?.balance || 0}
          {currencyText}
        </Typography>
        <Typography
          variant="bodySmall"
          textAlign="left"
          style={{
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {child?.nickname ?? child?.user.name}
        </Typography>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        justify="flex-end"
        gap={SPACING.space1}
        wrap={false}
        style={{
          width: "40%",
        }}
      >
        {rewardBalance?.balance ? (
          <RewardRedeemButton rewardBalance={rewardBalance} childId={childId} />
        ) : (
          <Typography
            variant="bodySmall"
            color={JoonUIColor.text.secondary}
            style={{
              textAlign: "right",
              fontStyle: "italic",
            }}
          >
            Nothing to redeem!
          </Typography>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default RewardChildRedeemCard
