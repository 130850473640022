import {
  CalendarFullSolidIcon,
  FlexBox,
  HandshakeSimpleIcon,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  StarIcon,
  TrophyIcon,
  Typography,
} from "@joonapp/web-shared"

import { getQuestionnaireAnswers } from "./getQuestionnaireAnswers"
import PlanPaywallListCard from "./PlanPaywallListCard"
import { ACTIVE_COACHING_LOOKUP_KEY } from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { useCoachSubscriptionPackagesQuery } from "../../../../networking/queries"

const PlanPaywallList = () => {
  const { primaryGoal, lifeAreasAffected, biggestChallenge } =
    getQuestionnaireAnswers()
  const isMobile = useMobile()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()
  const coachingStandardMonthly =
    subscriptionPackages?.[ACTIVE_COACHING_LOOKUP_KEY]
  const sessionsText = () => {
    switch (coachingStandardMonthly?.num_sessions) {
      case 0:
        return ""
      case 1:
        return "One 45-minute video call each month"
      case 2:
        return "Two 45-minute video calls each month"
      case 4:
        return "Four 45-minute video calls each month"
      default:
        return "Two 45-minute video calls each month"
    }
  }

  const collaborationText = ["Anytime direct messaging", sessionsText()]

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space3}
      align="flex-start"
      style={{ position: "relative", zIndex: 0 }}
    >
      <PlanPaywallListCard
        icon={StarIcon}
        title="Your goal sets our north star..."
        text={primaryGoal}
      />
      <PlanPaywallListCard
        icon={TrophyIcon}
        title="Together we'll achieve..."
        text={lifeAreasAffected}
      />
      <PlanPaywallListCard
        icon={HandshakeSimpleIcon}
        title="We'll collaborate through..."
        text={collaborationText}
      />
      <PlanPaywallListCard
        icon={CalendarFullSolidIcon}
        title="During our first meeting, we'll get started with..."
        text={biggestChallenge}
      />
      <FlexBox
        direction="column"
        align="flex-start"
        justify="flex-start"
        wrap={false}
        gap={SPACING.space1}
        style={{
          borderRadius: SPACING.space2,
          padding: SPACING.space2,
          backgroundColor: "#8677A7",
        }}
      >
        <Typography
          variant={isMobile ? "bodyXSmall" : "bodySmall"}
          color={JoonUIColor.text.inverted}
          textAlign="left"
          style={{ whiteSpace: "pre-line" }}
        >
          {
            "My goal is to help you tackle these challenges and become the confident parent you want to be.\n\nSo let's get started!"
          }
        </Typography>
      </FlexBox>
      <div
        style={{
          position: "absolute",
          height: "100%",
          left: "23px",
          backgroundColor: "transparent",
          borderLeft: `2px dotted ${JoonColorExpanded.purple500}`,
          zIndex: -1,
        }}
      />
    </FlexBox>
  )
}

export default PlanPaywallList
