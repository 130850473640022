import { CSSProperties } from "react"

import NotificationDot from "../../components/notificationDot/NotificationDot"
import { useFamilyUnreadMailQuery } from "../../networking/queries"

type Props = {
  size: CSSProperties["height"]
}

export default function UnreadMailNotificationDot({ size }: Props) {
  const { data: unreadMail } = useFamilyUnreadMailQuery()
  if (unreadMail === undefined || !unreadMail.length) return <></>
  return <NotificationDot size={size} position="top-right" />
}
