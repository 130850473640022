import { useMemo } from "react"

import { useFamilyQuery } from "../../../networking/queries"
import {
  useCustomRewardBalancesQuery,
  useCustomRewardLogsStatsQuery,
  useCustomRewardsQuery,
} from "../../../networking/rewards/queries"
import { FamilyQuerySelect } from "../../../types"
import { useRewardsChildToggleHeaderStore } from "../redeem/RewardsChildToggleHeader"

// This hook is used to get data about rewards and balances
// Also will get calculated values for the child selected
// on the Purchases page

const useRewards = () => {
  const { data: logs, isLoading: isLoadingLogs } =
    useCustomRewardLogsStatsQuery()
  const { data: balances, isLoading: isLoadingBalances } =
    useCustomRewardBalancesQuery()
  const { selectedChildId } = useRewardsChildToggleHeaderStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { data: rewards, isLoading: isLoadingRewards } = useCustomRewardsQuery()

  const hasPreviouslyPurchasedOrRedeemedRewards =
    (logs?.redeemed_all_time ?? 0) + (logs?.purchased_all_time ?? 0) > 0

  const selectedChild = children?.find(
    (child) => child.user.id === selectedChildId
  )

  const childRewardBalances =
    useMemo(
      () => balances?.filter((balance) => balance.user_id === selectedChildId),
      [balances, selectedChildId]
    ) || []

  const childHasAvailableRewardsToPurchase =
    selectedChildId !== null &&
    rewards?.some((reward) => reward.user_ids.includes(selectedChildId))

  return {
    hasPreviouslyPurchasedOrRedeemedRewards,
    selectedChild,
    childRewardBalances,
    isLoadingBalances,
    isLoadingLogs,
    isLoadingRewards,
    childHasAvailableRewardsToPurchase,
    isLoading: isLoadingBalances || isLoadingLogs || isLoadingRewards,
  }
}

export default useRewards
