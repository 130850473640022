import { JoonColorExpanded } from "@joonapp/web-shared"
import { CSSProperties } from "react"

type Props = {
  size?: CSSProperties["height"]
  style?: CSSProperties
  position?: "top-right" | "relative"
}

export default function NotificationDot({
  size = "7px",
  style,
  position = "relative",
}: Props) {
  const positionStyles = (() => {
    switch (position) {
      case "top-right":
        return {
          position: "absolute",
          top: "-4px",
          right: "-4px",
        }
      case "relative":
      default:
        return {
          position: "relative",
        }
    }
  })() as CSSProperties

  return (
    <div
      style={{
        height: size,
        width: size,
        borderRadius: "50%",
        background: JoonColorExpanded.red400,
        ...positionStyles,
        ...style,
      }}
    />
  )
}
