import {
  UserRole,
  SessionBillingPeriod,
  SessionBooking,
} from "@joonapp/web-shared"

import { createJoonAPIClient } from "../../util/joon-api"

export const getSessionBillingPeriods = async (groupId: number) => {
  const API = createJoonAPIClient()
  const response = await API.get<{ results: SessionBillingPeriod[] }>(
    "/api/session-billing-periods",
    { params: { group_id: groupId } }
  )
  return response.data.results
}

export const getSessionBookings = async (groupId?: number) => {
  const params = groupId ? { group_id: groupId } : {}

  const API = createJoonAPIClient()
  const response = await API.get<{ results: SessionBooking[] }>(
    "/api/session-bookings",
    { params }
  )
  return response.data.results
}

export const getUpcomingSessions = async () => {
  const API = createJoonAPIClient()
  const response = await API.get<{ results: SessionBooking[] }>(
    "/api/session-bookings/next-sessions"
  )
  return response.data.results
}

export const getSessionBooking = async (sessionId: number) => {
  const API = createJoonAPIClient()
  const response = await API.get<SessionBooking>(
    `/api/session-bookings/${sessionId}`
  )
  return response.data
}

export const updateSessionNotes = async ({
  noteId,
  note,
  visibilityRole,
}: {
  noteId: number
  note?: string
  visibilityRole?: UserRole | null // null sets visibility to public
}) => {
  const API = createJoonAPIClient()
  const response = await API.put(`/api/session-notes/${noteId}`, {
    note,
    visibility_role: visibilityRole,
  })
  return response.data
}
