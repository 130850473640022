import { useMutation } from "@tanstack/react-query"
import toast from "react-hot-toast"

import {
  createCustomReward,
  deleteCustomReward,
  editCustomReward,
  purchaseCustomReward,
  redeemCustomReward,
  undoCustomRewardLog,
} from "./api"
import { QUERY_KEYS } from "../../constants"
import { queryClient } from "../../util/queryClient"

export const useEditRewardMutation = () =>
  useMutation({
    mutationFn: editCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      toast.success("Reward updated successfully!")
    },
  })

export const useAddRewardMutation = () =>
  useMutation({
    mutationFn: createCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      toast.success("Reward created successfully!")
    },
  })

export const usePurchaseRewardMutation = () =>
  useMutation({
    mutationFn: purchaseCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
    },
  })

export const useRedeemRewardMutation = () =>
  useMutation({
    mutationFn: redeemCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
    },
  })

export const useDeleteRewardMutation = () =>
  useMutation({
    mutationFn: deleteCustomReward,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      toast.success("Reward deleted successfully!")
    },
  })

export const useUndoCustomRewardLogMutation = () =>
  useMutation({
    mutationFn: undoCustomRewardLog,
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_LOGS])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARD_BALANCES])
      queryClient.invalidateQueries([QUERY_KEYS.CUSTOM_REWARDS])
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
    },
  })
