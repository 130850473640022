import {
  FlexBox,
  Modal,
  ModalHeader,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import { useFamilyQuery, useUserQuery } from "../../networking/queries"
import {
  useExperimentsQuery,
  cohorts,
  onboardExperiments,
} from "../../util/experiments"
import { useRouter } from "../../util/router"

const Devtools = () => {
  const { data: family } = useFamilyQuery()
  const { user } = useUserQuery()
  const [showModal, setShowModal] = useState(false)
  const { data: dbExperiments } = useExperimentsQuery()
  const router = useRouter()
  const allExperiments = [
    ...onboardExperiments.map((exp) => exp.name),
    ...(dbExperiments ? Object.keys(dbExperiments) : []),
  ]

  const handleCohortChange = (experiment: string, cohort: string | null) => {
    if (cohort === null) {
      localStorage.removeItem(experiment)
    } else {
      localStorage.setItem(experiment, cohort)
    }
    // Force a page reload to ensure all experiment-dependent code updates
    router.push(router.location.pathname + router.location.search, {
      replace: true,
    })
  }

  if (!user?.enable_developer_mode && import.meta.env.PROD) return null

  return (
    <div
      style={{
        position: "absolute",
        left: "10px",
        bottom: "10px",
        zIndex: 999,
      }}
    >
      <button
        onClick={() => setShowModal(true)}
        style={{
          border: "1px solid black",
          padding: "10px",
          borderRadius: "10px",
          background: "white",
        }}
      >
        Devtools
      </button>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} animate>
        <ModalHeader
          title="Devtools"
          onClose={() => setShowModal(false)}
          showBorderBottom
        />
        <FlexBox
          direction="column"
          style={{ padding: SPACING.space6, paddingTop: SPACING.space4 }}
          gap={SPACING.space4}
        >
          <div>
            <Typography variant="bodyBold">Family ID: </Typography>
            <Typography variant="body">{family?.id}</Typography>
          </div>
          <div>
            <Typography variant="bodyBold">User ID: </Typography>
            <Typography variant="body">{user?.id}</Typography>
          </div>
          <Typography variant="bodyBold">Experiment Overrides</Typography>
          {allExperiments.map((experiment) => (
            <FlexBox key={experiment} gap={SPACING.space4} align="center">
              <Typography variant="body">{experiment}</Typography>
              <select
                value={localStorage.getItem(experiment) || ""}
                onChange={(e) =>
                  handleCohortChange(experiment, e.target.value || null)
                }
                style={{ minWidth: "150px" }}
              >
                <option value="">Default</option>
                {Object.values(cohorts).map((cohort) => (
                  <option key={cohort} value={cohort}>
                    {cohort}
                  </option>
                ))}
              </select>
            </FlexBox>
          ))}
        </FlexBox>
      </Modal>
    </div>
  )
}

export default Devtools
