import {
  Family,
  FamilyChildProfile,
  FamilyParentProfile,
  ParentTrainingStatus,
  QuestInstance,
  QuestSeries,
} from "@joonapp/web-shared"
import { useQuery, UseQueryResult } from "@tanstack/react-query"
import { AxiosResponse } from "axios"
import dayjs from "dayjs"

import { getIncidents } from "./incidents"
import {
  getAssignedQuests,
  getCustomQuestTemplates,
  getOpenQuests,
  getParentTaskInstances,
  getQuestInstanceStats,
  getQuestLog,
  getQuestsToReview,
  getSuggestedQuestTemplates,
} from "./quests"
import { getCoachSubscription, getSubscriberInfo } from "./subscriptions"
import { getTrainings } from "./trainings"
import { getCoinCumulativeStats, getCoinTransactions } from "./transactions"
import {
  loadUser,
  loadFamilies,
  getNetworkInfo,
  loadQuestionnaires,
  getChildAuthCode,
  getParentAuthCode,
  getTherapists,
  getTherapistInvites,
  loadRecommendedQuestionnaires,
  loadFamilyMail,
  loadInventoryItems,
  getNotificationPreferences,
  loadCoachIntakeFormQuestionnaire,
  loadCoachPostSelectionQuestionnaire,
  loadCareTeam,
  getSBCredentials,
  loadCoachSubscriptionPackages,
  getCountryCode,
} from "./user"
import { useChildQuestionnaireModalStore } from "../components/modals/childQuestionnaireModal/useChildQuestionnaireModalStore"
import { periodDropdownOptions, QUERY_KEYS } from "../constants"
import useOnboardContext from "../hooks/useOnboardContext"
import useCareTeam from "../pages/care/useCareTeam"
import { useCoinTransactionsModalStore } from "../pages/family/coinTransactions/useTransactionsModalStore"
import { useQBDatePickerStore } from "../pages/questBoard/QuestBoardDatePicker"
import {
  FamilyMailboxTab,
  FamilyQuerySelect,
  SubscriptionPackagesResponse,
} from "../types"
import { QuestTemplate, QuestTemplateCategory } from "../types/quests"
import { getAllQuestions, getChildQuestions } from "../util/questionnaires"
import { useRouter } from "../util/router"
import { sessionManager } from "../util/storage"
import {
  getTherapistsWithPatients,
  groupInvites,
  separateFamily,
  sortByRoutine,
} from "../util/util"

export const useQuestionnairesQuery = () =>
  useQuery({
    queryKey: [QUERY_KEYS.QUESTIONNAIRES],
    queryFn: loadQuestionnaires,
    select: getAllQuestions,
  })

export const useUserQuery = (resetAuthTokensOnError: boolean = false) => {
  const router = useRouter()
  const { isCreatingUser } = useOnboardContext()
  const { data: user, status: userStatus } = useQuery({
    queryKey: [QUERY_KEYS.USER],
    queryFn: loadUser,
    enabled: sessionManager.hasRefreshToken() && !isCreatingUser,
    onError: (error: any) => {
      if (error.response?.status === 403) {
        // User does not exist in backend. Route to create account.
        router.replace("/onboard")
      } else if (resetAuthTokensOnError) {
        sessionManager.clearAuthTokens()
      }
    },
  })

  return { user, userStatus }
}

// Function overloads
export function useFamilyQuery(): UseQueryResult<Family, unknown>
export function useFamilyQuery(
  select: FamilyQuerySelect.CHILDREN
): UseQueryResult<FamilyChildProfile[], unknown>
export function useFamilyQuery(
  select: FamilyQuerySelect.PARENT
): UseQueryResult<FamilyParentProfile, unknown>
export function useFamilyQuery(
  select: FamilyQuerySelect.COPARENTS
): UseQueryResult<FamilyParentProfile[], unknown>

export function useFamilyQuery(select?: FamilyQuerySelect) {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.FAMILY],
    queryFn: loadFamilies,
    select: (family: Family) => {
      const { children, parent, coparents } = separateFamily(user, family)
      switch (select) {
        case FamilyQuerySelect.CHILDREN:
          return children
        case FamilyQuerySelect.PARENT:
          return parent
        case FamilyQuerySelect.COPARENTS:
          return coparents
        default:
          return family
      }
    },
    enabled: !!user,
  })
}

export const useFamilyMailQuery = (
  tab: FamilyMailboxTab,
  isUnread?: boolean
) => {
  const { user } = useUserQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.FAMILY_MAIL, tab, isUnread],
    queryFn: () => loadFamilyMail(user?.id as number, tab, isUnread),
    enabled: !!user,
  })
}

export const useFamilyUnreadMailQuery = () => {
  const { user } = useUserQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.UNREAD_MAIL],
    queryFn: () =>
      loadFamilyMail(user?.id as number, FamilyMailboxTab.RECEIVED, true),
    enabled: !!user,
  })
}

export const useInventoryGiftsQuery = () => {
  const { user } = useUserQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.INVENTORY],
    queryFn: () => loadInventoryItems(user?.id as number),
    enabled: !!user,
  })
}

export const useSubscriberInfo = () => {
  const { data: family } = useFamilyQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.SUBSCRIBER_INFO],
    queryFn: () => getSubscriberInfo(family!),
    enabled: !!family,
  })
}

export const useUserNetworkInfo = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.NETWORK_INFO],
    queryFn: getNetworkInfo,
  })
}

export const useUserCountryCodeInfo = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COUNTRY_CODE],
    queryFn: getCountryCode,
    select: (response) => response.data?.country,
  })
}

export const useChildAuthCodeQuery = () => {
  const { data: family } = useFamilyQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.CHILD_AUTH_CODE],
    queryFn: getChildAuthCode,
    select: (response) => response.data.results[0].code,
    enabled: sessionManager.hasRefreshToken() && !!family,
  })
}

export const useParentAuthCodeQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.PARENT_AUTH_CODE],
    queryFn: getParentAuthCode,
    select: (response) => response.data.results[0].code,
    enabled: sessionManager.hasRefreshToken(),
  })
}

export const useQuestTemplatesQuery = (
  ageGroup: string,
  templateCategory: QuestTemplateCategory,
  userId?: number
) => {
  const isCustomTemplate = templateCategory === QuestTemplateCategory.CUSTOM

  return useQuery({
    queryKey: [QUERY_KEYS.QUEST_TEMPLATES, ageGroup, templateCategory, userId],
    queryFn: isCustomTemplate
      ? () => getCustomQuestTemplates()
      : () =>
          getSuggestedQuestTemplates(
            templateCategory,
            ageGroup,
            userId as number
          ),
    enabled: sessionManager.hasRefreshToken() && !!userId,
    select: (response: AxiosResponse<{ results: QuestTemplate[] }>) =>
      response.data.results,
  })
}

export const useSBCredentialsQuery = () => {
  const { user } = useUserQuery()
  const { hasCoach } = useCareTeam()
  return useQuery({
    queryKey: [QUERY_KEYS.SB_CREDENTIALS],
    queryFn: () => getSBCredentials(user?.id!),
    enabled: !!user?.id && hasCoach,
  })
}

export const useCareTeamQuery = () => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.CARE_TEAM],
    queryFn: () => loadCareTeam(user?.id!),
    enabled: !!user,
  })
}

export const useTherapistsQuery = () => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  return useQuery({
    queryKey: [QUERY_KEYS.THERAPISTS],
    queryFn: () => getTherapists(children!),
    select: getTherapistsWithPatients,
    enabled: !!children && children.length > 0,
  })
}

export const useTherapistInvitesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.THERAPIST_INVITES],
    queryFn: getTherapistInvites,
    select: (response) => groupInvites(response.data.results),
    enabled: !!sessionManager.hasRefreshToken(),
  })
}

export const useAverageCoinsEarnedThisWeekQuery = () => {
  // this is the (coins earned this week from all children) / (number of children)
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  return useQuery({
    queryKey: [QUERY_KEYS.AVERAGE_COINS_EARNED_THIS_WEEK],
    queryFn: async () => {
      if (!children || children.length === 0) return 0

      const startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD")
      const endDate = dayjs().format("YYYY-MM-DD")

      const statsPromises = children.map((child) =>
        getCoinCumulativeStats({
          userId: child.user.id,
          startDate,
          endDate,
        })
      )

      const statsResults = await Promise.all(statsPromises)
      let totalCoins = 0
      for (const stats of statsResults) totalCoins += stats.coins_earned
      return Math.floor(totalCoins / children.length)
    },
    enabled: !!children,
  })
}

export const useCoinCulumativeStatsQuery = () => {
  const { child, selectedPeriod } = useCoinTransactionsModalStore()

  return useQuery({
    queryKey: [QUERY_KEYS.COIN_CUMULATIVE_STATS, child?.id, selectedPeriod],
    queryFn: () =>
      getCoinCumulativeStats({
        userId: child?.id,
        startDate: selectedPeriod.startDate,
        endDate: selectedPeriod.endDate,
      }),
    enabled: !!child,
  })
}

export const useCoinCulumativeStatsThisWeekQuery = (
  child?: FamilyChildProfile
) => {
  return useQuery({
    queryKey: [
      QUERY_KEYS.COIN_CUMULATIVE_STATS,
      child?.user.id,
      periodDropdownOptions[1],
    ],
    queryFn: () =>
      getCoinCumulativeStats({
        userId: child?.user.id!,
        startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
      }),
    enabled: !!child,
  })
}

export const useCoinTransactionsQuery = () => {
  const { child, selectedPeriod } = useCoinTransactionsModalStore()

  return useQuery({
    queryKey: [QUERY_KEYS.COIN_TRANSACTIONS, child?.id, selectedPeriod],
    queryFn: () =>
      getCoinTransactions({
        userId: child?.id,
        startDate: selectedPeriod.startDate,
        endDate: selectedPeriod.endDate,
        page: 1,
      }),
    select: (response) => response?.data.results,
    enabled: !!child,
  })
}

export const useRecommendedQuestionnairesQuery = () => {
  const { childId } = useChildQuestionnaireModalStore()

  return useQuery({
    queryKey: [QUERY_KEYS.RECOMMENDATION_QUESTIONNAIRES, childId],
    queryFn: () => loadRecommendedQuestionnaires(childId as number),
    select: getChildQuestions,
    enabled: typeof childId === "number",
  })
}

export const useCoachIntakeFormQuestionnaireQuery = () => {
  const { user } = useUserQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.COACH_INTAKE_FORM],
    queryFn: loadCoachIntakeFormQuestionnaire,
    enabled: !!user,
  })
}

export const useCoachPostSelectionQuestionnaireQuery = () => {
  const { user } = useUserQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.COACH_POST_SELECTION],
    queryFn: loadCoachPostSelectionQuestionnaire,
    enabled: !!user,
  })
}

export const useQuestInstanceStatsQuery = (userId: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.QUEST_INSTANCE_STATS, userId],
    queryFn: () => getQuestInstanceStats(userId),
  })
}

export const useQuestBoardQuery = (
  onSuccess: (val?: any) => void = () => {}
) => {
  const { questBoardDate } = useQBDatePickerStore()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const childIds = children?.map((child) => child.user.id) || []

  const selectedDate = questBoardDate?.format("YYYY-MM-DD") || ""
  return useQuery<QuestInstance[]>({
    queryKey: [QUERY_KEYS.QUEST_BOARD, selectedDate],
    queryFn: () => getAssignedQuests(selectedDate, childIds),
    select: (data) => sortByRoutine(data),
    onSuccess,
    enabled: !!children,
  })
}

export const useReorderQuestsQuery = (
  onSuccess: (val?: any) => void = () => {}
) => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const childIds = children?.map((child) => child.user.id) || []

  return useQuery<QuestSeries[]>({
    queryKey: [QUERY_KEYS.QUEST_BOARD_REORDER],
    queryFn: () => getOpenQuests(childIds),
    onSuccess,
    enabled: !!children,
  })
}

export const useQuestReviewQuery = () => {
  const { data: family } = useFamilyQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)

  return useQuery<QuestInstance[]>({
    queryKey: [QUERY_KEYS.QUEST_REVIEW],
    queryFn: () => getQuestsToReview(children as FamilyChildProfile[]),
    enabled: sessionManager.hasRefreshToken() && !!family,
  })
}

export const useQuestLogQuery = (seriesId: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.QUEST_LOG, seriesId],
    queryFn: () => getQuestLog({ seriesId }),
    enabled: !!seriesId,
  })
}

export const useParentTaskQuery = (args?: { enabled: boolean }) => {
  const { user } = useUserQuery()
  const todaysDate = dayjs().format("YYYY-MM-DD")

  return useQuery<QuestInstance[]>({
    queryKey: [QUERY_KEYS.PARENT_TASKS, user?.id],
    queryFn: () => getAssignedQuests(todaysDate, [user?.id as number]),
    enabled: args?.enabled !== false && !!user,
  })
}

export const useParentTaskInstancesQuery = ({
  seriesId,
  minDate,
  maxDate,
  status,
}: {
  seriesId?: number
  minDate?: string
  maxDate?: string
  status?: string
}) => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.PARENT_TASK_INSTANCES, seriesId, user?.id, status],
    queryFn: () =>
      getParentTaskInstances({
        userId: user?.id as number,
        seriesId,
        minDate,
        maxDate,
        status,
      }),
    select: (data) => data.flat(),
    enabled: !!user,
  })
}

export const useIncidentTemplatesQuery = () => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.INCIDENT_TEMPLATES],
    queryFn: () => getIncidents(),
    enabled: !!user,
  })
}

export const useTrainingsQuery = (
  status = ParentTrainingStatus.IN_PROGRESS
) => {
  const { user } = useUserQuery()

  return useQuery({
    queryKey: [QUERY_KEYS.TRAININGS, status],
    queryFn: () =>
      getTrainings({
        userId: user?.id || 0,
        status,
      }),
    enabled: !!user,
  })
}

export const useNotificationPreferencesQuery = () => {
  const { user } = useUserQuery()
  return useQuery({
    queryKey: [QUERY_KEYS.NOTIFICATION_PREFERENCES],
    queryFn: () => getNotificationPreferences(user?.id as number),
    enabled: !!user,
  })
}

export const useCoachSubscriptionPackagesQuery = () => {
  return useQuery<SubscriptionPackagesResponse>({
    queryKey: [QUERY_KEYS.SUBSCRIPTION_PACKAGES],
    queryFn: loadCoachSubscriptionPackages,
  })
}

export const useGetCoachSubscriptionQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.COACH_SUBSCRIPTION],
    queryFn: () => getCoachSubscription(),
  })
}
