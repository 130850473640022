import {
  Button,
  DateInput,
  DropdownOption,
  DropdownSelect,
  FlexBox,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import { useFamilyQuery } from "../../../networking/queries"
import { useCustomRewardLogsQuery } from "../../../networking/rewards/queries"
import { FamilyQuerySelect } from "../../../types"

interface FilterRewardLogsModalStore {
  isOpen: boolean
  onOpen: (rewardId: number) => void
  onClose: () => void
  rewardId: number | undefined
  startDate: Date | null
  setStartDate: (startDate: Date | null) => void
  endDate: Date | null
  setEndDate: (endDate: Date | null) => void
  selectedUser: DropdownOption | null
  setSelectedUser: (user: DropdownOption | null) => void
}

export const useFilterRewardLogsModalStore = create<FilterRewardLogsModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (rewardId) => set({ isOpen: true, rewardId }),
    onClose: () => set({ isOpen: false }),
    rewardId: undefined,
    startDate: null,
    setStartDate: (startDate) => set({ startDate }),
    endDate: null,
    setEndDate: (endDate) => set({ endDate }),
    selectedUser: null,
    setSelectedUser: (user) => set({ selectedUser: user }),
  })
)

export default function FilterRewardLogsModal() {
  const [modalSelectedUser, setModalSelectedUser] =
    useState<DropdownOption | null>(null)
  const [modalStartDate, setModalStartDate] = useState<Date | null>(null)
  const [modalEndDate, setModalEndDate] = useState<Date | null>(null)
  const { isOpen, onClose, rewardId, selectedUser, startDate, endDate } =
    useFilterRewardLogsModalStore()
  const { isLoading } = useCustomRewardLogsQuery({ rewardId })
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const userDropdownOptions =
    children?.map((child) => ({
      label: child.user.name,
      value: child.user.id,
    })) ?? []

  const onApplyFilter = async () => {
    if (
      modalStartDate &&
      modalEndDate &&
      dayjs(modalStartDate)
        .startOf("day")
        .isAfter(dayjs(modalEndDate).startOf("day"))
    ) {
      toast.error("Start date cannot be after end date")
      return
    }
    useFilterRewardLogsModalStore.setState({
      selectedUser: modalSelectedUser,
      startDate: modalStartDate,
      endDate: modalEndDate,
    })
    onClose()
  }

  useEffect(() => {
    if (isOpen) {
      setModalSelectedUser(selectedUser)
      setModalStartDate(startDate)
      setModalEndDate(endDate)
    }
  }, [isOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      displayCloseIcon
      animate
      style={{
        width: "min(100%, 450px)",
        height: "350px",
        padding: `${SPACING.space4} ${SPACING.space6}`,
      }}
    >
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        align="flex-start"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <FlexBox
          direction="column"
          align="flex-start"
          justify="flex-start"
          gap={SPACING.space2}
        >
          <Typography variant="bodyBold">Filter by:</Typography>
          <Typography variant="bodyBold">Child:</Typography>
          <DropdownSelect
            type="single"
            placeholder="Select child"
            selectedOption={modalSelectedUser}
            setSelectedOption={setModalSelectedUser}
            options={userDropdownOptions}
            fullWidth
          />
          <DateInput
            label="Date range:"
            name="start-date"
            date={modalStartDate}
            setDate={setModalStartDate}
            maxDate={new Date()}
            modalTitle="Start date"
            placeholder="Start date"
            fullWidth
          />
          <DateInput
            name="end-date"
            date={modalEndDate}
            setDate={setModalEndDate}
            maxDate={new Date()}
            modalTitle="End date"
            placeholder="End date"
            fullWidth
          />
        </FlexBox>
        <Button
          buttonType="primary"
          onClick={onApplyFilter}
          text="Apply filter"
          isLoading={isLoading}
          fullWidth
        />
      </FlexBox>
    </Modal>
  )
}
