import {
  Button,
  Checkbox,
  CustomReward,
  CustomRewardCurrency,
  DropdownSelect,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"
import { create } from "zustand"

import { useDeleteRewardModalStore } from "./DeleteRewardModal"
import { RewardEmojiPicker, RewardEmojiPickerButton } from "./RewardEmojiPicker"
import ImageCheckbox from "../../components/imageCheckbox/ImageCheckbox"
import useMobile from "../../hooks/useMobile"
import {
  useAverageCoinsEarnedThisWeekQuery,
  useFamilyQuery,
} from "../../networking/queries"
import {
  useAddRewardMutation,
  useEditRewardMutation,
} from "../../networking/rewards/mutations"
import { FamilyQuerySelect } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

interface AddEditRewardModalStore {
  isOpen: boolean
  onOpen: (reward?: CustomReward) => void
  onClose: () => void

  rewardId: number | null
  templateId: number | null

  title: string
  setTitle: (title: string) => void
  cost: number | null
  setCost: (cost: number | null) => void
  emoji: string | null
  setEmoji: (emoji: string) => void
  selectedChildren: number[]
  toggleSelectedChild: (childId: number) => void
  currency: CustomRewardCurrency
  setCurrency: (currency: CustomRewardCurrency) => void
}

export const useAddEditRewardModal = create<AddEditRewardModalStore>(
  (set, get) => ({
    isOpen: false,
    onOpen: (reward) => {
      if (reward)
        set({
          rewardId: reward.user_ids ? reward.id : null,
          templateId: reward.user_ids ? null : reward.id,
          title: reward.title,
          cost: reward.cost,
          emoji: reward.emoji,
          selectedChildren: reward.user_ids || [],
          currency: reward.currency,
        })
      set({ isOpen: true })
    },
    onClose: () =>
      set({
        isOpen: false,
        title: "",
        cost: null,
        emoji: null,
        selectedChildren: [],
        rewardId: null,
        templateId: null,
        currency: CustomRewardCurrency.ITEM,
      }),

    rewardId: null,
    templateId: null,

    title: "",
    cost: null,
    emoji: null,
    selectedChildren: [],
    currency: CustomRewardCurrency.ITEM,

    setTitle: (title: string) => set({ title: title }),
    setCost: (cost: number | null) => set({ cost: cost }),
    setEmoji: (emoji: string) => set({ emoji: emoji }),
    setCurrency: (currency: CustomRewardCurrency) => set({ currency }),
    toggleSelectedChild: (childId: number) => {
      const { selectedChildren } = get()
      if (selectedChildren.includes(childId))
        set({
          selectedChildren: selectedChildren.filter((id) => id !== childId),
        })
      else set({ selectedChildren: [...selectedChildren, childId] })
    },
  })
)

const AddEditRewardModal = () => {
  const {
    isOpen,
    onClose,
    title,
    cost,
    emoji,
    rewardId,
    selectedChildren,
    currency,
    setTitle,
    setCost,
    setEmoji,
    toggleSelectedChild,
    setCurrency,
  } = useAddEditRewardModal()
  const [errorText, setErrorText] = useState("")
  const { onOpen: openDeleteRewardModal } = useDeleteRewardModalStore()
  const { data: averageCoinsEarned } = useAverageCoinsEarnedThisWeekQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const editRewardMutation = useEditRewardMutation()
  const addRewardMutation = useAddRewardMutation()

  const isMobile = useMobile()

  const isEditing = !!rewardId
  const plural = cost && cost === 1 ? "" : "s"
  const currencyOptions = [
    { label: "Dollar", value: CustomRewardCurrency.DOLLAR },
    { label: "Minute", value: CustomRewardCurrency.MINUTE },
  ]
  const placeholderText =
    currency === CustomRewardCurrency.DOLLAR
      ? "Allowance"
      : currency === CustomRewardCurrency.MINUTE
        ? "Tablet screen time"
        : "Ice cream with mom!"

  const onClickAddReward = () => {
    if (cost === null)
      return setErrorText(
        `Please enter ${currency === CustomRewardCurrency.ITEM ? "a cost" : "an exchange rate"} for your reward`
      )
    if (selectedChildren.length === 0)
      return setErrorText("Please select at least one child")
    if (!emoji) return setErrorText("Please select a reward icon")
    if (!title) return setErrorText("Please enter a title")
    if (isEditing && !rewardId) throw new Error("Reward ID not found")
    setErrorText("")

    if (isEditing) {
      trackAnalyticEvent(ANALYTIC_EVENTS.EDIT_REWARD)
      editRewardMutation.mutate({
        rewardId: rewardId as number,
        rewardInfo: {
          title: title,
          cost: cost,
          emoji: emoji,
          user_ids: selectedChildren,
          purchasable_from_joonity: true,
          currency: currency,
        },
      })
    } else {
      trackAnalyticEvent(ANALYTIC_EVENTS.ADD_REWARD)
      addRewardMutation.mutate({
        title: title,
        cost: cost,
        emoji: emoji,
        user_ids: selectedChildren,
        purchasable_from_joonity: true,
        currency: currency,
      })
    }
    onClose()
  }

  return (
    <>
      <RewardEmojiPicker setEmoji={setEmoji} />
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        displayCloseIcon
        animate
        style={{ background: JoonUIColor.background.lightAccent }}
      >
        <div className="quest-modal">
          <div
            className="quest-modal-content"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: SPACING.space4,
              padding: `${SPACING.space4} ${SPACING.space6}`,
            }}
          >
            <div
              className="quest-modal-header section-title"
              style={{ width: "fit-content", textAlign: "left" }}
            >
              {isEditing ? `Edit: ${emoji} ${title}` : "Create new reward"}
            </div>

            <FlexBox
              direction="column"
              gap={SPACING.space2}
              wrap={false}
              fullWidth
            >
              <Typography variant="bodyBold">Reward type</Typography>
              <FlexBox direction="row" gap={SPACING.space2} wrap={false}>
                <ImageCheckbox
                  name="Reward type"
                  key="Currency exchange"
                  label={
                    <FlexBox direction="column" align="center">
                      <Typography variant={isMobile ? "caption" : "bodyBold"}>
                        Currency exchange
                      </Typography>
                      <Typography
                        variant={isMobile ? "bodyXSmall" : "bodySmall"}
                        color={JoonUIColor.text.secondary}
                        style={{ whiteSpace: "wrap" }}
                      >
                        Best for allowance or screen time
                      </Typography>
                    </FlexBox>
                  }
                  image={"/images/rewards_currency_exchange.png"}
                  onChange={() => setCurrency(CustomRewardCurrency.DOLLAR)}
                  selected={currency !== CustomRewardCurrency.ITEM}
                  inputType="radio"
                  style={{
                    width: "50%",
                    minHeight: isMobile ? "228px" : "220px",
                    maxHeight: isMobile ? "228px" : "220px",
                    paddingTop: SPACING.space3,
                    paddingBottom: SPACING.space3,
                    gap: SPACING.space1,
                  }}
                />
                <ImageCheckbox
                  name="Reward type"
                  key="Item or activity"
                  label={
                    <FlexBox direction="column" align="center">
                      <Typography variant={isMobile ? "caption" : "bodyBold"}>
                        Item or activity
                      </Typography>
                      <Typography
                        variant={isMobile ? "bodyXSmall" : "bodySmall"}
                        color={JoonUIColor.text.secondary}
                        style={{ whiteSpace: "wrap" }}
                      >
                        For everything else you'd like to reward your child!
                      </Typography>
                    </FlexBox>
                  }
                  image={"/images/rewards_item_or_activity.png"}
                  onChange={() => setCurrency(CustomRewardCurrency.ITEM)}
                  selected={currency === CustomRewardCurrency.ITEM}
                  inputType="radio"
                  style={{
                    width: "50%",
                    minHeight: isMobile ? "228px" : "220px",
                    maxHeight: isMobile ? "228px" : "220px",
                    paddingTop: SPACING.space3,
                    paddingBottom: SPACING.space3,
                    gap: SPACING.space1,
                  }}
                />
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
              <Typography variant="bodyBold">
                Set your item or activity price
              </Typography>

              <FlexBox direction="column" gap={SPACING.space0} wrap={false}>
                {currency !== CustomRewardCurrency.ITEM && (
                  <Typography
                    color={JoonUIColor.text.secondary}
                    variant="bodyXSmall"
                  >
                    This reward can be purchased and redeemed per unit (dollar
                    or minute).
                  </Typography>
                )}
                <Typography
                  color={JoonUIColor.text.secondary}
                  variant="bodyXSmall"
                  style={{ marginBottom: SPACING.space1 }}
                >
                  As a reminder: an easy quest rewards 4 coins, medium 10 coins,
                  and hard 20 coins. In the last 7 days, on average your kids
                  earned {averageCoinsEarned} coins.
                </Typography>
                <FlexBox
                  direction="row"
                  justify="flex-start"
                  align="center"
                  gap={SPACING.space2}
                  wrap={false}
                >
                  <Typography variant="caption">
                    {currency === CustomRewardCurrency.ITEM
                      ? "Cost:"
                      : "Exchange:"}
                  </Typography>
                  <div style={{ maxWidth: "100px" }}>
                    <TextInput
                      name="Cost"
                      value={cost !== null ? `${cost}` : ""}
                      placeholder="100"
                      maxLength={6}
                      onChange={(e) => {
                        const value = e.target.value
                        if (value === "") return setCost(null)
                        if (isNaN(Number(value)) || Number(value) < 0) return
                        setCost(Number(value))
                      }}
                      style={{ marginBottom: SPACING.space1, width: "100px" }}
                    />
                  </div>
                  <Typography
                    variant="caption"
                    style={{ whiteSpace: "nowrap", width: "fit-content" }}
                  >
                    Joon coin{plural}
                  </Typography>
                </FlexBox>
                {currency !== CustomRewardCurrency.ITEM && (
                  <FlexBox
                    direction="row"
                    justify="flex-start"
                    align="center"
                    gap={SPACING.space2}
                    wrap={false}
                  >
                    <Typography variant="caption">For each:</Typography>
                    <DropdownSelect
                      name="Currency"
                      type="single"
                      selectedOption={
                        currencyOptions.find(
                          (option) => option.value === currency
                        ) || currencyOptions[0]
                      }
                      setSelectedOption={(option) =>
                        setCurrency(option.value as CustomRewardCurrency)
                      }
                      options={currencyOptions}
                      style={{ marginBottom: SPACING.space1, width: "140px" }}
                    />
                  </FlexBox>
                )}
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
              <Typography variant="bodyBold">Available for</Typography>
              <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
                {children?.map((child) => (
                  <Checkbox
                    name={child.user.name}
                    key={child.user.id}
                    label={child.user.name}
                    onChange={() => toggleSelectedChild(child.user.id)}
                    selected={selectedChildren.includes(child.user.id)}
                  />
                ))}
              </div>
            </FlexBox>

            <RewardEmojiPickerButton emoji={emoji} />
            <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
              <Typography variant="bodyBold">Reward Name</Typography>
              {currency !== CustomRewardCurrency.ITEM && (
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                >
                  Avoid including amount or numbers in the title and use the
                  exchange rate settings above instead.
                </Typography>
              )}
              <TextInput
                name="Reward Title"
                maxLength={140}
                value={title}
                placeholder={placeholderText}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
              />
            </FlexBox>

            <div
              className={
                errorText ? "error-text mt-0 mb-0" : "error-text mt-6 mb-6"
              }
              style={{ width: "100%", textAlign: "center" }}
            >
              {errorText}
            </div>

            <FlexBox
              direction="row"
              justify="space-between"
              align="center"
              fullWidth
            >
              {isEditing && (
                <TextButton
                  onClick={() => {
                    openDeleteRewardModal(rewardId as number)
                    onClose()
                  }}
                  style={{ width: "16%" }}
                >
                  <Typography
                    variant="caption"
                    color={JoonUIColor.semantic.alert}
                  >
                    Delete
                  </Typography>
                </TextButton>
              )}
              <Button
                text={isEditing ? "Save changes" : "Add reward"}
                isLoading={
                  addRewardMutation.isLoading || editRewardMutation.isLoading
                }
                onClick={onClickAddReward}
                style={{ width: isEditing ? "80%" : "100%" }}
              />
            </FlexBox>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddEditRewardModal
