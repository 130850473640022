import { useEffect, useMemo } from "react"
import { create } from "zustand"

import ToggleHeader from "../../../components/toggleHeader/ToggleHeader"
import { useFamilyQuery } from "../../../networking/queries"
import { FamilyQuerySelect } from "../../../types"
import { getChildImage } from "../../../util/util"

interface RewardsChildToggleHeaderStore {
  selectedChildId: number | null
  setSelectedChildId: (childId: number | null) => void
}

export const useRewardsChildToggleHeaderStore =
  create<RewardsChildToggleHeaderStore>((set) => ({
    selectedChildId: null,
    setSelectedChildId: (childId) => set({ selectedChildId: childId }),
  }))

const RewardsChildToggleHeader = () => {
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const { selectedChildId, setSelectedChildId } =
    useRewardsChildToggleHeaderStore()

  const childOptions = useMemo(
    () =>
      children?.map((child) => ({
        label: child.user.name,
        value: child.user.id || null,
        imageUrl: getChildImage(child.user) || null,
      })) || [],
    [children]
  )

  const goToNextChild = () => {
    if (selectedChildId === null) {
      setSelectedChildId(childOptions[0].value)
      return
    }
    const currentIndex = childOptions.findIndex(
      (option) => option.value === selectedChildId
    )
    const nextIndex = (currentIndex + 1) % childOptions.length
    setSelectedChildId(nextIndex === 0 ? null : childOptions[nextIndex].value)
  }

  const goToPreviousChild = () => {
    if (selectedChildId === null) {
      if (childOptions.length === 2 && childOptions[1].value === null)
        setSelectedChildId(childOptions[0].value)
      else setSelectedChildId(childOptions[childOptions.length - 1].value)
      return
    }
    const currentIndex = childOptions.findIndex(
      (option) => option.value === selectedChildId
    )
    const previousIndex =
      (currentIndex - 1 + childOptions.length) % childOptions.length
    setSelectedChildId(
      currentIndex === 0 ? null : childOptions[previousIndex].value
    )
  }

  useEffect(() => {
    if (childOptions.length === 1) {
      childOptions.push({
        label: "All Kids",
        value: null,
        imageUrl: null,
      })
    }
  }, [childOptions, setSelectedChildId])

  if (childOptions.length === 0) return null

  return (
    <ToggleHeader
      options={childOptions}
      selectedOption={
        childOptions.find((option) => option.value === selectedChildId) || {
          label: "All Kids",
          value: null,
          imageUrl: null,
        }
      }
      onClickNext={goToNextChild}
      onClickPrevious={goToPreviousChild}
    />
  )
}

export default RewardsChildToggleHeader
