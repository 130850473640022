import { Family } from "@joonapp/web-shared"

import { CoachingLookupKey } from "../constants"
import { SubscriberAPIResponse, SubscriptionInfo } from "../types"
import { createJoonAPIClient } from "../util/joon-api"

// Family

export async function getSubscriberInfo(family: Family) {
  if (!family) throw new Error("No family")

  const API = createJoonAPIClient()

  return API.get<SubscriberAPIResponse>(
    `api/families/${family.id}/subscription`
  ).then((res) => res.data)
}

export async function updateSubscriberInfo(
  family: Family,
  ipAddress: string,
  countryCode: string,
  fbClickId: string | undefined,
  fbBrowser: string | undefined
) {
  const API = createJoonAPIClient()

  return API.post(`api/families/${family.id}/subscriber-attributes/`, {
    ip_address: ipAddress,
    user_agent: navigator.userAgent,
    country_code: countryCode,
    fb_click_id: fbClickId,
    fb_browser: fbBrowser,
  })
}

// Coaching

export const getCoachingStripeCheckoutUrl = async (
  coachProfileId: number,
  subscriptionPackage?: CoachingLookupKey
) => {
  const API = createJoonAPIClient()

  const fromMOF = window.location.href.includes("from=preview")
    ? "&from=preview"
    : ""
  const successUrl = `${window.location.origin}/coaching?stripe_success=true${fromMOF}`
  const cancelUrl = `${window.location.origin}/coaching?stripe_success=false${fromMOF}`

  const response = await API.get(`api/coaching-groups/checkout/`, {
    params: {
      coach_profile_id: coachProfileId,
      success_url: successUrl,
      cancel_url: cancelUrl,
      selected_lookup_key: subscriptionPackage,
    },
  })
  return response.data.checkout_url
}

export const getCoachingStripeBillingUrl = async () => {
  const API = createJoonAPIClient()
  const response = await API.get(`api/coaching-groups/billing/`, {
    params: {
      return_url: window.location.href,
    },
  })
  return response.data.billing_url
}

export const getCoachSubscription = async () => {
  const API = createJoonAPIClient()

  return API.get<SubscriptionInfo>(`api/coaching-groups/subscription`).then(
    (res) => res.data
  )
}

export const requestSubscriptionUpgrade = async (numSessions: number) => {
  const API = createJoonAPIClient()
  return API.post("api/coaching-groups/request-sessions/", {
    num_sessions: numSessions,
  })
}
