import {
  Button,
  ChildUser,
  CustomReward,
  CustomRewardCurrency,
  Family,
  FlexBox,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useEffect } from "react"
import toast from "react-hot-toast"
import { create } from "zustand"

import PurchaseRewardChildSelectCard from "./PurchaseRewardChildSelectCard"
import RewardsCounter from "../../components/counter/RewardsCounter"
import { useFamilyQuery } from "../../networking/queries"
import { usePurchaseRewardMutation } from "../../networking/rewards/mutations"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { getUserFromFamily } from "../../util/util"

interface PurchaseRewardModalStore {
  isOpen: boolean
  onOpen: (reward: CustomReward) => void
  onClose: () => void

  reward: CustomReward | null
  selectedChildId: number | null
  setSelectedChildId: (selectedChildId: number | null) => void
  quantity: number
  setQuantity: (quantity: number) => void
}

export const usePurchaseRewardModal = create<PurchaseRewardModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (reward) => set({ isOpen: true, reward }),
    onClose: () => set({ isOpen: false, reward: null }),

    reward: null,
    selectedChildId: null,
    setSelectedChildId: (selectedChildId) => set({ selectedChildId }),
    quantity: 1,
    setQuantity: (quantity) => set({ quantity }),
  })
)

const PurchaseRewardModal = () => {
  const {
    isOpen,
    onClose,
    reward,
    selectedChildId,
    setSelectedChildId,
    quantity,
    setQuantity,
  } = usePurchaseRewardModal()

  const { data: family } = useFamilyQuery()
  const children = reward?.user_ids.map((userId) =>
    getUserFromFamily(family as Family, userId)
  ) as ChildUser[]

  const purchaseRewardMutation = usePurchaseRewardMutation()

  const onPurchaseClick = async () => {
    if (!selectedChildId || !reward) return
    await purchaseRewardMutation.mutateAsync({
      rewardId: reward.id,
      childId: selectedChildId,
      amount: quantity,
    })
    const plural = quantity === 1 ? "" : "s"
    toast.success(
      `${quantity} ${
        reward.currency === CustomRewardCurrency.DOLLAR
          ? "dollar"
          : reward.currency === CustomRewardCurrency.MINUTE
            ? "minute"
            : "reward"
      }${plural} purchased successfully`
    )
    trackAnalyticEvent(ANALYTIC_EVENTS.PURCHASE_CUSTOM_REWARD)
    onClose()
  }

  useEffect(() => {
    if (selectedChildId === null && children?.length && children.length >= 1)
      setSelectedChildId(children[0].id)
  }, [children, selectedChildId, setSelectedChildId])

  if (!family || !reward) return <></>

  const childrenWithEnoughCoins = children.filter(
    (child) => child.coins >= reward.cost
  )
  const maxQuantity =
    Math.floor(
      (childrenWithEnoughCoins.find((child) => child.id === selectedChildId)
        ?.coins ?? 0) / (reward.cost ?? 1)
    ) ?? 0

  return (
    <Modal onClose={onClose} isOpen={isOpen} displayCloseIcon animate>
      <div style={{ width: "min(400px, 95vw", padding: SPACING.space6 }}>
        {childrenWithEnoughCoins.length === 0 ? (
          <FlexBox
            direction="column"
            gap={SPACING.space4}
            style={{ textAlign: "left" }}
          >
            <Typography variant="bodyBold" textAlign="left">
              Looks like your kids don't have enough coins to purchase this
              reward!
            </Typography>
            <Typography variant="bodySmall" textAlign="left">
              Assign them more Quests so they can start saving up.
            </Typography>
            <Button text="OK" onClick={onClose} fullWidth />
          </FlexBox>
        ) : (
          <FlexBox direction="column" align="left" gap={SPACING.space6}>
            <FlexBox direction="column" gap={SPACING.space4}>
              <Typography variant="bodyBold">
                {`Purchasing: ${reward.emoji} ${reward.title}`}
              </Typography>
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography
                  variant="bodyXSmall"
                  color={JoonUIColor.text.secondary}
                  style={{ fontWeight: 600 }}
                >
                  Cost
                </Typography>
                <FlexBox
                  gap={SPACING.space1}
                  direction="row"
                  wrap={false}
                  align="center"
                >
                  <img
                    style={{ height: SPACING.space3 }}
                    src="/images/icons/coin-icon.png"
                    alt="coin"
                  />
                  <Typography variant="bodySmall">{reward?.cost}</Typography>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
              <Typography variant="bodyBold">
                Who would you like to purchase this for?
              </Typography>
              <Typography variant="bodySmall">
                Only kids with enough coins to make this purchase will show up
                below:
              </Typography>
              {childrenWithEnoughCoins.map((child) => (
                <PurchaseRewardChildSelectCard child={child} />
              ))}
            </FlexBox>

            <FlexBox direction="column" gap={SPACING.space4} wrap={false}>
              <Typography variant="bodyBold">
                {`How many ${
                  reward.currency === CustomRewardCurrency.DOLLAR
                    ? "dollars"
                    : reward.currency === CustomRewardCurrency.MINUTE
                      ? "minutes"
                      : ""
                } would you like to purchase?`}
              </Typography>
              <RewardsCounter
                maxQuantity={maxQuantity}
                quantity={quantity}
                setQuantity={setQuantity}
              />
              <FlexBox
                direction="row"
                align="center"
                gap={SPACING.space1}
                wrap={false}
                style={{ width: "fit-content" }}
              >
                <Typography variant="bodySmall" style={{ userSelect: "none" }}>
                  Total cost
                </Typography>
                <img
                  style={{ height: SPACING.space3 }}
                  src="/images/icons/coin-icon.png"
                  alt="coin"
                />
                <Typography variant="bodySmall">
                  {reward.cost * quantity}
                </Typography>
              </FlexBox>
            </FlexBox>

            <Button
              text="Purchase"
              isLoading={purchaseRewardMutation.isLoading}
              onClick={onPurchaseClick}
              disabled={
                !selectedChildId || quantity < 1 || quantity > maxQuantity
              }
              fullWidth
              style={{ userSelect: "none" }}
            />
          </FlexBox>
        )}
      </div>
    </Modal>
  )
}

export default PurchaseRewardModal
