import {
  ChatIcon,
  CreditCardIcon,
  JoonColorExpanded,
  JoonUIColor,
  ReceiptIcon,
  SPACING,
  Typography,
  VideoIcon,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"
import toast from "react-hot-toast"

import { PlanTitle } from "./PlanTitle"
import TextArrowButton from "../../../../components/coaching/TextArrowButton"
import { ACTIVE_COACHING_LOOKUP_KEY, COACH_PLANS } from "../../../../constants"
import useMobile from "../../../../hooks/useMobile"
import { useCoachSubscriptionPackagesQuery } from "../../../../networking/queries"
import { getCoachingStripeCheckoutUrl } from "../../../../networking/subscriptions"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../../util/analytics"
import { useRouter } from "../../../../util/router"
import { useCoachingStore } from "../../useCoachingStore"

const PlanInfoCard = () => {
  const [isLoading, setIsLoading] = useState(false)
  const { selectedCoach, setCoachingPlan } = useCoachingStore()
  const { data: subscriptionPackages } = useCoachSubscriptionPackagesQuery()
  const router = useRouter()
  const coachingDefaultPlan = subscriptionPackages?.[ACTIVE_COACHING_LOOKUP_KEY]
  const isMobile = useMobile()
  const { plan, frequency } = COACH_PLANS[ACTIVE_COACHING_LOOKUP_KEY]
  const firstMonthPriceInCents = coachingDefaultPlan?.first_month_unit_amount
  const priceInCents = coachingDefaultPlan?.unit_amount ?? 0

  const formattedCentsToDollars = (cents: number) =>
    Number((cents / 100).toFixed(2))

  const priceForToday = formattedCentsToDollars(
    firstMonthPriceInCents ?? priceInCents
  )
  const fullPrice = formattedCentsToDollars(
    firstMonthPriceInCents ? priceInCents : priceInCents * 2
  )

  const priceLabel =
    firstMonthPriceInCents !== null
      ? `afterwards $${fullPrice}/${coachingDefaultPlan?.interval || "month"}`
      : "Early bird special!"

  const infoCards = [
    {
      Icon: ChatIcon,
      text: "Anytime direct messaging with your coach",
    },
    {
      Icon: VideoIcon,
      text: frequency,
    },
    {
      Icon: CreditCardIcon,
      text: "HSA/FSA eligible",
    },
    {
      Icon: ReceiptIcon,
      text: "Try it Risk Free!\nLove it or get a full refund within 30 days",
    },
  ]

  const onContinueClick = async () => {
    if (!selectedCoach) return
    setIsLoading(true)
    trackAnalyticEvent(ANALYTIC_EVENTS.MARKETING_COACH_INITIATE_CHECKOUT, {
      is_tof: router.query.from !== "preview",
    })
    try {
      const checkoutUrl = await getCoachingStripeCheckoutUrl(
        selectedCoach.id,
        ACTIVE_COACHING_LOOKUP_KEY
      ) // must be profile id
      if (!checkoutUrl) {
        toast.error("Subscription already exists")
      } else {
        window.location.assign(checkoutUrl)
      }
      setCoachingPlan(ACTIVE_COACHING_LOOKUP_KEY)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FlexBox
      direction="column"
      justify="center"
      align="center"
      gap={SPACING.space0}
      style={{
        width: "min(100%, 400px)",
        opacity: subscriptionPackages ? 1 : 0,
        background: "#F9FAFC",
        border: `2px solid ${JoonUIColor.text.primaryAccent}`,
        borderRadius: "16px",
        marginBottom: SPACING.space4,
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10)",
        transition: "opacity .3s ease-in-out",
      }}
    >
      <FlexBox
        justify="center"
        align="center"
        gap={SPACING.space1}
        style={{
          padding: SPACING.space4,
        }}
      >
        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          fullWidth
          wrap={false}
        >
          <FlexBox
            justify="flex-start"
            align="center"
            gap={SPACING.space1}
            wrap={false}
          >
            <PlanTitle plan={plan} coachingPlan={ACTIVE_COACHING_LOOKUP_KEY} />
          </FlexBox>
          <FlexBox justify="flex-end" align="center" gap={SPACING.space2}>
            <Typography
              variant="h2"
              color={JoonUIColor.background.disabled}
              style={{ textDecoration: "line-through" }}
            >
              ${fullPrice}
            </Typography>
            <Typography variant="h2" color={JoonUIColor.text.primary}>
              ${priceForToday}
            </Typography>
          </FlexBox>
        </FlexBox>

        <FlexBox
          style={{
            padding: `${SPACING.space1} ${SPACING.space2}`,
            background: JoonColorExpanded.yellow300,
            borderRadius: SPACING.space1,
          }}
          fullWidth
        >
          <Typography
            variant="bodyXSmall"
            color={JoonUIColor.text.primary}
            textAlign="center"
            style={{
              fontWeight: 600,
              whiteSpace: "nowrap",
            }}
          >
            First month: 60% off Early Bird Special!
          </Typography>
        </FlexBox>

        <Typography
          variant="bodyXSmall"
          color={JoonUIColor.text.secondary}
          style={{ fontWeight: 600 }}
        >
          {priceLabel}
        </Typography>
      </FlexBox>

      <FlexBox
        direction="column"
        justify="space-between"
        align="flex-start"
        gap={SPACING.space2}
        wrap={false}
        style={{
          padding: `${SPACING.space4} ${SPACING.space2}`,
          background: JoonUIColor.background.primaryNeutral,
          borderTop: `1px solid ${JoonUIColor.icon.light}`,
          borderBottom: `1px solid ${JoonUIColor.icon.light}`,
          borderBottomLeftRadius: "16px",
          borderBottomRightRadius: "16px",
        }}
      >
        <FlexBox direction="column" align="flex-start" gap={SPACING.space0}>
          {infoCards.map(({ Icon, text }, index) => (
            <FlexBox
              direction="row"
              justify="flex-start"
              align="center"
              gap={SPACING.space2}
              wrap={false}
              style={{ padding: SPACING.space2 }}
            >
              <FlexBox
                justify="center"
                align="center"
                style={{
                  minWidth: "20px",
                  maxWidth: "20px",
                }}
              >
                <Icon
                  size={20}
                  color={
                    index < infoCards.length / 2
                      ? JoonUIColor.icon.accent
                      : JoonUIColor.icon.neutral
                  }
                />
              </FlexBox>
              <Typography
                variant={isMobile ? "bodyXSmall" : "bodySmall"}
                color={JoonUIColor.text.primary}
                style={{
                  fontWeight: "700",
                  textAlign: "left",
                  whiteSpace: "pre-line",
                }}
              >
                {text}
              </Typography>
            </FlexBox>
          ))}
        </FlexBox>

        <div
          style={{ width: "100%" }}
          onPointerDown={(e) => {
            e.currentTarget.dataset.pressed = "true"
            e.currentTarget.dataset.pressX = e.clientX.toString()
            e.currentTarget.dataset.pressY = e.clientY.toString()
          }}
          onPointerUp={async (e) => {
            if (e.currentTarget.dataset.pressed === "true") {
              const startX = Number(e.currentTarget.dataset.pressX || 0)
              const startY = Number(e.currentTarget.dataset.pressY || 0)
              const deltaX = Math.abs(e.clientX - startX)
              const deltaY = Math.abs(e.clientY - startY)
              if (deltaX < 10 && deltaY < 10) onContinueClick()
            }
            e.currentTarget.dataset.pressed = "false"
          }}
          onPointerLeave={(e) => {
            e.currentTarget.dataset.pressed = "false"
          }}
        >
          <TextArrowButton
            text={`Get started with ${selectedCoach?.user.name || "your coach"}`}
            isLoading={isLoading}
            onClick={() => {}}
            style={{
              position: "relative",
              width: "100%",
              cursor: "pointer",
            }}
          />
        </div>
      </FlexBox>
    </FlexBox>
  )
}

export default PlanInfoCard
