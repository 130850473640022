import {
  ChildUser,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import useMarkAsReadMutation from "./useMarkAsReadMutation"
import { useViewMessageModalStore } from "./useViewMessageModalStore"
import Card from "../../../components/card/Card"
import { FamilyMail, FamilyMailboxTab } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { getChildImage } from "../../../util/util"
import UnreadMailNotificationDot from "../UnreadMailNotificationDot"

interface MailItemProps {
  mail: FamilyMail
  child: ChildUser | undefined
  tab: FamilyMailboxTab
}

const MailItem = ({ mail, child, tab }: MailItemProps) => {
  const image = getChildImage(child || null)

  const { id, sent_datetime, read_datetime } = mail

  const startDate = dayjs(sent_datetime || "")
  const endDate = dayjs()

  const isToday = startDate.isSame(endDate, "day")
  const isWithinWeek = startDate.isAfter(endDate.subtract(7, "day"))

  const date = isToday
    ? startDate.format("h:mm A")
    : isWithinWeek
      ? startDate.format("ddd")
      : startDate.format("MMM D")

  const { onOpen, setChild, setTab } = useViewMessageModalStore()

  const markAsReadMutation = useMarkAsReadMutation()

  const onClickMailItem = () => {
    if (tab === FamilyMailboxTab.RECEIVED && !read_datetime) {
      markAsReadMutation.mutate(id as number)
    }
    setChild(child as ChildUser)
    setTab(tab)
    trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_FAMILY_MAIL)
    onOpen(mail)
  }

  return (
    <Card
      key={id}
      title={
        <FlexBox
          direction="row"
          align="center"
          justify="flex-start"
          gap={SPACING.space2}
        >
          <img
            src={image}
            style={{
              width: "15vw",
              maxWidth: "35px",
              height: "auto",
              borderRadius: "50%",
            }}
            alt="Child Avatar"
          />
          <Typography variant="bodyBold">{child?.name}</Typography>
        </FlexBox>
      }
      onClick={onClickMailItem}
      style={{
        width: "99%",
        position: "relative",
        border: "1px solid #E0E0E0",
        marginTop: SPACING.space1,
      }}
      buttonComponent={
        <>
          <Typography
            variant="caption"
            color={JoonUIColor.text.primary}
            style={{ textWrap: "nowrap" }}
          >
            {date}
          </Typography>
          {!read_datetime && tab === FamilyMailboxTab.RECEIVED && (
            <UnreadMailNotificationDot size="12px" />
          )}
        </>
      }
    ></Card>
  )
}

export default MailItem
