import { DropdownOption, QuestRoutine, QuestStatus } from "@joonapp/web-shared"
import dayjs from "dayjs"

import { CancellationSurveyQuestion } from "./types"
import { CancellationSurveyType } from "./types"
import { QuestTemplateCategory } from "./types/quests"
import { displayStringForTemplateCategory } from "./util/choices/quests"

export const SKILL_MAPPING = {
  knowledge: "Learning & Education",
  stamina: "Mental Health",
  strength: "Health & Fitness",
  kindness: "Relationship Building",
  focus: "Focus & Self Control",
  teamwork: "Chores & Housework",
} as { [key: string]: string }

export const SKILL_DROPDOWN = Object.entries(SKILL_MAPPING).map(
  ([key, value]) => ({
    label: value,
    value: key,
  })
)

export const REPETITIONS = {
  daily: [true, true, true, true, true, true, true],
  noDays: [false, false, false, false, false, false, false],
  weekdays: [false, true, true, true, true, true, false],
  weekends: [true, false, false, false, false, false, true],
}

export const difficultyRewards = {
  easy: 4,
  medium: 10,
  hard: 20,
}

export const ageGroups = {
  six_under: {
    value: "six_under",
    label: "6 & Under",
  },
  seven_nine: {
    value: "seven_nine",
    label: "7-9",
  },
  ten_twelve: {
    value: "ten_twelve",
    label: "10-12",
  },
  thirteen_up: {
    value: "thirteen_up",
    label: "13 & Over",
  },
}

export const PREFERENCE_KEY = {
  hidesFutureRoutines: "hides_future_routines",
  morningLimit: "morning_limit",
  afternoonLimit: "afternoon_limit",
  nightLimit: "night_limit",
  afternoonStartTime: "afternoon_start_time",
  nightStartTime: "night_start_time",
  enableDoterWishes: "enable_doter_wishes",
} as const

export enum QuestRepeatFrequency {
  NO_REPEAT = "Does not repeat",
  REPEAT = "On day(s)",
}

export const questRepeatSegmentedControlOptions = [
  {
    label: QuestRepeatFrequency.NO_REPEAT,
    value: QuestRepeatFrequency.NO_REPEAT,
  },
  {
    label: QuestRepeatFrequency.REPEAT,
    value: QuestRepeatFrequency.REPEAT,
  },
]

export const periodDropdownOptions = [
  {
    label: "All Time",
    value: "all_time",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: null,
  },
  {
    label: "This Week",
    value: "this_week",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
  },
  {
    label: "This Month",
    value: "this_month",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD"),
  },
  {
    label: "Custom",
    value: "custom",
    endDate: dayjs().format("YYYY-MM-DD"),
    startDate: null,
  },
]
export type PeriodDropdownOption = (typeof periodDropdownOptions)[number]

export const templateCategoryDropdownOptions = Object.values(
  QuestTemplateCategory
).map((category) => ({
  value: category,
  label: displayStringForTemplateCategory(category),
}))

export const completeQuestStatuses = [
  QuestStatus.COMPLETED,
  QuestStatus.REDEEMED,
  QuestStatus.VERIFIED,
]

export enum QUERY_KEYS {
  QUEST_BOARD = "quest-board",
  QUEST_BOARD_REORDER = "quest-board-reorder",
  QUEST_INSTANCE_STATS = "quest-instance-stats",
  QUEST_REVIEW = "quest-review",
  QUEST_LOG = "quest-log",
  QUESTIONNAIRES = "questionnaires",
  CARE_TEAM = "care-team",
  COACH_INTAKE_FORM = "coach-intake-form",
  COACH_POST_SELECTION = "coach-post-selection",
  COACH_PROFILES = "coach-profiles",
  RECOMMENDATION_QUESTIONNAIRES = "recommendation-questionnaires",
  USER = "user",
  FAMILY = "family",
  FAMILY_MAIL = "family-mails",
  UNREAD_MAIL = "family-unread-mails",
  INVENTORY = "inventory-items",
  SUBSCRIBER_INFO = "subscriber-info",
  CHILD_AUTH_CODE = "child-auth-code",
  PARENT_AUTH_CODE = "parent-auth-code",
  CUSTOM_REWARDS = "custom-rewards",
  CUSTOM_REWARD_LOGS = "custom-reward-logs",
  CUSTOM_REWARD_LOGS_STATS = "custom-reward-logs-stats",
  REWARD_TEMPLATES = "reward-templates",
  QUEST_TEMPLATES = "quest-templates",
  NOTIFICATION_PREFERENCES = "notification-preferences",
  SB_CREDENTIALS = "sb-credentials",
  THERAPISTS = "therapists",
  THERAPIST_INVITES = "therapist-invites",
  EXPERIMENTS = "experiments",
  BONUS_REWARDS = "bonus-rewards",
  AVERAGE_COINS_EARNED_THIS_WEEK = "average-coins-earned-this-week",
  COIN_TRANSACTIONS = "coin-transactions",
  COIN_CUMULATIVE_STATS = "coin-cumulative-stats",
  NETWORK_INFO = "network-info",
  COUNTRY_CODE = "country-code",
  PARENT_TASKS = "parent-tasks",
  PARENT_TASK_INSTANCES = "parent-task-instances",
  INCIDENT_TEMPLATES = "incident-templates",
  TRAININGS = "trainings",
  FAMILY_CHECKOUT_LINK = "family-checkout-link",
  SUBSCRIPTION_PACKAGES = "subscription-packages",
  COACH_SUBSCRIPTION = "coach-subscription",
  SESSION_BILLING_PERIODS = "session-billing-periods",
  SESSION_BOOKINGS = "session-bookings",
  COACHING_GROUPS = "coaching-groups",
  SESSION_BOOKING = "session-booking",
  CUSTOM_REWARD_BALANCES = "custom-reward-balances",
}

export const questRoutineOptions: DropdownOption[] = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Morning",
    value: QuestRoutine.MORNING,
  },
  {
    label: "Afternoon",
    value: QuestRoutine.AFTERNOON,
  },
  {
    label: "Night",
    value: QuestRoutine.NIGHT,
  },
  {
    label: "Anytime",
    value: QuestRoutine.ANYTIME,
  },
]

export const GRADIENTS = {
  gradient100: `linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8) 2.83%,
    rgba(255, 255, 255, 0) 51.53%,
    rgba(255, 255, 255, 0.8) 99.81%
  ),
  #e9eafa`,
}

export enum CoachingStep {
  POST_ANSWER_SCREEN = -1,
  LANDING,
  INTAKE_FORM,
  MATCHING,
  RECOMMENDED_COACHES,
  POST_SELECTION,
  PLAN_SELECT,
  SCHEDULED,
}

export enum CoachingLookupKey {
  TEXT_ONLY = "coaching_text_only_monthly",
  ONE_CALL = "coaching_one_call_monthly",
  STANDARD = "coaching_standard_monthly",
  FOUR_CALLS = "coaching_four_call_monthly",
}

export const ACTIVE_COACHING_LOOKUP_KEY = CoachingLookupKey.STANDARD

export const COACH_PLANS = {
  [CoachingLookupKey.TEXT_ONLY]: {
    plan: "Starter",
    frequency: "1 kick-off meeting included to discuss your goals!",
  },
  [CoachingLookupKey.ONE_CALL]: {
    plan: "Basic",
    frequency: "1 x 45-minute session with your coach/month",
  },
  [CoachingLookupKey.STANDARD]: {
    plan: "Plus",
    frequency: "2 x 45-minute sessions with your coach/month",
  },
  [CoachingLookupKey.FOUR_CALLS]: {
    plan: "Pro",
    frequency: "4 x 45-minute sessions with your coach/month",
  },
}

export const CoachingQuestionText = {
  [CancellationSurveyType.COACH]: {
    [CancellationSurveyQuestion.WHY_LEAVING]:
      "Why didn't coaching work for you?",
    [CancellationSurveyQuestion.ALTERNATIVE_SOLUTIONS]:
      "What will you use going forward for parenting resources?",
  },
  [CancellationSurveyType.FAMILY]: {
    [CancellationSurveyQuestion.WHY_LEAVING]: "Why didn't Joon work for you?",
    [CancellationSurveyQuestion.ALTERNATIVE_SOLUTIONS]:
      "What will you use going forward to motivate your child?",
  },
} as const
