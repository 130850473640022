import { JoonUIColor, Typography } from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect } from "react"

import useDataTaskCompletionModalStore from "./useDataTaskCompletionModalStore"
import useDataTasksModalStore from "./useDataTasksModalStore"
import Card from "../../../components/card/Card"
import { useMarkDataTaskAsReadMutation } from "../../../networking/dataCollectionTasks/mutations"
import { DataTaskIncidentTemplate } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { ArrowCircleRightIcon } from "../AcademyActions"

const DataTaskCard = ({ dataTask }: { dataTask: DataTaskIncidentTemplate }) => {
  const { onOpen: openDataTaskCompletionModal } =
    useDataTaskCompletionModalStore()
  const { onClose: closeDataTasksModal } = useDataTasksModalStore()

  const onClick = () => {
    openDataTaskCompletionModal(dataTask.id)
    closeDataTasksModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.INITIATE_DATA_COLLECTION_TASK)
  }
  const markDataTaskAsReadMutation = useMarkDataTaskAsReadMutation()

  const isNew = !dataTask.date_read

  useEffect(() => {
    if (!isNew) return
    markDataTaskAsReadMutation.mutate(dataTask.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTask])

  return (
    <Card
      title={dataTask.title}
      subtitle={
        isNew
          ? ((
              <Typography
                variant="bodyXSmall"
                style={{ fontWeight: 700 }}
                color={JoonUIColor.semantic.success}
              >
                NEW TASK ADDED
              </Typography>
            ) as any)
          : `Assigned on ${dayjs(dataTask.date_assigned).format("MMM D")}`
      }
      onClick={onClick}
      buttonComponent={<ArrowCircleRightIcon />}
    />
  )
}

export default DataTaskCard
