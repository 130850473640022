import dayjs from "dayjs"
import { useEffect } from "react"

import { useSubscriberInfo, useUserQuery } from "../../networking/queries"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"

const useDailyPaywallRedirect = () => {
  const { user } = useUserQuery()
  const router = useRouter()
  const { data: subscriber } = useSubscriberInfo()

  const lastSeenPaywall = localStorage.getItem(
    localStorageItems.lastSeenPaywall
  )

  useEffect(() => {
    if (!user || !subscriber) return
    if (subscriber?.active_plan?.is_active) return

    const lastSeenPaywallDate = dayjs(lastSeenPaywall)
    const isDifferentDate =
      !lastSeenPaywallDate.isSame(dayjs(), "day") || !lastSeenPaywall

    if (isDifferentDate) {
      switch (user.sub_offering) {
        case "referral":
          router.push(`/paywall?campaign=DiscountAnnual`)
          break
        case "discounted":
          router.push(`/paywall?campaign=SpecialDiscountAnnual`)
          break
        default:
          router.push(`/paywall`)
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, subscriber])
}

export default useDailyPaywallRedirect
