import {
  Button,
  CheckIcon,
  CircleCheckboxChecked,
  FlexBox,
  JoonUIColor,
  Modal,
  ModalContent,
  ModalHeader,
  QuestStatus,
  QuestType,
  SPACING,
  SkipIcon,
  TherapistIcon,
  Typography,
  UserRole,
} from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import dayjs from "dayjs"
import { useState } from "react"
import toast from "react-hot-toast"

import { useAddEditQuestStore } from "./addEditQuestModal/useAddEditQuestStore"
import { useQBDatePickerStore } from "./QuestBoardDatePicker"
import QuestLog from "./QuestLog"
import ReassignQuest, { useReassignQuestModalStore } from "./ReassignQuest"
import { useViewQuestModalStore } from "./useViewQuestModalStore"
import Badge from "../../components/badges/Badge"
import CategoryBadge from "../../components/badges/categoryBadge/CategoryBadge"
import ChildBadge from "../../components/badges/childBadge/ChildBadge"
import DifficultyBadge from "../../components/badges/difficultyBadge/DifficultyBadge"
import { ScheduleBadge } from "../../components/badges/scheduleBadge/ScheduleBadge"
import StatusBadge from "../../components/badges/statusBadge/StatusBadge"
import { QUERY_KEYS } from "../../constants"
import { useSubscription } from "../../hooks/useSubscription"
import {
  completeQuests,
  deleteQuest,
  skipQuests,
  undoVerifyQuest,
} from "../../networking/quests"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { localStorageItems } from "../../util/storage"
import {
  CoachUpsellModalType,
  useCoachUpsellModalStore,
} from "../care/CoachUpsellModal"
import useCareTeam from "../care/useCareTeam"

const ViewQuestModal = () => {
  const { isOpen, onClose, questInstance } = useViewQuestModalStore()
  const { onOpen: openReassignQuestModal } = useReassignQuestModalStore()

  if (!questInstance) return <></>

  const questData = questInstance.series
  const frequencyTarget = questInstance.series.current_frequency_target

  return (
    <Modal isOpen={isOpen} onClose={onClose} animate>
      <ModalHeader
        title="Quest Details"
        onClose={onClose}
        style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
      />
      <ModalContent style={{ width: "min(500px, 100vw)" }}>
        <FlexBox
          direction="column"
          gap={SPACING.space4}
          style={{
            padding: `${SPACING.space4} ${SPACING.space6}`,
          }}
        >
          <Typography variant="h3">{questData.title}</Typography>
          {!!frequencyTarget && (
            <FlexBox
              direction="row"
              justify="space-between"
              align="center"
              wrap={false}
              gap={SPACING.space2}
              style={{
                border: `1px solid ${JoonUIColor.border.accent}`,
                borderRadius: SPACING.space2,
                padding: SPACING.space3,
              }}
            >
              <FlexBox direction="column" gap={SPACING.space1}>
                <Typography variant="caption" textAlign="left">
                  Frequency target: {frequencyTarget.frequency}x per week
                </Typography>
                <Typography variant="bodySmall" textAlign="left">
                  Goal set by{" "}
                  {frequencyTarget.creator_profile?.nickname || "Therapist"} on{" "}
                  {dayjs(frequencyTarget.date_created).format("MMMM D, YYYY")}
                </Typography>
              </FlexBox>

              <div style={{ minWidth: "32px" }}>
                <TherapistIcon
                  size={32}
                  color={JoonUIColor.background.accent}
                />
              </div>
            </FlexBox>
          )}
          {questData.mandatory && (
            <FlexBox
              direction="row"
              align="center"
              gap={SPACING.space2}
              wrap={false}
            >
              <div style={{ minWidth: "24px" }}>
                <CircleCheckboxChecked size={24} />
              </div>
              <Typography variant="bodyXSmall">
                {!!frequencyTarget
                  ? "All therapist modified Quests are mandatory."
                  : "This is a mandatory Quest your child must complete. To change it to an optional Quest, edit the Quest and disable this setting in the Additional Options section."}
              </Typography>
            </FlexBox>
          )}
          <hr />
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Status</Typography>
            <StatusBadge status={questInstance.status} />
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Category and difficulty</Typography>
            <FlexBox
              direction="row"
              gap={SPACING.space2}
              wrap={false}
              align="center"
              justify="flex-start"
            >
              <CategoryBadge skill={questData.skill} />
              <DifficultyBadge
                difficulty={questData.difficulty}
                reward={questData.redeemable_reward}
              />
            </FlexBox>
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Assigned to</Typography>
            <FlexBox
              direction="row"
              gap={SPACING.space1}
              align="center"
              wrap={false}
            >
              <ChildBadge childId={questInstance.user_id} />
              <button
                onClick={openReassignQuestModal}
                style={{ padding: `${SPACING.space1} ${SPACING.space3}` }}
              >
                <Typography
                  variant="caption"
                  color={JoonUIColor.text.primaryAccent}
                >
                  Reassign
                </Typography>
              </button>
              <ReassignQuest />
            </FlexBox>
          </FlexBox>
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Schedule</Typography>
            <FlexBox direction="row" gap={SPACING.space1}>
              <ScheduleBadge questInstance={questInstance} type="routine" />
              <ScheduleBadge questInstance={questInstance} type="repetition" />
              {questInstance.series.reminder_time && (
                <ScheduleBadge questInstance={questInstance} type="reminder" />
              )}
            </FlexBox>
          </FlexBox>
          {questData.description && (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="caption">Description</Typography>
              <Badge>{questData.description}</Badge>
            </FlexBox>
          )}
          {questData.photo_url && (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="caption">Photo</Typography>
              <img
                src={questData.photo_url || ""}
                alt="Quest"
                style={{ width: "100px", height: "auto" }}
              />
            </FlexBox>
          )}
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Requires Review</Typography>
            <Badge>{questData.requires_review ? "Yes" : "No"}</Badge>
          </FlexBox>
          {questData.timer_length && (
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="caption">Timer</Typography>
              <Badge>
                {questData.timer_length.split(":")[1] +
                  " min, " +
                  questData.timer_length.split(":")[2] +
                  " sec"}
              </Badge>
            </FlexBox>
          )}
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Log</Typography>
            <QuestLog />
          </FlexBox>
        </FlexBox>
      </ModalContent>
      <div
        style={{
          position: "sticky",
          bottom: "0",
          width: "100%",
          background: "white",
          borderTop: `1px solid ${JoonUIColor.border.default}`,
        }}
      >
        <FlexBox
          direction="row"
          wrap={false}
          align="center"
          style={{ padding: `${SPACING.space3} ${SPACING.space4}` }}
        >
          <DeleteQuestButton />
          <EditQuestButton />
          <SkipQuestButton />
          <UndoButton />
          <CompleteQuestButton />
        </FlexBox>
      </div>
    </Modal>
  )
}

export default ViewQuestModal

const CompleteQuestButton = () => {
  const { questInstance, onClose: closeViewQuestModal } =
    useViewQuestModalStore()
  const { checkRemainingFreeQuests } = useSubscription()
  const { questBoardDate } = useQBDatePickerStore()
  const queryClient = useQueryClient()

  const questIsOpen =
    questInstance?.status === QuestStatus.OPEN ||
    questInstance?.status === QuestStatus.RETRY
  if (!questIsOpen) return <></>

  const onClickCompleteQuest = async () => {
    if (!questInstance) return
    const questIsInFuture =
      questBoardDate.format("YYYY-MM-DD") > dayjs().format("YYYY-MM-DD")
    if (questIsInFuture)
      throw toast.error("You cannot complete a Quest in the future.")

    checkRemainingFreeQuests()
    try {
      await completeQuests({ questInstanceIds: [questInstance.id] })
      toast.success("Quest completed")
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      closeViewQuestModal()
    } catch (error) {
      toast.error("Error completing Quest")
    }
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space2}
      onClick={onClickCompleteQuest}
      style={{
        padding: `${SPACING.space2} ${SPACING.space4}`,
        cursor: "pointer",
        flex: 1,
      }}
    >
      <CheckIcon color={JoonUIColor.semantic.success} size={24} />
      <Typography
        variant="bodyBold"
        color={JoonUIColor.semantic.success}
        textAlign="center"
        style={{ lineHeight: "16px" }}
      >
        Mark Complete
      </Typography>
    </FlexBox>
  )
}

const SkipQuestButton = () => {
  const { questInstance, onClose: closeViewQuestModal } =
    useViewQuestModalStore()
  const queryClient = useQueryClient()

  const questIsRepeating = questInstance?.series.type === QuestType.REPEATING
  const questIsOpen =
    questInstance?.status === QuestStatus.OPEN ||
    questInstance?.status === QuestStatus.RETRY
  if (!questIsRepeating || !questIsOpen) return <></>

  const onClickSkipQuest = async () => {
    if (!questInstance) return

    try {
      await skipQuests({ questInstanceIds: [questInstance.id] })
      toast.success("Quest skipped")
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      closeViewQuestModal()
    } catch (error) {
      toast.error("Error skipping Quest")
    }
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space2}
      onClick={onClickSkipQuest}
      style={{
        padding: `${SPACING.space2} ${SPACING.space4}`,
        cursor: "pointer",
        flex: 0.5,
      }}
    >
      <SkipIcon color={JoonUIColor.icon.neutral} size={24} />
      <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
        Skip
      </Typography>
    </FlexBox>
  )
}

const EditQuestButton = () => {
  const { onOpen: openAddEditQuestModal } = useAddEditQuestStore()
  const { questInstance, onClose: closeViewQuestModal } =
    useViewQuestModalStore()

  const onClickEditQuest = () => {
    if (!questInstance) return
    openAddEditQuestModal(questInstance)
    closeViewQuestModal()
    trackAnalyticEvent(ANALYTIC_EVENTS.OPEN_EDIT_QUEST)
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space2}
      style={{
        flex: 0.5,
        cursor: "pointer",
        padding: `${SPACING.space2} ${SPACING.space4}`,
      }}
      onClick={onClickEditQuest}
    >
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4050_2472)">
          <path
            d="M17.97 0.904297L15.7012 3.17305L21.795 9.2668L24.0637 6.99805C25.2356 5.82617 25.2356 3.92773 24.0637 2.75586L22.2169 0.904297C21.045 -0.267578 19.1465 -0.267578 17.9747 0.904297H17.97ZM14.6419 4.23242L3.7153 15.1637C3.2278 15.6512 2.87155 16.2559 2.67468 16.9168L1.0153 22.5559C0.898113 22.9543 1.00593 23.3809 1.29655 23.6715C1.58718 23.9621 2.01374 24.0699 2.40749 23.9574L8.04655 22.298C8.70749 22.1012 9.31218 21.7449 9.79967 21.2574L20.7356 10.3262L14.6419 4.23242Z"
            fill="#7B838E"
          />
        </g>
        <defs>
          <clipPath id="clip0_4050_2472">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.967773)"
            />
          </clipPath>
        </defs>
      </svg>

      <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
        Edit
      </Typography>
    </FlexBox>
  )
}

const DeleteQuestButton = () => {
  const { questInstance, onClose: closeViewQuestModal } =
    useViewQuestModalStore()
  const { onOpen: openCoachUpsellModal } = useCoachUpsellModalStore()
  const [isDeleting, setIsDeleting] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const queryClient = useQueryClient()
  const { hasCoach } = useCareTeam()

  const isTherapistCreated =
    questInstance?.series?.assigner_profile?.role === UserRole.THERAPIST
  if (!questInstance || isTherapistCreated) return <></>

  const onClickDeleteQuest = async () => {
    try {
      setIsDeleting(true)
      await deleteQuest(questInstance.series.id)
      trackAnalyticEvent(ANALYTIC_EVENTS.DELETE_QUEST)
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      toast.success("Quest deleted")
      setShowConfirmModal(false)
      closeViewQuestModal()
      if (hasCoach) return
      const lastSeenUpsellModalDate = localStorage.getItem(
        localStorageItems.deleteCoachUpsellModalSeenDate
      )
      const needsToSeeDeleteCoachUpsellModal =
        !dayjs().isSame(dayjs(lastSeenUpsellModalDate), "week") ||
        !lastSeenUpsellModalDate
      if (needsToSeeDeleteCoachUpsellModal)
        openCoachUpsellModal(CoachUpsellModalType.delete)
    } catch (error) {
      toast.error("Error deleting Quest")
    } finally {
      setIsDeleting(false)
    }
  }

  return (
    <>
      <FlexBox
        direction="column"
        align="center"
        gap={SPACING.space2}
        style={{
          padding: `${SPACING.space2} ${SPACING.space4}`,
          flex: 0.5,
          cursor: "pointer",
        }}
        onClick={() => setShowConfirmModal(true)}
      >
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.04559 0.829688L7.65988 1.5H2.51702C1.56881 1.5 0.802734 2.17031 0.802734 3C0.802734 3.82969 1.56881 4.5 2.51702 4.5H23.0884C24.0367 4.5 24.8027 3.82969 24.8027 3C24.8027 2.17031 24.0367 1.5 23.0884 1.5H17.9456L17.5599 0.829688C17.2706 0.31875 16.6759 0 16.0277 0H9.57773C8.92952 0 8.33488 0.31875 8.04559 0.829688ZM23.0884 6H2.51702L3.65273 21.8906C3.73845 23.0766 4.86345 24 6.21881 24H19.3867C20.742 24 21.867 23.0766 21.9527 21.8906L23.0884 6Z"
            fill={JoonUIColor.semantic.destructive}
          />
        </svg>

        <Typography variant="bodyBold" color={JoonUIColor.semantic.destructive}>
          Delete
        </Typography>
      </FlexBox>
      <Modal
        isOpen={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        animate
      >
        <ModalHeader
          title="Are you sure?"
          onClose={() => setShowConfirmModal(false)}
          style={{ borderBottom: `1px solid ${JoonUIColor.border.default}` }}
        />
        <FlexBox
          style={{
            padding: `${SPACING.space4} ${SPACING.space6} ${SPACING.space6}`,
            width: "min(500px, 100vw)",
          }}
          gap={SPACING.space4}
        >
          <Typography variant="body">
            This action will delete the Quest and all Quests in the series. You
            will not be able to undo this action.
          </Typography>

          <FlexBox
            align="center"
            justify="flex-end"
            wrap={false}
            gap={SPACING.space2}
          >
            <button onClick={() => setShowConfirmModal(false)}>
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.primaryAccent}
              >
                Cancel
              </Typography>
            </button>
            <Button
              buttonType="redPrimary"
              onClick={onClickDeleteQuest}
              isLoading={isDeleting}
              text="Delete"
            />
          </FlexBox>
        </FlexBox>
      </Modal>
    </>
  )
}

const UndoButton = () => {
  const { questInstance, onClose: closeViewQuestModal } =
    useViewQuestModalStore()
  const queryClient = useQueryClient()

  // Only display button if Quest is verified
  const validUndoStatuses = [
    QuestStatus.SKIPPED,
    QuestStatus.REJECTED,
    QuestStatus.VERIFIED,
    QuestStatus.REJECTED_VIEWED,
    QuestStatus.REDEEMED, // Show button but surface message for redeemed Quests
  ]
  if (!questInstance || !validUndoStatuses.includes(questInstance.status))
    return <></>

  const onClickUndoQuest = async () => {
    if (!questInstance) return

    if (questInstance.status === QuestStatus.REDEEMED) {
      return toast.error(
        "You cannot undo this because your child has already redeemed the rewards."
      )
    }

    try {
      await undoVerifyQuest(questInstance.id)
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
      queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD_REORDER])
      closeViewQuestModal()
      if (questInstance.status === QuestStatus.VERIFIED) {
        toast.success("Quest verification undone")
      } else if (questInstance.status === QuestStatus.REJECTED) {
        toast.success("Quest rejection undone")
      } else if (questInstance.status === QuestStatus.SKIPPED) {
        toast.success("Quest skip undone")
      } else {
        toast.success("Quest action undone")
      }
    } catch (error) {
      toast.error("Error undoing Quest action")
    }
  }

  return (
    <FlexBox
      direction="column"
      align="center"
      gap={SPACING.space2}
      onClick={onClickUndoQuest}
      style={{
        padding: `${SPACING.space2} ${SPACING.space4}`,
        cursor: "pointer",
        flex: 0.5,
      }}
    >
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_3253_56413)">
          <path
            d="M2.74121 10.4998H2.34277C1.71934 10.4998 1.21777 9.99829 1.21777 9.37485V3.37485C1.21777 2.92016 1.48965 2.50766 1.91152 2.33422C2.3334 2.16078 2.81621 2.25454 3.13965 2.57797L5.08965 4.52797C9.1959 0.473285 15.81 0.487347 19.8928 4.57485C23.9943 8.67641 23.9943 15.3233 19.8928 19.4248C15.7912 23.5264 9.14434 23.5264 5.04277 19.4248C4.45684 18.8389 4.45684 17.8874 5.04277 17.3014C5.62871 16.7155 6.58027 16.7155 7.16621 17.3014C10.0959 20.2311 14.8443 20.2311 17.774 17.3014C20.7037 14.3717 20.7037 9.62329 17.774 6.6936C14.8584 3.77797 10.1475 3.76391 7.21309 6.64672L9.13965 8.57797C9.46309 8.90141 9.55684 9.38422 9.3834 9.8061C9.20996 10.228 8.79746 10.4998 8.34277 10.4998H2.74121Z"
            fill="#7B838E"
          />
        </g>
        <defs>
          <clipPath id="clip0_3253_56413">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.467773)"
            />
          </clipPath>
        </defs>
      </svg>

      <Typography variant="bodyBold" color={JoonUIColor.icon.neutral}>
        Undo
      </Typography>
    </FlexBox>
  )
}
