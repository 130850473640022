import { FlexBox, SPACING } from "@joonapp/web-shared"

import { useCoachParentTasks } from "./useCoachParentTasks"
import DataTaskCard from "../../academy/dataTasks/DataTaskCard"

const CoachParentTasks = () => {
  const { coachParentTasks } = useCoachParentTasks()

  return (
    <FlexBox
      direction="column"
      gap={SPACING.space2}
      style={{ padding: SPACING.space4 }}
    >
      {coachParentTasks?.map((task) => <DataTaskCard dataTask={task} />)}
    </FlexBox>
  )
}

export default CoachParentTasks
