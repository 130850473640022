import * as Sentry from "@sentry/react"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError } from "axios"
import { useEffect } from "react"
import { create } from "zustand"

import { QUERY_KEYS } from "../constants"
import {
  createChildren,
  createFamily,
  createUser,
} from "../networking/authentication"
import {
  useFamilyQuery,
  useQuestionnairesQuery,
  useSubscriberInfo,
  useUserNetworkInfo,
  useUserQuery,
  useUserCountryCodeInfo,
} from "../networking/queries"
import {
  getSubscriberInfo,
  updateSubscriberInfo,
} from "../networking/subscriptions"
import { joinFamily, loadFamilies, loadUser } from "../networking/user"
import { ParentDataInterface } from "../types"
import { GAEvent, trackAnalyticEvent, trackGAEvent } from "../util/analytics"
import {
  submitChildQuestionnaires,
  submitParentQuestionnaires,
} from "../util/questionnaires"
import { history } from "../util/router"
import { localStorageItems, sessionManager } from "../util/storage"
import { separateFamily } from "../util/util"

export const OnboardingStep = {
  ParentingQuestionnaire: 0,
  AddChildOrJoinFamily: 1,
  CoparentCode: 2,
  AddChildren: 3,
  ParentNameNickname: 4,
  ParentAvatar: 5,
  EmailPassword: 6,
  CreateAccountWithCredentials: 7,
}

export const ChildStep = Object.freeze({
  ChildInfo: 0,
  QuestionnaireDisclaimer: 1,
  Questionnaire: 2,
})

interface OnboardContextStore {
  isCoparent: boolean
  setIsCoparent: (value: boolean) => void
  coparentCode: string
  setCoparentCode: (value: string) => void

  parentData: ParentDataInterface
  creatingPlan: boolean
  setCreatingPlan: (value: boolean) => void
  isCreatingUser: boolean
  setIsCreatingUser: (value: boolean) => void
  currentStep: number
  childStep: number
  parentQuestionnaireStep: number
  setParentQuestionnaireStep: (value: number) => void
  setChildStep: (value: number) => void
  setParentData: (
    fieldName:
      | "name"
      | "nickname"
      | "avatar"
      | "parentQuestionnaireMapping"
      | "childrenData",
    value: any
  ) => void
  setStep: (value: number) => void
  nextStep: () => void
  previousStep: () => void
  isSubmittingData: boolean
  setIsSubmittingData: (value: boolean) => void
}

const useOnboardContext = create<OnboardContextStore>((set) => ({
  isCoparent: false,
  setIsCoparent: (value: boolean) => set({ isCoparent: value }),
  coparentCode: "",
  setCoparentCode: (value: string) =>
    set({ coparentCode: value.toUpperCase() }),

  parentData: {
    name: "",
    nickname: "",
    avatar: null,
    parentQuestionnaireMapping: {},
    childrenData: [],
  },
  setParentData: (fieldName, value) =>
    set((state) => ({
      parentData: { ...state.parentData, [fieldName]: value },
    })),

  creatingPlan: false,
  setCreatingPlan: (value: boolean) => set({ creatingPlan: value }),
  isCreatingUser: false,
  setIsCreatingUser: (value: boolean) => set({ isCreatingUser: value }),
  // STEPS
  currentStep: 0,
  childStep: 0,
  parentQuestionnaireStep: 0,
  setParentQuestionnaireStep: (value: number) =>
    set({ parentQuestionnaireStep: value }),

  setChildStep: (value: number) => set({ childStep: value }),
  setStep: (value: number) => set({ currentStep: value }),
  nextStep: () => {
    set((state: OnboardContextStore) => {
      if (state.currentStep === OnboardingStep.CoparentCode) {
        return { currentStep: OnboardingStep.ParentNameNickname }
      } else if (
        state.currentStep === OnboardingStep.ParentAvatar &&
        sessionManager.hasRefreshToken()
      ) {
        return { currentStep: OnboardingStep.CreateAccountWithCredentials }
      } else return { currentStep: state.currentStep + 1 }
    })
  },
  previousStep: () => {
    set((state: OnboardContextStore) => {
      if (state.currentStep === 0) {
        sessionManager.clearAuthTokens()
        history.goBack()
        return {}
      } else if (state.currentStep === OnboardingStep.ParentNameNickname) {
        return state.isCoparent
          ? { currentStep: OnboardingStep.CoparentCode }
          : { currentStep: OnboardingStep.AddChildren }
      } else if (state.currentStep === OnboardingStep.AddChildren) {
        return { currentStep: OnboardingStep.AddChildOrJoinFamily }
      } else return { currentStep: state.currentStep - 1 }
    })
  },

  // Data submit
  isSubmittingData: false,
  setIsSubmittingData: (value: boolean) => set({ isSubmittingData: value }),
}))

export const useAccountCreation = () => {
  const {
    isSubmittingData,
    setIsSubmittingData,
    parentData,
    isCoparent,
    coparentCode,
    setIsCreatingUser,
  } = useOnboardContext()
  const queryClient = useQueryClient()

  const { data: questionsResponse, status } = useQuestionnairesQuery()

  const uploadOnboardingSecondaryData = async () => {
    if (status === "loading" || status === "error") return {}
    const { onboardingQuestionnaires } = questionsResponse
    if (isSubmittingData) return

    const hasChildQuestionnaires =
      parentData.childrenData.length > 0 &&
      !(
        Array.isArray(parentData.childrenData[0].questionAnswerMapping) &&
        parentData.childrenData[0].questionAnswerMapping.length === 0
      )

    setIsSubmittingData(true)
    try {
      // Create User, Family, and Children
      setIsCreatingUser(true)
      await createUser(parentData.name, parentData.avatar as string)
      setIsCreatingUser(false)
      trackAnalyticEvent("create-acc-user-created")

      if (isCoparent) {
        await joinFamily(coparentCode, parentData.nickname)
        trackAnalyticEvent("create-acc-family-joined")
      } else {
        const family = await createFamily(parentData.nickname)
        Sentry.addBreadcrumb({
          category: "account",
          message: `Family created: ${family?.data?.id}`,
          level: "info",
        })
        const children = await createChildren(
          family.data.id,
          parentData.childrenData
        )
        Sentry.addBreadcrumb({
          category: "account",
          message: `Children created: ${JSON.stringify(children)}`,
          level: "info",
        })
        trackAnalyticEvent("create-acc-family-created")

        // Set marker to show freemium popup when verifying first Quest.
        // We only need to set new user flags for those that are creating a family.
        sessionManager.setNewUserLocalStorageFlags()
      }
      Sentry.addBreadcrumb({
        category: "function",
        message: "Starting loadFamilies in uploadOnboardingSecondaryData",
        level: "info",
      })

      const familiesData = await loadFamilies()

      Sentry.addBreadcrumb({
        category: "function",
        message: "Finished loadFamilies in uploadOnboardingSecondaryData",
        level: "info",
      })

      // Set paywall flag if applicable
      const subscriberInfo = await getSubscriberInfo(familiesData)
      if (!subscriberInfo.plan_is_active) {
        sessionManager.setLocalStorageFlag(localStorageItems.needsToSeePaywall)
      }

      // Submit Questionnaires
      const userData = await loadUser()
      if (!familiesData || !userData)
        throw new Error(
          `Error loading data families:${familiesData} user:${userData}`
        )
      const { children } = separateFamily(userData, familiesData)
      if (hasChildQuestionnaires) {
        submitChildQuestionnaires(
          onboardingQuestionnaires,
          parentData,
          children
        )
      }
      if (Object.keys(parentData.parentQuestionnaireMapping).length > 0) {
        submitParentQuestionnaires(
          onboardingQuestionnaires,
          parentData,
          userData.id
        )
      }
      trackAnalyticEvent("create-acc-questionnaires-submitted")

      trackGAEvent(GAEvent.CREATE_ACCOUNT)
    } catch (error) {
      Sentry.withScope((scope) => {
        scope.setLevel("error")
        scope.setTag("function", "uploadOnboardingSecondaryData")
        scope.setExtra("status", status)
        scope.setExtra("isSubmittingData", isSubmittingData)
        scope.setExtra("parentData", JSON.stringify(parentData))
        scope.setExtra("isCoparent", isCoparent)
        scope.setExtra("coparentCode", coparentCode)

        if (error instanceof AxiosError) {
          scope.setExtra("axiosError", {
            message: error.message,
            code: error.code,
            response: error.response
              ? {
                  data: error.response.data,
                  status: error.response.status,
                  headers: error.response.headers,
                }
              : null,
            request: error.request
              ? {
                  method: error.request.method,
                  url: error.request.url,
                  headers: error.request.headers,
                }
              : null,
          })
        } else {
          scope.setExtra("error", {
            message: (error as Error)?.message,
            stack: (error as Error)?.stack,
          })
        }

        Sentry.captureException(error)
      })

      // Instead of throwing, return an error object
      throw error
    } finally {
      queryClient.invalidateQueries([QUERY_KEYS.FAMILY])
      setIsSubmittingData(false)
    }
  }
  return { uploadOnboardingSecondaryData }
}

export default useOnboardContext

export const usePaywall = () => {
  const { user } = useUserQuery()
  const { data: family } = useFamilyQuery()
  const { data: subscriber } = useSubscriberInfo()
  const needsToSeePaywall = localStorage.getItem(
    localStorageItems.needsToSeePaywall
  )
  const redirectLink = localStorage.getItem(localStorageItems.redirectLink)

  useEffect(() => {
    if (!user || !family) return

    // eventually we want to instead have everyone sent to the paywall
    // and the paywall is dynamic with the redirect link params
    // For now, if they have redirect, they bypass paywall
    if (!!redirectLink) {
      history.push(redirectLink)
      localStorage.removeItem(localStorageItems.redirectLink)
      localStorage.removeItem(localStorageItems.needsToSeePaywall)
    } else if (!!needsToSeePaywall) {
      // If a user is not a coparent, or if they are a coparent and not subscribed, show them paywall
      const userIsCoparent = localStorage.getItem(localStorageItems.isCoparent)
      const familyIsSubscribed = subscriber?.active_plan?.is_active

      if (!userIsCoparent || !familyIsSubscribed) {
        history.push("/paywall/trial")
        localStorage.removeItem(localStorageItems.needsToSeePaywall)
        localStorage.removeItem(localStorageItems.isCoparent)
      }
    }
  }, [user, family, needsToSeePaywall, redirectLink, subscriber])
}

export const useUpdateSubscriberAttributes = () => {
  const { data: family } = useFamilyQuery()
  const { data: subscriber } = useSubscriberInfo()
  const { data: ipAddress } = useUserNetworkInfo()
  const { data: countryCode } = useUserCountryCodeInfo()

  const cookies = document.cookie.split("; ")
  const fbClickCookie = cookies.find((cookie) => cookie.startsWith("_fbc="))
  const fbClickId = fbClickCookie ? fbClickCookie.split("=")[1] : undefined
  const fbBrowserCookie = cookies.find((cookie) => cookie.startsWith("_fbp="))
  const fbBrowser = fbBrowserCookie ? fbBrowserCookie.split("=")[1] : undefined

  useEffect(() => {
    if (!family || !ipAddress || !countryCode) return
    updateSubscriberInfo(family, ipAddress, countryCode, fbClickId, fbBrowser)
  }, [family, subscriber, ipAddress, countryCode])
}
