import {
  CustomReward,
  CustomRewardCurrency,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useMemo } from "react"

import useRewards from "./hooks/useRewards"
import PurchasedRewardGroup from "./redeem/PurchasedRewardGroup"
import RewardRedeemCard from "./redeem/RewardRedeemCard"
import { useRewardsChildToggleHeaderStore } from "./redeem/RewardsChildToggleHeader"
import TotalCoinsEarnedCard from "./redeem/TotalCoinsEarnedCard"
import { RewardsEmpty } from "./RewardsEmpty"
import { LoadingItemsSkeleton } from "../../components/skeleton/Skeleton"
import {
  useCustomRewardBalancesQuery,
  useCustomRewardLogsQuery,
  useCustomRewardsQuery,
} from "../../networking/rewards/queries"
import { useRouter } from "../../util/router"

const RewardsRedeem = () => {
  const { selectedChildId } = useRewardsChildToggleHeaderStore()
  const {
    hasPreviouslyPurchasedOrRedeemedRewards,
    childRewardBalances,
    selectedChild,
    childHasAvailableRewardsToPurchase,
    isLoading,
  } = useRewards()
  const { data: rewards } = useCustomRewardsQuery()
  const { data: rewardLogs } = useCustomRewardLogsQuery()
  const { data: balances } = useCustomRewardBalancesQuery()
  const router = useRouter()

  const sortedChildRewardBalances = childRewardBalances?.sort(
    (a, b) => b.balance - a.balance
  )

  const rewardsWithBalances = useMemo(() => {
    const rewardIdsToRewardsWithBalances: {
      [key: number]: CustomReward
    } = {}

    rewards?.forEach((reward) => {
      rewardIdsToRewardsWithBalances[reward.id] = reward
    })
    rewardLogs?.forEach((rewardLog) =>
      balances?.some((balance) => balance.reward.id === rewardLog.reward_id) ||
      !rewardLog.reward.date_deleted
        ? (rewardIdsToRewardsWithBalances[rewardLog.reward_id] =
            rewardLog.reward)
        : null
    )
    return rewardIdsToRewardsWithBalances
  }, [rewards, rewardLogs, balances])

  const { dollarRewards, minuteRewards, itemRewards } = useMemo(() => {
    const sortedRewards = Object.values(rewardsWithBalances).sort(
      (a, b) => a.cost - b.cost
    )
    const dollarRewards = [] as CustomReward[]
    const minuteRewards = [] as CustomReward[]
    const itemRewards = [] as CustomReward[]
    sortedRewards.forEach((reward) => {
      if (reward.currency === CustomRewardCurrency.DOLLAR) {
        dollarRewards.push(reward)
      } else if (reward.currency === CustomRewardCurrency.MINUTE) {
        minuteRewards.push(reward)
      } else if (reward.currency === CustomRewardCurrency.ITEM) {
        itemRewards.push(reward)
      }
    })
    return { dollarRewards, minuteRewards, itemRewards }
  }, [rewardsWithBalances])

  return (
    <div style={{ paddingTop: SPACING.space3, paddingBottom: SPACING.space3 }}>
      {isLoading ? (
        <LoadingItemsSkeleton />
      ) : (!rewards?.length || rewards.length === 0) && !selectedChildId ? (
        <RewardsEmpty />
      ) : (
        <FlexBox direction="column" gap={SPACING.space4}>
          {selectedChildId && (
            <>
              <TotalCoinsEarnedCard childId={selectedChildId} />
              <Typography variant="bodyBold">Current reward balance</Typography>
            </>
          )}
          {selectedChildId === null ? (
            <>
              {(minuteRewards.length > 0 || dollarRewards.length > 0) && (
                <>
                  <FlexBox
                    direction="row"
                    justify="flex-start"
                    align="center"
                    gap={SPACING.space1}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    <img
                      src={"/images/rewards_currency_exchange.png"}
                      alt="Currency Exchange"
                      style={{ minHeight: "32px", maxHeight: "32px" }}
                    />
                    <Typography variant="bodyBold">
                      Currency exchange
                    </Typography>
                  </FlexBox>
                  <FlexBox direction="column" gap={SPACING.space2}>
                    {minuteRewards.map((reward) => (
                      <PurchasedRewardGroup key={reward.id} reward={reward} />
                    ))}
                    {dollarRewards.map((reward) => (
                      <PurchasedRewardGroup key={reward.id} reward={reward} />
                    ))}
                  </FlexBox>
                </>
              )}
              {itemRewards.length > 0 && (
                <>
                  <Typography
                    variant="bodyBold"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      whiteSpace: "nowrap",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3">🎈</Typography> Item or activity
                  </Typography>
                  <FlexBox direction="column" gap={SPACING.space2}>
                    {itemRewards.map((reward) => (
                      <PurchasedRewardGroup key={reward.id} reward={reward} />
                    ))}
                  </FlexBox>
                </>
              )}
            </>
          ) : childRewardBalances?.length > 0 ? (
            <FlexBox direction="column" gap={SPACING.space1}>
              {sortedChildRewardBalances.map((balance, i: number) => (
                <RewardRedeemCard key={i} rewardBalance={balance} />
              ))}
            </FlexBox>
          ) : childHasAvailableRewardsToPurchase ? (
            <FlexBox
              direction="column"
              justify="center"
              align="center"
              style={{
                padding: `${SPACING.space3} ${SPACING.space4}`,
                background: JoonUIColor.background.primaryNeutral,
                border: `1px solid ${JoonUIColor.border.default}`,
                borderRadius: SPACING.space2,
              }}
            >
              <Typography
                variant="bodyXSmall"
                style={{ fontWeight: 700, textAlign: "center" }}
              >
                {selectedChild?.user.name} has not purchased any rewards yet!
              </Typography>
              <Typography variant="bodyXSmall" style={{ textAlign: "center" }}>
                They can purchase some by clicking on the teleportation device
                under their doter and talking to your avatar at the shop
                counter!
              </Typography>
            </FlexBox>
          ) : (
            <FlexBox
              justify="center"
              align="center"
              style={{
                padding: SPACING.space3,
                background: JoonUIColor.background.primaryNeutral,
                border: `1px solid ${JoonUIColor.border.default}`,
                borderRadius: SPACING.space2,
              }}
            >
              <Typography variant="caption">
                No custom rewards set up yet!
              </Typography>
            </FlexBox>
          )}
        </FlexBox>
      )}
      {!isLoading && hasPreviouslyPurchasedOrRedeemedRewards && (
        <button
          style={{
            marginTop: SPACING.space6,
            marginBottom: SPACING.space10,
            width: "100%",
          }}
          onClick={() => router.push("/rewards/transactions")}
        >
          <Typography
            variant="caption"
            textAlign="center"
            color={JoonUIColor.semantic.primary}
          >
            View all reward transactions
          </Typography>
        </button>
      )}
    </div>
  )
}

export default RewardsRedeem
