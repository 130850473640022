import {
  Identify,
  identify,
  init,
  reset,
  track,
  setGroup,
  setUserId,
} from "@amplitude/analytics-browser"

import { localStorageItems } from "./storage"

export const ANALYTIC_EVENTS = {
  //// Auth
  PARENT_LOGIN_APPROVED: "parent_login_approved", // Parent successfully logs in with email/password
  GOOGLE_LOGIN_APPROVED: "google_login_approved", // Parent successfully logs in with Google
  APPLE_LOGIN_APPROVED: "apple_login_approved", // Parent successfully logs in with Apple
  AUTH_NO_ACCOUNT: "auth_no_account", // User attempts login but has no account
  AUTH_HAS_ACCOUNT: "auth_has_account", // User attempts login and has existing account
  CREATE_LOGIN: "create_login", // User creates a new login/account
  PURCHASE_COMPLETED: "purchase_completed", // User completes a subscription purchase
  PURCHASE_CANCELLED: "purchase_cancelled", // User cancels during purchase flow
  SIGNUP_FAILED: "signup_failed", // Account creation attempt fails

  //// Invited by therapist/code page
  INVITED_BY_THERAPIST: "invited_by_therapist", // User arrives via therapist invitation
  NOT_INVITED_BY_THERAPIST: "not_invited_by_therapist", // User arrives organically (not via therapist)
  ONBOARDING_USING_THERAPIST_CODE: "onboarding_using_therapist_code", // User enters therapist code during onboarding

  //// Onboarding
  SHOW_WELCOME: "show_welcome", // Welcome screen is displayed to user
  START_ONBOARD: "start_onboard", // User begins onboarding process
  START_CHILD_ONBOARDING_SURVEY: "start_child_onboarding_survey", // User starts child-specific survey
  INPUT_CHILD_INFO_INTENT: "input_child_info_intent", // User begins entering child information
  INPUT_CHILD_INFORMATION: "input_child_information", // User completes child information entry
  ADD_CHILDREN: "add_children", // User adds one or more children to account
  SELECT_PARENT_AVATAR: "select_parent_avatar", // Parent chooses their avatar
  ADD_PARENT_NAME: "add_parent_name", // Parent enters their name
  ADD_PARENT_NICKNAME: "add_parent_nickname", // Parent enters their nickname
  ADD_PARENT_NAME_AND_NICKNAME: "add_parent_name_and_nickname", // Parent completes both name fields
  ENTER_COPARENT_CODE: "enter_coparent_code", // User enters code to link with coparent
  INVALID_COPARENT_CODE: "invalid_coparent_code", // Entered coparent code is invalid
  ONBOARDING_QUESTION: (question: number) => `onboarding_question_${question}`, // User answers specific onboarding question
  SHOW_CHECKOUT: "show_checkout", // Checkout screen is displayed
  SHOW_BILLING: "show_billing", // Billing screen is displayed
  EXPERIMENT_WEB_WELCOME: "experiment_web_welcome", // A/B test welcome screen variant shown
  EXPERIMENT_WEB_WELCOME_CTA: "experiment_web_welcome_cta", // User clicks CTA in welcome experiment
  IOS_DOWNLOAD_PROMPT: "ios_download_prompt", // params: { action: 'download' | 'no_iphone' | 'close'}
  IOS_DOWNLOAD_LINK_CLICK: "ios_download_link_click", // User clicks iOS app download link
  IOS_BANNER_CLICK: "ios_banner_click", // User clicks iOS app banner
  ADD_TO_HOMESCREEN_BANNER_CLICK: "add_to_homescreen_banner_click", // User clicks add to homescreen banner
  HAS_ACCOUNT: "has_account", // User indicates they have existing account
  HAS_FAMILY_CODE: "has_family_code", // User indicates they have a family code
  CONTINUE_ADD_CHILDREN: "continue_add_children", // User proceeds to add children
  ONBOARD_HAS_ACCOUNT_LOGIN_BUTTON_CLICK:
    "onboard_has_account_login_button_click", // User with account clicks login
  ONBOARD_HAS_FAMILY_BUTTON_CLICK: "onboard_has_family_button_click", // User with family code clicks button
  GET_STARTED_STEP_CLICKED: "get_started_step_clicked", // params: {step: number step}
  ABA_QUESTION_ANSWERED: "aba_question_answered", // user said their child has ABA

  //// Paywall
  SHOW_PAYWALL: "show_paywall", // Paywall screen is displayed
  PAYWALL_START_TRIAL: "paywall_start_trial", // User starts free trial
  PAYWALL_SKIP: "paywall_skip", // User skips paywall
  PAYWALL_SELECT_PRICE: "paywall_select_price", // params: { price }
  PAYWALL_NEXT: "paywall_next", // User proceeds past paywall

  //// Coach In-App Upsell
  COACH_UPSELL_BANNER_VIEWED: "coach_upsell_banner_viewed", // On load of coach upsell banner
  COACH_UPSELL_BANNER_LEARN_MORE: "coach_upsell_banner_learn_more", // User clicks learn more on coach in-app upsell
  COACH_UPSELL_BANNER_DISMISS: "coach_upsell_banner_dismiss", // User clicks dismiss on coach in-app upsell
  COACH_UPSELL_MODAL_VIEWED: "coach_upsell_modal_viewed", // params: { type: "delete" | "reject" }: User views the upsell modal after deleting/rejecting a quest
  COACH_UPSELL_MODAL_LEARN_MORE: "coach_upsell_modal_learn_more", // params: { type: "reject" | "delete" } User clicks learn more on coach in-app upsell
  COACH_UPSELL_MODAL_DISMISS: "coach_upsell_modal_dismiss", // params: { type: "reject" | "delete" } User clicks maybe later on coach in-app upsell

  //// Coaching experiments: every event has the param: {is_tof: boolean} indicating if the user came from TOF or MOF
  MARKETING_TAB_VIEWED: "marketing_tab_viewed", // user views marketing tab
  MARKETING_BUTTON_CLICK: "marketing_button_click", // user clicks "Ready to try it out" or "Learn more" in marketing tab
  MARKETING_COACH_ONBOARDING_START: "marketing_coach_onboarding_start", // landed on coaching/training page, params: { from_popup: boolean }
  MARKETING_CONTINUE_CLICK: "marketing_continue_click", // any time next step is clicked, params: { step: number }
  MARKETING_SKIP_CLICK: "marketing_skip_click", // any time skip to "/me" is clicked, params: { step: number }
  SHOW_RECOMMENDED_COACHES: "show_recommended_coaches", // user is served recommended coach profiles
  MARKETING_INTAKE_FORM: (question: number) =>
    `marketing_intake_form_question_${question}`, // when intake form question is answered, params: { answers: number[] | string }
  MARKETING_INTAKE_FORM_SUBMIT: "marketing_intake_form_submit", // when intake form is submitted
  MARKETING_LEARN_MORE_ABOUT_COACH_CLICK:
    "marketing_learn_more_about_coach_click", // user clicks learn more about coach on coach selection page
  MARKETING_COACH_READ_MORE_CLICK: "marketing_coach_read_more_click", // user clicks read more on coach selection page
  MARKETING_COACH_CARD_CLICK: "marketing_coach_card_click", // user clicks a carousel card on coach selection page for more info/video
  MARKETING_COACH_SELECT: "marketing_coach_select", // when a coach is selected, params: { coach_id: number }
  MARKETING_POST_SELECTION_SUBMIT: "marketing_post_selection_submit", // when post selection questionnaire is submitted
  MARKETING_CHOOSE_ANOTHER_COACH: "marketing_choose_another_coach", // when user clicks "Choose another coach" from plan select page
  MARKETING_COACH_INITIATE_CHECKOUT: "marketing_coach_initiate_checkout", // initiating checkout flow
  MARKETING_COACH_PURCHASE_COMPLETE: "marketing_coach_purchase_complete", // when the purchase is completed for coaching
  MARKETING_SECONDARY_OFFERING_MODAL_OPEN:
    "marketing_secondary_offering_modal_open", // when the secondary offering modal is opened (clicking "No thanks" on plan select page)
  MARKETING_COACH_ONBOARDING_COMPLETE: "marketing_coach_onboarding_complete", // when "Let's Go!" on last step is clicked

  //// Quests
  ADD_QUEST: "add_quest", // User adds new quest
  COMPLETE_QUEST: "qb_complete_quest", // User marks quest as complete
  CREATE_CUSTOM_QUEST: "qb_create_custom_quest", // params: { source: location of the custom quest creation }
  SKIP_QUEST: "qb_skip_quest", // User skips quest
  OPEN_EDIT_QUEST: "qb_open_edit_quest", // User opens quest edit modal
  EDIT_QUEST: "qb_edit_quest", // User saves quest edits
  VIEW_QUEST_DETAILS: "qb_view_quest_details", // User views quest details
  DELETE_QUEST: "qb_delete_quest", // User deletes quest
  CHANGE_QB_DATE: "qb_change_date", // User changes quest board date
  VIEW_ASSIGNED_TAB: "qb_view_assigned_tab", // User views assigned quests tab
  VIEW_VERIFY_TAB: "qb_view_verify_tab", // User views verify quests tab
  VERIFY_QUEST: "verify_quest", // User verifies completed quest
  VERIFY_ALL_QUESTS: "qb_verify_all_quests", // User bulk verifies all quests
  UNDO_QUEST_VERIFY: "qb_undo_quest_verify", // User undoes quest verification
  ADD_QUEST_INTENT: "add_quest_intent", // user opens add quest modal
  TEMPLATE_CATEGORY_CLICK: "qb_template_category_click", // user clicks a template category, { category: string }
  TEMPLATE_CLICK: "qb_template_click", // user clicks a template
  RETRY_QUEST: "retry_quest", // User retries failed/rejected quest
  REJECT_QUEST: "reject_quest", // User rejects completed quest
  COMPLETE_MULTIPLE_QUESTS: "complete_multiple_quests", // User bulk completes quests
  SKIP_MULTIPLE_QUESTS: "skip_multiple_quests", // User bulk skips quests
  REJECT_MULTIPLE_QUESTS: "reject_multiple_quests", // User bulk rejects quests
  REORDER_QUESTS: "reorder_quests", // when user opens up the reorder quest screen
  REORDER_QUESTS_SAVE: "reorder_quests_save", // when user saves the new order of quests

  //// Rewards
  ADD_REWARD: "rewards_add_reward", // user adds a reward
  EDIT_REWARD: "rewards_edit_reward", // user edits a reward
  DELETE_REWARD: "rewards_delete_reward", // user deletes a reward
  SEND_BONUS_REWARD: "send_bonus_reward", // user opens bonus reward modal
  CUSTOM_REWARD_CREATE_CUSTOM: "custom_reward_create_custom", // user opens add reward modal
  BONUS_REWARD_SENT: "rewards_bonus_reward_sent", // user sends bonus reward
  PURCHASE_CUSTOM_REWARD: "purchase_custom_reward", // user purchases a reward
  REDEEM_CUSTOM_REWARD: "redeem_custom_reward", // when redeeming custom reward
  VIEW_CUSTOM_REWARD_DETAILS: "view_custom_reward_details", // when viewing custom reward details
  EMPTY_STATE_CREATE_CUSTOM: "empty_state_create_custom", //  params: { type: string } => "custom_reward", "quest" for quest board. fires when user clicks “Create your first Custom Reward” in custom reward empty state
  EMPTY_STATE_OPEN_TEMPLATES: "empty_state_open_templates", //  params: { type: string } => "custom_reward", "quest" for quest board. fires when user clicks “see what other parents are creating” in custom reward empty state

  //// Family
  SEND_THERAPIST_INVITE: "send_therapist_invite", // User invites therapist
  MANAGE_THERAPISTS_BUTTON_CLICK: "manage_therapists_button_click", // User clicks manage therapists button
  FAMILY_TAB_MANAGE_FAMILY: "family_tab_manage_family", // User views family management tab
  FAMILY_TAB_MANAGE_THERAPISTS: "family_tab_manage_therapists", // User views therapist management tab
  ADD_THERAPIST_PROMPT_INVITE: "add_therapist_prompt_invite", // User prompted to add therapist

  //// Family mailbox
  OPEN_FAMILY_MAILBOX: "open_family_mailbox", // User opens family mailbox
  VIEW_FAMILY_MAIL: "view_family_mail", // User views specific mail message
  COMPOSE_FAMILY_MAIL: "compose_family_mail", // User starts composing new mail
  SEND_FAMILY_MAIL: "send_family_mail", // User sends family mail message

  //// OTHER:
  NAV_ITEM_CLICK: "nav_item_click", // params: page
  CONTACT_US_CLICK: "contact_us_click", // user clicks contact us button
  AUTH_CODE_COPY_CLICK: "auth_code_copy_click", // user clicks copy auth code. params: location, type
  EMAIL_CAMPAIGN_CLICKED: "email_campaign_clicked", // user clicks a link in an email campaign
  SET_PHONE_NUMBER: "set_phone_number", // source param is either: post_therapist_link or settings
  SKIP_SET_PHONE_NUMBER: "skip_set_phone_number", // user skips setting phone number
  OPEN_TEXT_MESSAGE_SETTINGS: "open_text_message_settings", // User opens SMS settings
  SUB_CANCEL_SUBMIT_ANSWER: "sub_cancel_submit_answer", // User submits answer to cancellation survey
  CANCEL_SUB_WONT_CANCEL: "cancel_sub_wont_cancel", // User clicks "View Rewards" on custom reward step
  CANCEL_SUB_WILL_CANCEL: "cancel_sub_will_cancel", // User clicks "Continue cancel" on custom reward step

  //// Browser navigation
  PAGE_REFRESH: "page_refresh", // User refreshes page
  PAGE_CLOSE: "page_close", // User closes page
  PAGE_BACK_FORWARD: "page_back_forward", // User navigates away using back or forward button

  //// Me
  OPEN_QUESTS_REVIEW_CATCHUP: "open_quests_review_catchup", // User opens quest review catchup
  OPEN_QUESTS_COMPLETE_CATCHUP: "open_quests_complete_catchup", // User opens quest completion catchup
  CLICK_TODAYS_PROGRESS: "click_todays_progress", // User views today's progress
  SHOW_ABA_THERAPIST_PROMPT: "show_aba_therapist_prompt", // ABA therapist prompt shown to user
  ABA_THERAPIST_FORCE_CLOSE: "aba_therapist_force_close", // User force closes ABA prompt
  SHOW_CATCHUP_END_PARENT_TASKS_REMINDER:
    "show_catchup_end_parent_tasks_reminder", // Shows end of catchup reminder
  CATCHUP_END_PARENT_TASKS_REMINDER_CLOSE:
    "catchup_end_parent_tasks_reminder_close", // User closes catchup reminder
  INVITE_THERAPIST_EMAIL: "invite_therapist_email", // User invites therapist via email

  //// Parent tasks
  OPEN_PARENT_TASK_RESOURCE: "open_parent_task_resource", // User opens task resource
  PARENT_TASKS_INITIATE_COMPLETE: "parent_tasks_initiate_complete", // User starts task completion
  PARENT_TASKS_FINISH_COMPLETE: "parent_tasks_finish_complete", // User finishes task
  OPEN_PARENT_TASKS_SHEET: "open_parent_tasks_sheet", // User opens tasks sheet

  //// Data tasks
  OPEN_DATA_COLLECTION_TASKS: "open_data_collection_tasks", // User opens data collection tasks
  INITIATE_DATA_COLLECTION_TASK: "initiate_data_collection_task", // User starts data collection task
  SUBMIT_DATA_COLLECTION_TASK: "submit_data_collection_task", // User submits collected data

  //// Incidents
  INITIATE_LOG_INCIDENT: "initiate_log_incident", // User starts incident logging
  ENTER_LOG_INCIDENT_CHILD_SELECTION: "enter_log_incident_child_selection", // User selects child for incident
  ENTER_LOG_INCIDENT_BEHAVIOR_DESCRIPTION:
    "enter_log_incident_behavior_description", // User describes incident behavior
  ENTER_LOG_INCIDENT_STRATEGY_DESCRIPTION:
    "enter_log_incident_strategy_description", // User describes incident strategy
  SUBMIT_INCIDENT_LOG: "submit_incident_log", // User submits incident log
  LOG_ANOTHER_INCIDENT: "log_another_incident", // User starts another incident log

  //// Parent Training
  OPEN_PARENT_TRAINING_DETAILS: "open_parent_training_details", // User views training details
  START_CONTINUE_LEARNING_MODULES: "start_continue_learning_modules", // User starts/continues modules
  OPEN_LEARNING_MODULE: "open_learning_module", // User opens specific module
  COMPLETE_BASIC_LEARNING_ASSIGNMENT: "complete_basic_learning_assignment", // when the user completes PDF/readingtext/video
  COMPLETE_SHORT_ANSWER_LEARNING_ASSIGNMENT:
    "complete_short_answer_learning_assignment", // when the user submits ALL short answer questions
  COMPLETE_MULTIPLE_CHOICE_LEARNING_ASSIGNMENT:
    "complete_multiple_choice_learning_assignment", // when the user submits ALL multiple choice questions
  FINISH_CURRENT_LEARNING_MODULES: "finish_current_learning_modules", // when the user gets to the end of the learning modules and we're dismissing the screen

  VIEW_COACHING_SESSIONS: "view_coaching_sessions", // user views coaching sessions
  VIEW_COACHING_MESSAGES: "view_coaching_messages", // user views coaching messages
  JOIN_COACHING_SESSION: "join_coaching_session", // user joins a coaching session
  SCHEDULE_COACHING_SESSION: "schedule_coaching_session", // user schedules a coaching session
  RESCHEDULE_COACHING_SESSION: "reschedule_coaching_session", // user reschedules a coaching session
  CANCEL_COACHING_SESSION: "cancel_coaching_session", // user cancels a coaching session
}

export enum FacebookPixelEvent {
  CONTACT = "Contact",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  START_TRIAL = "StartTrial",
  PURCHASE_SUBSCRIPTION = "Purchase",
}

export enum TiktokPixelEvent {
  CONTACT = "Contact",
  COMPLETE_REGISTRATION = "CompleteRegistration",
  START_TRIAL = "PlaceAnOrder",
  PURCHASE_SUBSCRIPTION = "CompletePayment",
}

export enum PinterestPixelEvent {
  CONTACT = "Lead",
  COMPLETE_REGISTRATION = "SignUp",
  START_TRIAL = "Checkout",
}

const isProduction = import.meta.env.MODE === "production"
// Initialize amplitude
isProduction &&
  init(import.meta.env.VITE_AMPLITUDE_API_KEY as string, undefined, {
    minIdLength: 1,
  })
// Initialize hotjar
// isProduction && Hotjar.init(3623886, 6)

export function analyticsUserDidLogin(
  parentId: number,
  familyId: number,
  email: string
) {
  // User id (as string)
  setUserId(`${parentId}`)

  // User properties
  const event = new Identify()
  event.set("family_id", `${familyId}`)
  event.set("email", email)
  localStorage.setItem(localStorageItems.analyticsFamilyId, `${familyId}`)
  localStorage.setItem(localStorageItems.analyticsUserId, `${parentId}`)
  identify(event)

  // Set family group
  setGroup("family", `${familyId}`)
}

export function analyticsUserDidLogout() {
  reset()
  localStorage.removeItem(localStorageItems.analyticsFamilyId)
  localStorage.removeItem(localStorageItems.analyticsUserId)
}

export function trackAnalyticEvent(
  eventName: string,
  eventParams: { [key: string]: any } = {}
) {
  if (!isProduction) return
  eventParams.platform = "web"
  eventParams.family_id = localStorage.getItem(
    localStorageItems.analyticsFamilyId
  )
  eventParams.user_id = localStorage.getItem(localStorageItems.analyticsUserId)

  track(eventName, eventParams)
}

export enum GAEvent {
  START_QUIZ = "AW-343454768/kDV8CILi5dQDELDo4qMB",
  CREATE_ACCOUNT = "AW-343454768/QAqSCPrN5dQDELDo4qMB",
  START_TRIAL = "AW-343454768/-_y7CLiBtqYYELDo4qMB",
}
export function trackGAEvent(gaEvent: string, data?: any) {
  if (import.meta.env.DEV) return
  gtag("event", "conversion", { send_to: gaEvent, ...data })
}
