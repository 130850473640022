import {
  CustomReward,
  CustomRewardBalance,
  CustomRewardLog,
  CustomRewardLogStats,
  CustomRewardLogType,
  CustomRewardParams,
} from "@joonapp/web-shared"

import { createJoonAPIClient } from "../../util/joon-api"

export const getCustomRewards = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: CustomReward[] }>("api/custom-rewards")
}

export const createCustomReward = async (rewardInfo: CustomRewardParams) => {
  const API = createJoonAPIClient()
  return API.post("api/custom-rewards/", rewardInfo)
}

export const editCustomReward = async ({
  rewardInfo,
  rewardId,
}: {
  rewardInfo: CustomRewardParams
  rewardId: number
}) => {
  const API = createJoonAPIClient()
  return API.patch(`api/custom-rewards/${rewardId}/`, rewardInfo)
}

export const getRewardTemplates = async () => {
  const API = createJoonAPIClient()
  return API.get<{ results: CustomReward[] }>("api/custom-rewards/templates/")
}

export const purchaseCustomReward = async ({
  rewardId,
  childId,
  amount = 1,
}: {
  rewardId: number
  childId: number
  amount?: number
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/custom-rewards/${rewardId}/purchase/`, {
    user_id: childId,
    amount,
  })
}

export const redeemCustomReward = async ({
  rewardId,
  amount,
  userId,
}: {
  rewardId: number
  amount: number
  userId: number
}) => {
  const API = createJoonAPIClient()
  return API.post(`api/custom-rewards/${rewardId}/redeem/`, {
    amount,
    user_id: userId,
  })
}

export const deleteCustomReward = async (rewardId: number) => {
  const API = createJoonAPIClient()
  return API.delete(`api/custom-rewards/${rewardId}/`)
}

export const getCustomRewardLogs = async ({
  userId,
  rewardId,
  minDate,
  maxDate,
  type,
}: {
  userId?: number
  rewardId?: number
  minDate?: string
  maxDate?: string
  type?: CustomRewardLogType
}) => {
  const API = createJoonAPIClient()
  return API.get<{ results: CustomRewardLog[] }>("api/custom-reward-logs", {
    params: {
      user_id: userId,
      reward_id: rewardId,
      min_date: minDate,
      max_date: maxDate,
      type,
    },
  }).then((response) => response.data.results)
}

export const undoCustomRewardLog = async (logId: number) => {
  const API = createJoonAPIClient()
  return API.post(`api/custom-reward-logs/${logId}/undo/`)
}

export const getCustomRewardLogsStats = async ({
  rewardId,
}: {
  rewardId?: number
}) => {
  const API = createJoonAPIClient()
  return API.get<CustomRewardLogStats>("api/custom-reward-logs/stats/", {
    params: { reward_id: rewardId },
  }).then((response) => response.data)
}

export const getCustomRewardBalances = async (params?: {
  rewardId?: number
  userId?: number
}) => {
  const { rewardId, userId } = params || {}
  const API = createJoonAPIClient()
  return API.get<{ balances: CustomRewardBalance[] }>(
    "api/custom-rewards/balances/",
    { params: { reward_id: rewardId, user_id: userId } }
  ).then((response) => response.data.balances)
}
