import {
  CalendarCheckIcon,
  capitalizeFirstLetter,
  CommentDots,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useCoachParentTasks } from "./tasks/useCoachParentTasks"
import NotificationDot from "../../components/notificationDot/NotificationDot"
import useMobile from "../../hooks/useMobile"
import { useRouter } from "../../util/router"

export enum CoachTab {
  MESSAGES = "messages",
  TASKS = "tasks",
  SESSIONS = "sessions",
}

const CoachTabNav = () => {
  const isMobile = useMobile()
  const router = useRouter()
  const tab = router.query.coachTab as CoachTab | undefined
  const careTeamUserId = router.query.careTeamUserId as string | undefined
  const { hasUnreadCoachParentTasks, hasCoachParentTasks } =
    useCoachParentTasks()

  const setTab = (tab: CoachTab) => {
    router.push(`/coach/${careTeamUserId}/${tab.toLowerCase()}`)
  }

  return (
    <FlexBox
      direction="row"
      align="center"
      justify={isMobile ? "space-around" : "flex-start"}
      gap={isMobile ? SPACING.space0 : SPACING.space4}
      wrap={false}
      style={{
        width: `min(100%, calc(600px - ${SPACING.space8}))`,
        margin: "0 auto",
      }}
    >
      <TabItem
        label={CoachTab.MESSAGES}
        onClick={() => setTab(CoachTab.MESSAGES)}
        icon={
          tab === CoachTab.MESSAGES ? (
            <CommentDots color={JoonUIColor.text.primary} size={16} />
          ) : (
            <CommentDots color={JoonUIColor.text.secondary} size={16} />
          )
        }
      />
      {hasCoachParentTasks && (
        <TabItem
          label={CoachTab.TASKS}
          onClick={() => setTab(CoachTab.TASKS)}
          icon={
            tab === CoachTab.TASKS ? (
              <CalendarCheckIcon color={JoonUIColor.text.primary} size={16} />
            ) : (
              <CalendarCheckIcon color={JoonUIColor.text.secondary} size={16} />
            )
          }
          showNewDataTaskNotification={hasUnreadCoachParentTasks}
        />
      )}
      <TabItem
        label={CoachTab.SESSIONS}
        onClick={() => setTab(CoachTab.SESSIONS)}
        icon={
          tab === CoachTab.SESSIONS ? (
            <CalendarCheckIcon color={JoonUIColor.text.primary} size={16} />
          ) : (
            <CalendarCheckIcon color={JoonUIColor.text.secondary} size={16} />
          )
        }
      />
    </FlexBox>
  )
}

export default CoachTabNav

const TabItem = ({
  label,
  onClick,
  icon,
  showNewDataTaskNotification,
}: {
  label: string
  onClick: () => void
  icon: any
  showNewDataTaskNotification?: boolean
}) => {
  const router = useRouter()
  const tab = router.query.coachTab as CoachTab | undefined
  const isSelected = tab === label

  return (
    <button
      onClick={onClick}
      style={{
        borderBottom: isSelected
          ? `4px solid ${JoonUIColor.semantic.primary}`
          : "4px solid transparent",
        padding: `${SPACING.space2} ${SPACING.space4}`,
        display: "flex",
        alignItems: "center",
        gap: SPACING.space2,
      }}
    >
      {icon}
      <Typography
        variant="bodySmall"
        style={{ fontWeight: "600" }}
        color={
          isSelected ? JoonUIColor.text.primary : JoonUIColor.text.secondary
        }
      >
        {capitalizeFirstLetter(label)}
      </Typography>
      {showNewDataTaskNotification && <NotificationDot />}
    </button>
  )
}
