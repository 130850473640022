import {
  Button,
  CheckIcon,
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { useEffect, useState } from "react"

import { useCheckoutRedirect } from "./useCheckoutRedirect"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import useMediaQuery from "../../hooks/useMediaQuery"
import { useUserQuery } from "../../networking/queries"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useHistory, useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"

const prices = {
  DiscountAnnual: {
    old: "$89.99",
    new: "$69.99",
  },
  SpecialDiscountAnnual: {
    old: "$89.99",
    new: "$49.99",
  },
  default: {
    old: "$129.99",
    new: "$89.99",
  },
}
const getPaywallPrices = (campaign: string) => {
  switch (campaign) {
    case "DiscountAnnual":
      return prices.DiscountAnnual
    case "SpecialDiscountAnnual":
      return prices.SpecialDiscountAnnual
    default:
      return prices.default
  }
}

const Paywall = () => {
  useDocumentTitle("Joon")
  const [isAtTop, setIsAtTop] = useState(true)
  const isSmallScreen = useMediaQuery("(max-height:750px)")
  const router = useRouter()
  const { user } = useUserQuery()
  const history = useHistory()

  const { isLoadingCheckoutFn, redirectToFamilyCheckout } =
    useCheckoutRedirect()

  const campaign = router.query.campaign as string
  const paywallPrices = getPaywallPrices(campaign)

  useEffect(() => {
    trackAnalyticEvent(ANALYTIC_EVENTS.SHOW_PAYWALL, {
      type: "daily",
    })
    localStorage.setItem(
      localStorageItems.lastSeenPaywall,
      dayjs().format("YYYY-MM-DD")
    )
    const handleScroll = () => {
      if (window.scrollY === 0) setIsAtTop(true)
      else setIsAtTop(false)
    }
    window.addEventListener("scroll", handleScroll)

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const scrollToBottom = () =>
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    })

  const onClickSkip = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_SKIP, {
      type: "daily",
    })
    if (history.length > 1) history.goBack()
    else router.push("/coaching")
  }

  const howWeWillGetYouThere = [
    "Science-based gamification behavior improvement program",
    "Tailored in-app parent guide and resources",
    "100s of personalized routine and task recommendations",
    "Same day customer service and support",
  ]

  const messages = [
    "It's only been a few weeks and Joon has already been so helpful and he's been so focused. It 100% works, In fact, it only gets better every day. The only things I need to remind him to do are things I haven't added to Joon yet.",
    "Joon is good for any child regardless of the developmental state of their mind. It helps my kids manage their own lives.",
    "This app is AWESOME! We have 6 kids in our house, 3 have ADHD and 3 don't. 4 of our kids are currently using it and this was our first week - so many tasks done and so much excitement! Even the preschooler wants in on it!",
  ]

  if (!user) return <></>

  return (
    <div className="paywall-page" style={{ gap: SPACING.space4 }}>
      <img
        src={"/images/logos/logo_white.svg"}
        alt="Joon"
        className="joon-logo"
      />
      <button onClick={onClickSkip} className="close-button">
        <CloseIcon color={JoonUIColor.text.disabled} size={24} />
      </button>
      <div className="paywall-ft-title">How we'll get you there</div>
      <FlexBox direction="column" gap={SPACING.space6}>
        {howWeWillGetYouThere.map((reason, index) => (
          <FlexBox key={index} align="center" gap={SPACING.space2} wrap={false}>
            <FlexBox
              align="center"
              gap={SPACING.space2}
              style={{
                backgroundColor: JoonUIColor.background.accent,
                borderRadius: SPACING.space2,
                padding: SPACING.space1,
                width: SPACING.space6,
                height: SPACING.space6,
              }}
            >
              <CheckIcon color={JoonUIColor.icon.inverted} size={16} />
            </FlexBox>
            <Typography variant="body" style={{ lineHeight: "20px" }}>
              {reason}
            </Typography>
          </FlexBox>
        ))}
      </FlexBox>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ marginTop: SPACING.space4 }}
      >
        <Typography
          variant="h3"
          color={JoonUIColor.text.primaryAccent}
          textAlign="center"
        >
          What other parents are saying
        </Typography>
        {messages.map((message, index) => (
          <ChatBubble
            key={index}
            message={message}
            side={index % 2 === 0 ? "left" : "right"}
          />
        ))}
      </FlexBox>

      <div className="paywall-action-box">
        {isAtTop && isSmallScreen && (
          <button onClick={scrollToBottom} className="scroll-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="12"
              viewBox="0 0 40 14"
            >
              <path
                d="M0 0 L20 12 L40 0"
                fill="none"
                stroke="white"
                stroke-width="4"
              />
            </svg>
          </button>
        )}
        <div className="paywall-action-box__text">
          You have access to the discounted price!
        </div>
        <div className="paywall-price">
          <span className="paywall-price__old">{paywallPrices.old}</span>
          <Typography variant="body">
            {paywallPrices.new}/year. Cancel any time.
          </Typography>
        </div>
        <Button
          isLoading={isLoadingCheckoutFn}
          onClick={() => {
            redirectToFamilyCheckout()
            localStorage.removeItem(localStorageItems.therapistCode)
            localStorage.setItem(localStorageItems.stripeFromPaywall, "true")
            trackAnalyticEvent(ANALYTIC_EVENTS.PAYWALL_START_TRIAL)
          }}
          style={{
            fontSize: "24px",
            background: "#f39f3d",
            borderColor: "#f39f3d",
            width: "100%",
            marginTop: SPACING.space2,
            marginBottom: SPACING.space6,
          }}
          text="Unlock Joon"
        >
          Unlock Joon
        </Button>
      </div>
    </div>
  )
}

export default Paywall

const ChatBubble = ({
  message,
  side,
}: {
  message: string
  side: "left" | "right"
}) => {
  const isLeft = side === "left"
  return (
    <div
      style={{
        backgroundColor: JoonUIColor.background.accent,
        borderRadius: SPACING.space4,
        padding: SPACING.space3,
        position: "relative",
        width: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          bottom: "-10px",
          left: isLeft ? "90%" : "10%",
          borderWidth: "10px",
          borderStyle: "solid",
          borderColor: isLeft
            ? `transparent ${JoonUIColor.background.accent} transparent transparent`
            : `transparent transparent transparent ${JoonUIColor.background.accent}`,
        }}
      />
      <Typography variant="body" color={JoonUIColor.text.inverted}>
        {message}
      </Typography>
    </div>
  )
}
