import { CustomRewardLogType } from "@joonapp/web-shared"
import { useQuery } from "@tanstack/react-query"
import dayjs from "dayjs"

import {
  getCustomRewardBalances,
  getCustomRewardLogsStats,
  getCustomRewards,
  getRewardTemplates,
} from "./api"
import { getCustomRewardLogs } from "./api"
import { QUERY_KEYS } from "../../constants"
import { useFilterRewardLogsModalStore } from "../../pages/rewards/redeem/FilterRewardLogsModal"
import { sessionManager } from "../../util/storage"

export const useCustomRewardLogsQuery = (params?: {
  rewardId?: number
  childId?: number
  type?: CustomRewardLogType
  enabled?: boolean
}) => {
  const { rewardId, childId, type, enabled = true } = params || {}
  const { selectedUser, startDate, endDate } = useFilterRewardLogsModalStore()

  const userId = childId || Number(selectedUser?.value) || undefined
  const minDate = startDate ? dayjs(startDate).format("YYYY-MM-DD") : undefined
  const maxDate = endDate ? dayjs(endDate).format("YYYY-MM-DD") : undefined

  return useQuery({
    queryKey: [
      QUERY_KEYS.CUSTOM_REWARD_LOGS,
      rewardId,
      userId,
      minDate,
      maxDate,
      type,
    ],
    queryFn: () =>
      getCustomRewardLogs({ rewardId, userId, minDate, maxDate, type }),
    enabled: sessionManager.hasRefreshToken() && enabled,
  })
}

export const useCustomRewardLogsStatsQuery = (params?: {
  rewardId?: number
}) => {
  const { rewardId } = params || {}
  return useQuery({
    queryKey: [QUERY_KEYS.CUSTOM_REWARD_LOGS_STATS, String(rewardId)],
    queryFn: () => getCustomRewardLogsStats({ rewardId: rewardId }),
    enabled: sessionManager.hasRefreshToken(),
  })
}

export const useCustomRewardBalancesQuery = (params?: {
  rewardId?: number
}) => {
  const { rewardId } = params || {}
  return useQuery({
    queryKey: [QUERY_KEYS.CUSTOM_REWARD_BALANCES, rewardId],
    queryFn: () => getCustomRewardBalances({ rewardId }),
    enabled: sessionManager.hasRefreshToken(),
  })
}

export const useCustomRewardsQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.CUSTOM_REWARDS],
    queryFn: getCustomRewards,
    select: (response) => response.data.results,
  })
}

export const useRewardTemplatesQuery = () => {
  return useQuery({
    queryKey: [QUERY_KEYS.REWARD_TEMPLATES],
    queryFn: getRewardTemplates,
    select: (response) => response.data.results,
  })
}
