import {
  CustomRewardLog,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"

import { useUserQuery } from "../../../networking/queries"

const columnSpacing = {
  date: "18%",
  user: "28%",
  activity: "54%",
  count: "10%",
}

export const RewardLogRow = ({ log }: { log: CustomRewardLog }) => {
  const { user } = useUserQuery()

  const formatDate = (date: string) => dayjs(date).format("MMM D")

  const formatCount = (count: number) => {
    return count === 0 ? 0 : count > 0 ? `+ ${count}` : `- ${Math.abs(count)}`
  }

  return (
    <FlexBox direction="row" justify="space-between" wrap={false}>
      <Typography
        variant="bodySmall"
        color={JoonUIColor.text.primary}
        style={{ width: columnSpacing.date }}
      >
        {formatDate(log.date_created)}
      </Typography>
      <Typography
        variant="bodySmall"
        color={JoonUIColor.text.primary}
        style={{
          width: columnSpacing.user,
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {log.action_user.id === user?.id
          ? `${user?.name} (You)`
          : log.action_user.name}
      </Typography>
      <Typography
        variant="bodySmall"
        color={JoonUIColor.text.primary}
        style={{
          width: columnSpacing.activity,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {log.display_string}
      </Typography>
      <Typography
        variant="bodySmall"
        color={
          log.amount_change === 0
            ? JoonUIColor.text.primary
            : log.amount_change > 0
              ? JoonColorExpanded.viridian400
              : JoonColorExpanded.red400
        }
        style={{
          width: columnSpacing.count,
          textAlign: "left",
          whiteSpace: "nowrap",
        }}
      >
        {formatCount(log.amount_change)}
      </Typography>
    </FlexBox>
  )
}

export const RewardLogTableLabel = () => {
  return (
    <FlexBox
      direction="row"
      justify="space-between"
      align="center"
      wrap={false}
      fullWidth
    >
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.secondary}
        style={{ width: columnSpacing.date, fontWeight: 700 }}
      >
        Date
      </Typography>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.secondary}
        style={{ width: columnSpacing.user, fontWeight: 700 }}
      >
        User
      </Typography>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.secondary}
        style={{ width: columnSpacing.activity, fontWeight: 700 }}
      >
        Activity
      </Typography>
      <Typography
        variant="bodyXSmall"
        color={JoonUIColor.text.secondary}
        style={{
          width: columnSpacing.count,
          textAlign: "left",
          fontWeight: 700,
        }}
      >
        Count
      </Typography>
    </FlexBox>
  )
}
