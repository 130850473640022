import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared/"

import useMobile from "../../../../hooks/useMobile"

interface ChatBubbleDownRightProps {
  text: string
  boldText: string
  imageUrl: string
}

const ChatBubbleDownRight = ({
  text,
  boldText,
  imageUrl,
}: ChatBubbleDownRightProps) => {
  const isMobile = useMobile()

  return (
    <FlexBox
      direction="column"
      align="flex-start"
      wrap={false}
      style={{
        position: "absolute",
        width: "90%",
        padding: SPACING.space2,
        background: JoonColorExpanded.purple600,
        borderRadius: SPACING.space2,
        top: isMobile ? "60px" : "72px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: isMobile ? "-40px" : "-52px",
          left: "8px",
          minWidth: isMobile ? "36px" : "48px",
          maxWidth: isMobile ? "36px" : "48px",
          minHeight: isMobile ? "36px" : "48px",
          maxHeight: isMobile ? "36px" : "48px",
          borderRadius: "50%",
          background: JoonColorExpanded.yellow400,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={imageUrl}
          style={{
            width: isMobile ? "36px" : "48px",
            height: isMobile ? "36px" : "48px",
            borderRadius: "50%",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt="Coach Profile"
        />
      </div>
      <Typography
        variant={isMobile ? "bodyXSmall" : "bodySmall"}
        color={JoonUIColor.text.inverted}
        style={{ textAlign: "left" }}
      >
        {text}
      </Typography>
      <Typography
        variant={isMobile ? "bodyXSmall" : "bodySmall"}
        color={JoonUIColor.text.inverted}
        style={{ textAlign: "left", fontWeight: 700 }}
      >
        {boldText}
      </Typography>
      <div
        style={{
          position: "absolute",
          top: "-12px",
          left: isMobile ? "48px" : "64px",
        }}
      >
        <img
          src="/images/guidePhotos/chat_bubble_tail_dark.svg"
          alt="chat bubble tail dark"
        />
      </div>
    </FlexBox>
  )
}

export default ChatBubbleDownRight
