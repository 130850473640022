import {
  ChevronRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import dayjs from "dayjs"
import { create } from "zustand"

import Shine from "../../components/coaching/Shine"
import useMobile from "../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import { useAddQuestTemplatesModalStore } from "../questBoard/addQuestTemplates/useAddQuestTemplatesModalStore"
import { useRejectQuestModal } from "../questBoard/RejectQuestModal"
import { useReviewQuestModal } from "../questBoard/ReviewQuestModal"
import { useViewQuestModalStore } from "../questBoard/useViewQuestModalStore"

export enum CoachUpsellModalType {
  reject = "reject",
  delete = "delete",
}

interface CoachUpsellModalStore {
  isOpen: boolean
  onOpen: (type: CoachUpsellModalType) => void
  onClose: () => void

  type: CoachUpsellModalType | undefined
}

export const useCoachUpsellModalStore = create<CoachUpsellModalStore>(
  (set) => ({
    isOpen: false,
    onOpen: (type: CoachUpsellModalType) => {
      trackAnalyticEvent(ANALYTIC_EVENTS.COACH_UPSELL_MODAL_VIEWED, {
        type,
      })
      localStorage.setItem(
        type === CoachUpsellModalType.reject
          ? localStorageItems.rejectCoachUpsellModalSeenDate
          : localStorageItems.deleteCoachUpsellModalSeenDate,
        dayjs().format("YYYY-MM-DD")
      )
      set({ isOpen: true, type })
    },
    onClose: () => {
      set({ isOpen: false })
    },

    type: undefined,
  })
)

const CoachUpsellModal = () => {
  const { isOpen, onClose, type } = useCoachUpsellModalStore()
  const { onClose: onCloseAddTemplateModal } = useAddQuestTemplatesModalStore()
  const { onClose: onCloseViewQuestModal } = useViewQuestModalStore()
  const { onClose: onCloseRejectQuestModal } = useRejectQuestModal()
  const { onClose: onCloseReviewQuestModal } = useReviewQuestModal()

  const isMobile = useMobile()
  const router = useRouter()

  const onLearnMoreClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.COACH_UPSELL_MODAL_LEARN_MORE, {
      type,
    })
    router.push("/coaching?from=preview&popup=true")
    onClose()
    onCloseAddTemplateModal()
    onCloseViewQuestModal()
    onCloseRejectQuestModal()
    onCloseReviewQuestModal()
  }

  const onMaybeLaterClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.COACH_UPSELL_MODAL_DISMISS, {
      type,
    })
    onClose()
    onCloseAddTemplateModal()
    onCloseViewQuestModal()
    onCloseRejectQuestModal()
    onCloseReviewQuestModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      style={{ width: "min(500px, 100%)" }}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        gap={SPACING.space6}
        style={{
          borderRadius: SPACING.space2,
          boxShadow: "4px 6px 6px 0px rgba(0, 0, 0, 0.08)",
          background: JoonUIColor.background.primaryNeutral,
          position: "relative",
          padding: SPACING.space4,
          paddingTop: SPACING.space9,
          paddingBottom: SPACING.space9,
        }}
        fullWidth
      >
        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space4}
          wrap={false}
          style={{
            width: "fit-content",
          }}
        >
          <Typography
            variant="h3"
            textAlign="center"
            style={{ width: "min(300px, 90%)" }}
          >
            {type === CoachUpsellModalType.reject
              ? "Having trouble getting your kids to do their quests properly?"
              : "Need help setting up the best routines for your child?"}
          </Typography>
          <Typography
            variant="bodySmall"
            textAlign="center"
            style={{ width: "min(380px, 90%)" }}
          >
            Connect 1:1 with a Joon coach and{" "}
            <Typography variant="caption">
              get actionable personalized strategies to improve your child's
              routines
            </Typography>
          </Typography>
        </FlexBox>

        <img
          src={`/images/guidePhotos/care_breakdown_strategy.svg`}
          style={{ width: "min(350px, 90%)" }}
          alt="<3"
        />

        <button
          style={{
            position: "relative",
            width: "min(380px, 90%)",
            padding: 0,
          }}
          onClick={onLearnMoreClick}
        >
          <FlexBox
            direction="row"
            align="center"
            justify="space-between"
            wrap={false}
            style={{
              background: JoonColorExpanded.indigo400,
              borderRadius: SPACING.space2,
              padding: `${SPACING.space2} ${SPACING.space1} ${SPACING.space2} ${SPACING.space3}`,
              marginTop: SPACING.space4,
              boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
              overflow: "hidden",
            }}
          >
            <Typography
              variant={isMobile ? "caption" : "bodyBold"}
              color={JoonUIColor.text.inverted}
              style={{ lineHeight: "1.4" }}
            >
              Learn more about Joon Coaching
            </Typography>

            <div style={{ minWidth: "30px" }}>
              <ChevronRightIcon color={JoonUIColor.icon.inverted} size={24} />
            </div>
          </FlexBox>
          <Shine />
        </button>

        <button onClick={onMaybeLaterClick}>
          <Typography
            variant={isMobile ? "bodySmall" : "body"}
            color={JoonUIColor.text.primaryAccent}
          >
            Maybe later
          </Typography>
        </button>
      </FlexBox>
    </Modal>
  )
}

export default CoachUpsellModal
