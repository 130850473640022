import {
  Button,
  CustomRewardBalance,
  CustomRewardCurrency,
  FlexBox,
  Modal,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { toast } from "react-hot-toast"
import { create } from "zustand"

import RewardsCounter from "../../../components/counter/RewardsCounter"
import { useFamilyQuery } from "../../../networking/queries"
import { useRedeemRewardMutation } from "../../../networking/rewards/mutations"
import { FamilyQuerySelect } from "../../../types"
import { trackAnalyticEvent } from "../../../util/analytics"
import { ANALYTIC_EVENTS } from "../../../util/analytics"

interface RewardQuantityModalStore {
  redeemChildId: number | null
  setRedeemChildId: (redeemChildId: number | null) => void
  isOpen: boolean
  onOpen: (rewardBalance: CustomRewardBalance) => void
  onClose: () => void
  rewardBalance: CustomRewardBalance | null
  quantity: number
  setQuantity: (quantity: number) => void
}

const initialState = {
  redeemChildId: null,
  isOpen: false,
  rewardBalance: null,
  quantity: 1,
}

export const useRewardQuantityModal = create<RewardQuantityModalStore>(
  (set) => ({
    ...initialState,
    redeemChildId: null,
    setRedeemChildId: (redeemChildId: number | null) => set({ redeemChildId }),
    onOpen: (rewardBalance: CustomRewardBalance) =>
      set({ isOpen: true, rewardBalance, quantity: 1 }),
    onClose: () => set(initialState),
    setQuantity: (quantity: number) => set({ quantity }),
  })
)

export default function RewardQuantityModal() {
  const {
    isOpen,
    onClose,
    rewardBalance,
    quantity,
    setQuantity,
    redeemChildId,
  } = useRewardQuantityModal()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const selectedChild = children?.find(
    (child) => child.user.id === redeemChildId
  )

  const redeemRewardMutation = useRedeemRewardMutation()

  const onRedeemClick = async () => {
    if (!rewardBalance || !redeemChildId) return
    trackAnalyticEvent(ANALYTIC_EVENTS.REDEEM_CUSTOM_REWARD)
    await redeemRewardMutation.mutateAsync({
      rewardId: rewardBalance?.reward.id,
      amount: quantity,
      userId: redeemChildId,
    })
    const plural = quantity === 1 ? "" : "s"
    toast.success(
      `${quantity} ${
        rewardBalance.reward.currency === CustomRewardCurrency.DOLLAR
          ? "dollar"
          : rewardBalance.reward.currency === CustomRewardCurrency.MINUTE
            ? "minute"
            : "reward"
      }${plural} redeemed successfully`
    )
    onClose()
  }

  if (!rewardBalance) return null

  const displayName = selectedChild?.nickname || selectedChild?.user.name

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      displayCloseIcon
      animate
      style={{
        width: "min(100%, 450px)",
        height: "350px",
        padding: `${SPACING.space4} ${SPACING.space6}`,
      }}
    >
      <FlexBox
        direction="column"
        align="flex-start"
        justify="space-between"
        style={{ height: "100%" }}
      >
        <FlexBox
          direction="column"
          align="flex-start"
          justify="flex-start"
          gap={SPACING.space4}
          wrap={false}
        >
          <Typography variant="bodyBold">
            {`Redeeming: ${rewardBalance.reward.emoji} ${rewardBalance.reward.title}`}
          </Typography>
          <Typography variant="bodySmall">
            Only redeem rewards when you're ready to reward your kids with this
            item in real life!
          </Typography>
          <Typography variant="bodyBold">
            {`How many ${
              rewardBalance.reward.currency === CustomRewardCurrency.DOLLAR
                ? "dollars"
                : rewardBalance.reward.currency === CustomRewardCurrency.MINUTE
                  ? "minutes"
                  : ""
            } would you like to redeem for ${displayName}?`}
          </Typography>
          <RewardsCounter
            maxQuantity={rewardBalance.balance}
            quantity={quantity}
            setQuantity={setQuantity}
          />
        </FlexBox>
        <Button
          buttonType="primary"
          onClick={onRedeemClick}
          text="Redeem"
          fullWidth
          disabled={quantity < 1 || quantity > rewardBalance.balance}
          isLoading={redeemRewardMutation.isLoading}
          style={{ userSelect: "none" }}
        />
      </FlexBox>
    </Modal>
  )
}
