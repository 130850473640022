import {
  ChevronRightIcon,
  CustomRewardBalance,
  CustomRewardCurrency,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { useState } from "react"

import RewardRedeemButton from "./RewardRedeemButton"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import { useRouter } from "../../../util/router"

type Props = {
  rewardBalance: CustomRewardBalance
}

const RewardRedeemCard = ({ rewardBalance }: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  const router = useRouter()

  const currencyText = `${
    rewardBalance.reward.currency &&
    rewardBalance.reward.currency !== CustomRewardCurrency.ITEM
      ? ` ${rewardBalance.reward.currency}${
          rewardBalance.balance > 1 ? "s" : ""
        }`
      : ""
  }`

  return (
    <FlexBox
      justify="space-between"
      direction="row"
      align="center"
      wrap={false}
      gap={SPACING.space2}
      onClick={() => {
        trackAnalyticEvent(ANALYTIC_EVENTS.VIEW_CUSTOM_REWARD_DETAILS)
        router.push(`/rewards/details/${rewardBalance.reward.id}`)
      }}
      style={{
        width: "100%",
        padding: `${SPACING.space3} ${SPACING.space4}`,
        border: "1px solid JoonUIColor.border.default",
        borderRadius: SPACING.space2,
        background: isHovered
          ? JoonUIColor.background.xlightGray
          : JoonUIColor.background.primaryNeutral,
        cursor: isHovered ? "pointer" : "default",
        transition: "background 0.1s ease-in-out",
        transform: isPressed ? "translateY(1px)" : "translateY(0px)",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false)
        setIsPressed(false)
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
    >
      <FlexBox
        direction="row"
        align="center"
        justify="flex-start"
        wrap={false}
        gap={SPACING.space1}
        style={{ width: "60%" }}
      >
        <Typography
          variant="caption"
          style={{
            backgroundColor: rewardBalance?.balance
              ? JoonColorExpanded.green200
              : JoonUIColor.icon.light,
            padding: `${SPACING.space05} ${SPACING.space1}`,
            borderRadius: SPACING.space1,
            whiteSpace: "nowrap",
          }}
        >
          {rewardBalance.balance}
          {currencyText}
        </Typography>
        <Typography
          variant="caption"
          textAlign="left"
          style={{
            textOverflow: "ellipsis",
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          {`${rewardBalance.reward.emoji} ${rewardBalance.reward.title}`}
        </Typography>
      </FlexBox>
      <FlexBox
        direction="row"
        align="center"
        justify="flex-end"
        gap={SPACING.space2}
        wrap={false}
        style={{ width: "40%" }}
      >
        <RewardRedeemButton
          rewardBalance={rewardBalance}
          childId={rewardBalance.user_id}
        />
        <ChevronRightIcon size={24} color={JoonUIColor.icon.neutral} />
      </FlexBox>
    </FlexBox>
  )
}

export default RewardRedeemCard
