import { Button, FlexBox, Modal, SPACING } from "@joonapp/web-shared"
import { useState } from "react"

import { useAddEditRewardModal } from "./AddEditRewardModal"
import { useBonusRewardModalStore } from "./BonusRewardModal"
import RewardTemplates, { useRewardTemplatesModal } from "./RewardTemplates"
import useMobile from "../../hooks/useMobile"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { QuickActionMobileButton } from "../me/QuickActionsSection"

export const AddRewardButton = () => {
  const { onOpen: openAddRewardModal } = useAddEditRewardModal()
  const isMobile = useMobile()
  const [showAddTypeModal, setShowAddTypeModal] = useState(false)
  const { onOpen: openRewardTemplatesModal } = useRewardTemplatesModal()
  const { onOpen: openBonusRewardModal } = useBonusRewardModalStore()

  return (
    <>
      <FlexBox
        direction="row"
        justify="center"
        align="center"
        wrap={false}
        style={{
          position: "fixed",
          zIndex: 500,
          width: "min(600px, 100%)",
          bottom: isMobile ? "78px" : "20px",
          borderRadius: SPACING.space2,
          background: "rgba(13, 5, 105, 0.12)",
          padding: SPACING.space2,
        }}
      >
        <QuickActionMobileButton
          text="Add new reward"
          onClick={() => {
            trackAnalyticEvent(ANALYTIC_EVENTS.CUSTOM_REWARD_CREATE_CUSTOM)
            setShowAddTypeModal(true)
          }}
        />
      </FlexBox>
      <Modal
        isOpen={showAddTypeModal}
        onClose={() => setShowAddTypeModal(false)}
        displayCloseIcon
        animate
      >
        <div className="quest-modal">
          <div className="quest-modal-content x-small">
            <div className="quest-modal-header section-title">
              Create Custom Reward
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <Button
                text="Create Your Own"
                onClick={() => {
                  setTimeout(() => openAddRewardModal(), 100)
                  setShowAddTypeModal(false)
                }}
              />
              <Button
                text="Use a Template"
                onClick={() => {
                  setTimeout(() => openRewardTemplatesModal(), 100)
                  setShowAddTypeModal(false)
                }}
              />
              <Button
                onClick={() => {
                  trackAnalyticEvent(ANALYTIC_EVENTS.SEND_BONUS_REWARD)
                  setTimeout(() => openBonusRewardModal(), 100)
                  setShowAddTypeModal(false)
                }}
                style={{ height: "50px" }}
                text="Gift Bonus Coin Reward"
              />
            </div>
          </div>
        </div>
      </Modal>
      <RewardTemplates />
    </>
  )
}
