import { useIncidentTemplatesQuery } from "../../../networking/queries"
import { IncidentLogDataType } from "../../../types"

export const useCoachParentTasks = () => {
  const { data: incidentTemplates } = useIncidentTemplatesQuery()
  const coachDataTasks = incidentTemplates?.parent_training_templates

  const coachParentTasks =
    coachDataTasks?.filter(
      (task) => task.data_type === IncidentLogDataType.RATING
    ) || []
  const hasCoachParentTasks = coachParentTasks.length > 0
  const unreadCoachParentTasks = coachParentTasks.filter(
    (task) => !task.date_read
  )

  return {
    hasUnreadCoachParentTasks: unreadCoachParentTasks.length > 0,
    coachParentTasks,
    hasCoachParentTasks,
  }
}
