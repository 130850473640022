import {
  ArrowRightIcon,
  CircleIcon,
  CoachProfile,
  CoachTagCategory,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import {
  InfoSolidIcon,
  JoonColorExpanded,
  TextButton,
} from "@joonapp/web-shared"
import { FlexBox } from "@joonapp/web-shared"
import { useState } from "react"

import CoachTabNav from "./CoachTabNav"
import useCoachStore from "./useCoachStore"
import { useRouter } from "../../util/router"
import CareTeamAvatar from "../care/CareTeamAvatar"

const CoachSBHeader = ({
  coachProfile,
  careTeamLength,
}: {
  coachProfile: CoachProfile
  careTeamLength: number
}) => {
  const [isHoveredInfoIcon, setIsHoveredInfoIcon] = useState(false)
  const [isHoveredArrowIcon, setIsHoveredArrowIcon] = useState(false)
  const { onOpenInfoModal } = useCoachStore()

  const router = useRouter()
  const { nickname, tags } = coachProfile || {}
  const { name, last_name } = coachProfile?.user || {}
  const credentials = tags
    .filter((tag) => tag.category === CoachTagCategory.CREDENTIALS)
    .map((tag) => tag.name)
  const displayName = nickname ?? `${name} ${last_name || ""}`

  return (
    <FlexBox
      direction="column"
      justify="space-between"
      style={{
        background: JoonColorExpanded.yellow200,
        width: "100%",
        padding: SPACING.space4,
        paddingBottom: SPACING.space0,
        borderRadius: `0 0 ${SPACING.space4} ${SPACING.space4}`,
        minHeight: "120px",
        maxHeight: "120px",
      }}
    >
      <FlexBox
        direction="row"
        gap={SPACING.space2}
        wrap={false}
        align="center"
        style={{
          width: `min(100%, calc(600px - ${SPACING.space8}))`,
          margin: "0 auto",
        }}
      >
        {careTeamLength > 1 && (
          <TextButton
            onClick={() => router.push("/care")}
            style={{
              borderRadius: "50%",
              padding: SPACING.space1,
              transform: "rotate(180deg)",
              marginRight: SPACING.space2,
            }}
            onMouseEnter={() => setIsHoveredArrowIcon(true)}
            onMouseLeave={() => setIsHoveredArrowIcon(false)}
          >
            <ArrowRightIcon
              size={24}
              color={
                isHoveredArrowIcon
                  ? JoonColorExpanded.neutral300
                  : JoonUIColor.icon.neutral
              }
            />
          </TextButton>
        )}

        <CareTeamAvatar profile={coachProfile} />

        <FlexBox
          direction="row"
          justify="space-between"
          align="center"
          wrap={false}
        >
          <FlexBox direction="column" align="flex-start" wrap={false}>
            <Typography variant="h3">{displayName}</Typography>
            <FlexBox direction="column">
              {credentials.slice(0, 2).map((credential, index: number) => (
                <FlexBox
                  key={index}
                  direction="row"
                  align="center"
                  gap={SPACING.space1}
                  wrap={false}
                >
                  <CircleIcon size={4} color={JoonColorExpanded.yellow400} />
                  <Typography
                    variant="bodyXSmall"
                    color={JoonColorExpanded.purple600}
                    textAlign="left"
                  >
                    {credential}
                  </Typography>
                </FlexBox>
              ))}
            </FlexBox>
          </FlexBox>

          <TextButton
            onClick={onOpenInfoModal}
            style={{
              borderRadius: "50%",
              padding: SPACING.space1,
            }}
            onMouseEnter={() => setIsHoveredInfoIcon(true)}
            onMouseLeave={() => setIsHoveredInfoIcon(false)}
          >
            <InfoSolidIcon
              size={20}
              color={
                isHoveredInfoIcon
                  ? JoonColorExpanded.purple500
                  : JoonColorExpanded.purple600
              }
            />
          </TextButton>
        </FlexBox>
      </FlexBox>
      <CoachTabNav />
    </FlexBox>
  )
}

export default CoachSBHeader
