import { useMutation } from "@tanstack/react-query"

import { markDataTaskAsRead } from "./api"
import { QUERY_KEYS } from "../../constants"
import { queryClient } from "../../util/queryClient"

export const useMarkDataTaskAsReadMutation = () => {
  return useMutation({
    mutationFn: markDataTaskAsRead,
    onSettled: () =>
      queryClient.invalidateQueries([QUERY_KEYS.INCIDENT_TEMPLATES]),
  })
}
