import {
  DataTaskIncidentTemplate,
  IncidentTemplate,
  IncidentLogDataType,
} from "../types"
import { createJoonAPIClient } from "../util/joon-api"

export const getIncidents = async () => {
  const API = createJoonAPIClient()

  const response = await API.get<{
    child_action_templates: IncidentTemplate[]
    parent_training_templates: DataTaskIncidentTemplate[]
  }>("api/incident-logs/form-templates/")

  return response.data
}

export const createIncident = async ({
  userId,
  targetBehaviorId,
  freeformTargetBehavior,
  strategyId,
  freeformPreventiveStrategy,
  incidentDateTime,
  duration,
  intensity,
  responseIntensity,
  antecedent,
  consequence,
  frequency,
}: {
  userId: number
  targetBehaviorId?: number
  freeformTargetBehavior?: string
  strategyId?: number
  freeformPreventiveStrategy?: string
  incidentDateTime?: string
  duration?: string
  intensity?: number
  responseIntensity?: number
  antecedent?: string
  consequence?: string
  frequency?: string
}) => {
  const API = createJoonAPIClient()

  const response = await API.post("api/incident-logs/", {
    user_id: userId,
    target_behavior_id: targetBehaviorId,
    freeform_target_behavior: freeformTargetBehavior,
    preventive_strategy_id: strategyId,
    freeform_preventive_strategy: freeformPreventiveStrategy,
    incident_datetime: incidentDateTime,
    duration,
    intensity,
    response_intensity: responseIntensity,
    antecedent,
    consequence,
    frequency,
  })

  return response.data
}

export const createDataTaskIncident = async (
  params: {
    userId: number
    dataTaskId: number
    reflection: string | null
  } & (
    | { dataType: IncidentLogDataType.DURATION; duration: string }
    | { dataType: IncidentLogDataType.FREQUENCY; frequency: number }
    | {
        dataType: IncidentLogDataType.ACCURACY
        numSuccesses: number
        numTotal: number
      }
    | {
        dataType: IncidentLogDataType.RATING
        rating: number
      }
    | {
        dataType: null
      }
  )
) => {
  const API = createJoonAPIClient()

  const payload = {
    user_id: params.userId,
    training_data_collection_task_id: params.dataTaskId,
    reflection: params.reflection,
    ...(params.dataType === IncidentLogDataType.DURATION && {
      duration: params.duration,
    }),
    ...(params.dataType === IncidentLogDataType.FREQUENCY && {
      frequency: params.frequency,
    }),
    ...(params.dataType === IncidentLogDataType.ACCURACY && {
      accuracy_successes: params.numSuccesses,
      accuracy_attempts: params.numTotal,
    }),
    ...(params.dataType === IncidentLogDataType.RATING && {
      overall_rating: params.rating,
    }),
    incident_datetime: new Date().toISOString(),
  }

  const response = await API.post("api/incident-logs/", payload)

  return response.data
}
