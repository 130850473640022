import {
  CloseIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import DataTaskCard from "./DataTaskCard"
import useDataTasksModalStore from "./useDataTasksModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import { GRADIENTS } from "../../../constants"
import { useIncidentTemplatesQuery } from "../../../networking/queries"

const DataTasksModal = () => {
  const { isOpen, onClose } = useDataTasksModalStore()
  const { data: incidentTemplates } = useIncidentTemplatesQuery()

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      showCard={true}
      size="default"
      backgroundType="overlay"
    >
      <button
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: SPACING.space2,
        }}
        onClick={onClose}
      >
        <CloseIcon color={JoonUIColor.icon.neutral} />
      </button>
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        wrap={false}
        style={{
          padding: SPACING.space6,
          background: GRADIENTS.gradient100,
          height: "100%",
          overflowY: "auto",
        }}
      >
        <FlexBox direction="column" gap={SPACING.space2}>
          <Typography variant="h3">Practice tasks</Typography>
          <Typography variant="bodySmall">
            Your care team assigned the following tasks for you to do in between
            sessions.
          </Typography>
        </FlexBox>
        <FlexBox direction="column" gap={SPACING.space2}>
          {incidentTemplates?.parent_training_templates?.map((task) => (
            <DataTaskCard key={task.id} dataTask={task} />
          ))}
        </FlexBox>
      </FlexBox>
    </SlideUpModal>
  )
}

export default DataTasksModal
