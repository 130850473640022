import {
  Typography,
  FlexBox,
  Checkbox,
  TextInput,
  SPACING,
  Button,
  JoonUIColor,
  Modal,
} from "@joonapp/web-shared"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { create } from "zustand"

import Counter from "../../components/counter/Counter"
import InputSectionBox from "../../components/inputSectionBox/InputSectionBox"
import { QUERY_KEYS } from "../../constants"
import {
  getTodaysBonusRewards,
  sendBonusReward,
} from "../../networking/bonusRewards"
import { useFamilyQuery } from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"
import { createErrorFromResponse } from "../../util/util"

interface BonusRewardModalStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void

  selectedChildren: number[]
  selectChild: (id: number) => void
  coinAmount: number
  setCoinAmount: (amount: number) => void
  rewardDescription: string
  setRewardDescription: (description: string) => void
  isSendingReward: boolean
  setIsSendingReward: (isSending: boolean) => void
  error: string | null
  setError: (error: string | null) => void
}

export const useBonusRewardModalStore = create<BonusRewardModalStore>(
  (set, get) => ({
    isOpen: false,
    onOpen: () => set({ isOpen: true }),
    onClose: () =>
      set({
        isOpen: false,
        selectedChildren: [],
        coinAmount: 10,
        rewardDescription: "",
        isSendingReward: false,
        error: null,
      }),
    selectedChildren: [],
    selectChild: (id: number) => {
      const currentSelectedChildren = get().selectedChildren
      if (currentSelectedChildren.includes(id)) {
        const newChildren = currentSelectedChildren.filter(
          (childId) => childId !== id
        )
        set({ selectedChildren: newChildren })
      } else {
        set({ selectedChildren: [...currentSelectedChildren, id] })
      }
    },
    coinAmount: 10,
    setCoinAmount: (amount) => set({ coinAmount: amount }),
    rewardDescription: "",
    setRewardDescription: (description) =>
      set({ rewardDescription: description }),
    isSendingReward: false,
    setIsSendingReward: (isSending) => set({ isSendingReward: isSending }),
    error: null,
    setError: (error) => set({ error }),
  })
)

const BonusRewardModal = () => {
  const {
    isOpen,
    onClose: closeBonusRewardModal,
    selectedChildren,
    selectChild,
    coinAmount,
    setCoinAmount,
    rewardDescription,
    setRewardDescription,
    isSendingReward,
    setIsSendingReward,
    error,
    setError,
  } = useBonusRewardModalStore()
  const { data: family } = useFamilyQuery()
  const { data: children } = useFamilyQuery(FamilyQuerySelect.CHILDREN)
  const childUsers = children?.map((child) => child.user)

  const queryClient = useQueryClient()

  const { data: childrenWhoReceivedRewards } = useQuery({
    queryKey: [QUERY_KEYS.BONUS_REWARDS],
    queryFn: getTodaysBonusRewards,
    select: (data) => data.results.map((reward: any) => reward.receiver_id),
    enabled: !!family,
  })

  const childrenWithRewardsAvailable =
    childUsers?.filter(
      (child) => !childrenWhoReceivedRewards?.includes(child.id)
    ) || []

  const sendReward = async () => {
    if (selectedChildren.length === 0) {
      throw setError("Please select at least one child to send this to!")
    } else if (rewardDescription.length === 0) {
      throw setError("Please enter why they are receiving this reward!")
    }
    setError(null)

    setIsSendingReward(true)

    try {
      const rewardPromises = selectedChildren.map((childId) =>
        sendBonusReward(childId, coinAmount, rewardDescription)
      )
      await Promise.all(rewardPromises)
      trackAnalyticEvent(ANALYTIC_EVENTS.BONUS_REWARD_SENT)
      closeBonusRewardModal()
      queryClient.invalidateQueries([QUERY_KEYS.BONUS_REWARDS])
    } catch (errorResponse) {
      const errorResponseString = createErrorFromResponse(errorResponse)
      if (errorResponseString) setError(errorResponseString)
      else setError("Something went wrong, please try again later")
    }
    setIsSendingReward(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeBonusRewardModal}
      displayCloseIcon
      animate
    >
      <FlexBox
        direction="column"
        style={{ padding: "30px", width: "min(450px, 95vw" }}
        gap={SPACING.space4}
      >
        <FlexBox justify="center">
          <Typography variant="h2">Bonus Reward</Typography>
        </FlexBox>
        <FlexBox direction="column">
          <Typography variant="bodyBold">
            Give a bonus reward for going above and beyond!
          </Typography>
          <Typography variant="body">
            Each child can only receive one reward per day.
          </Typography>
        </FlexBox>

        <InputSectionBox>
          <FlexBox
            justify="space-between"
            align="center"
            direction="column"
            gap={SPACING.space2}
          >
            <Typography variant="bodyBold">Award Bonus To</Typography>
            <FlexBox
              justify="flex-start"
              gap={SPACING.space1}
              style={{ textAlign: "center" }}
            >
              {childrenWithRewardsAvailable?.length > 0 ? (
                childrenWithRewardsAvailable?.map((child, i) => (
                  <Checkbox
                    name="test"
                    key={i}
                    label={child?.name}
                    onChange={() => selectChild(child?.id)}
                    selected={selectedChildren.includes(child.id)}
                  />
                ))
              ) : (
                <Typography variant="body">
                  You have sent a reward to all your children today. Come back
                  tomorrow to send more!
                </Typography>
              )}
            </FlexBox>
          </FlexBox>
        </InputSectionBox>
        <InputSectionBox>
          <FlexBox justify="space-between" align="center" direction="column">
            <Typography variant="bodyBold">Select Coin Amount</Typography>
            <Counter
              value={coinAmount}
              setValue={setCoinAmount}
              maxValue={50}
            />
          </FlexBox>
        </InputSectionBox>
        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="bodyBold">
            Tell them why they got a bonus
          </Typography>
          <TextInput
            name="rewardDescription"
            value={rewardDescription}
            onChange={(e: any) => setRewardDescription(e.target.value)}
            placeholder="Enter a description for the reward"
            fullWidth
          />
        </FlexBox>
        <FlexBox direction="column">
          {error && (
            <Typography
              variant="caption"
              style={{ textAlign: "center", width: "100%" }}
              color={JoonUIColor.semantic.alert}
            >
              {error}
            </Typography>
          )}
          <Button
            text="Send Reward"
            isLoading={isSendingReward}
            onClick={sendReward}
            fullWidth
          />
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

export default BonusRewardModal
