import {
  Button,
  CloseIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  Slider,
  SPACING,
  TextArea,
  TextButton,
  TextInput,
  Typography,
} from "@joonapp/web-shared"
import { useMutation } from "@tanstack/react-query"
import dayjs from "dayjs"
import toast from "react-hot-toast"
import { useShallow } from "zustand/react/shallow"

import useDataTaskIncident from "./useDataTask"
import useDataTaskCompletionModalStore, {
  DataTaskCompletionView,
} from "./useDataTaskCompletionModalStore"
import SlideUpModal from "../../../components/slideUpModal/SlideUpModal"
import TimerInput from "../../../components/timerInput/TimerInput"
import { createDataTaskIncident } from "../../../networking/incidents"
import { IncidentLogDataType } from "../../../types"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../../util/analytics"
import CatchUpTabBar from "../../me/CatchUpTabBar"

const DataTaskCompletionModal = () => {
  const { isOpen, onClose, view } = useDataTaskCompletionModalStore(
    useShallow((state) => ({
      isOpen: state.isOpen,
      onClose: state.onClose,
      view: state.view,
    }))
  )

  return (
    <SlideUpModal
      isOpen={isOpen}
      onClose={onClose}
      size="default"
      backgroundType="overlay"
    >
      {view === DataTaskCompletionView.COMPLETION && <DataTaskCompletionForm />}
      {view === DataTaskCompletionView.SUCCESS && <DataTaskSuccessView />}
    </SlideUpModal>
  )
}

const DataTaskCompletionForm = () => {
  const {
    dataTaskId,
    duration,
    reflection,
    numSuccesses,
    numTotal,
    frequency,
    rating,
    onClose,
    setDuration,
    setReflection,
    setNumSuccesses,
    setNumTotal,
    setFrequency,
    setView,
    setRating,
  } = useDataTaskCompletionModalStore(
    useShallow((state) => ({
      isOpen: state.isOpen,
      onClose: state.onClose,
      dataTaskId: state.dataTaskId,
      duration: state.duration,
      setDuration: state.setDuration,
      reflection: state.reflection,
      setReflection: state.setReflection,
      numSuccesses: state.numSuccesses,
      setNumSuccesses: state.setNumSuccesses,
      numTotal: state.numTotal,
      setNumTotal: state.setNumTotal,
      frequency: state.frequency,
      setFrequency: state.setFrequency,
      view: state.view,
      setView: state.setView,
      rating: state.rating,
      setRating: state.setRating,
    }))
  )
  const createDataTaskMutation = useMutation(createDataTaskIncident)

  const { dataTask } = useDataTaskIncident(dataTaskId)

  const onSubmitIncidentReport = async () => {
    if (!dataTaskId) {
      toast.error("Failed to save. Please refresh and try again.")
      throw new Error("Data task ID does not exist")
    }
    const userId = dataTask?.child?.id
    if (!userId) {
      toast.error("Failed to save. Please refresh and try again.")
      throw new Error("User ID does not exist")
    }

    switch (dataTask?.data_type) {
      case IncidentLogDataType.DURATION:
        if (!duration) return toast.error("Duration is required")

        await createDataTaskMutation.mutateAsync({
          userId: userId,
          dataType: IncidentLogDataType.DURATION,
          dataTaskId: dataTaskId,
          duration: duration,
          reflection: reflection,
        })
        break
      case IncidentLogDataType.FREQUENCY:
        if (frequency === null) return toast.error("Frequency is required")
        if (Number.isNaN(frequency)) return toast.error("Frequency is invalid")

        await createDataTaskMutation.mutateAsync({
          userId: userId,
          dataType: IncidentLogDataType.FREQUENCY,
          dataTaskId: dataTaskId,
          frequency: parseInt(frequency),
          reflection: reflection,
        })
        break
      case IncidentLogDataType.ACCURACY:
        if (!numSuccesses) return toast.error("Successes count is required")
        if (!numTotal) return toast.error("Total count is required")

        await createDataTaskMutation.mutateAsync({
          userId: userId,
          dataType: IncidentLogDataType.ACCURACY,
          dataTaskId: dataTaskId,
          numSuccesses: parseInt(numSuccesses),
          numTotal: parseInt(numTotal),
          reflection: reflection,
        })
        break
      case IncidentLogDataType.RATING:
        if (!rating) return toast.error("Rating is required")

        const parentId = dataTask?.subject?.id
        if (!parentId) return toast.error("Parent ID does not exist")

        await createDataTaskMutation.mutateAsync({
          userId: parentId,
          dataType: IncidentLogDataType.RATING,
          dataTaskId: dataTaskId,
          rating: rating,
          reflection: reflection,
        })
        break
      case null:
        await createDataTaskMutation.mutateAsync({
          userId: userId,
          dataType: null,
          dataTaskId: dataTaskId,
          reflection: reflection,
        })
        break
    }

    trackAnalyticEvent(ANALYTIC_EVENTS.SUBMIT_DATA_COLLECTION_TASK)
    setView(DataTaskCompletionView.SUCCESS)
  }

  return (
    <>
      {dataTask?.training && (
        <FlexBox
          direction="row"
          wrap={false}
          align="flex-start"
          style={{
            background: JoonUIColor.background.lightGray,
            padding: SPACING.space4,
          }}
        >
          <FlexBox direction="column" wrap={false}>
            <Typography variant="bodySmall">Part of:</Typography>
            <Typography variant="caption">
              {dataTask?.training?.title}
            </Typography>
          </FlexBox>
          <TextButton onClick={onClose}>
            <CloseIcon size={12} color={JoonUIColor.icon.neutral} />
          </TextButton>
        </FlexBox>
      )}
      <FlexBox
        direction="column"
        gap={SPACING.space4}
        style={{ padding: SPACING.space4, marginBottom: "auto" }}
      >
        <div
          style={{
            background: JoonColorExpanded.yellow100,
            padding: SPACING.space2,
            borderRadius: SPACING.space2,
            width: "100%",
          }}
        >
          {dataTask?.date_assigned && (
            <Typography variant="bodySmall" textAlign="left">
              Assigned on {dayjs(dataTask.date_assigned).format("MMM D")}
            </Typography>
          )}
          <Typography variant="bodyBold">{dataTask?.title}</Typography>
        </div>
        <div>
          <Typography variant="caption" textAlign="left">
            Description or instructions
          </Typography>
          <Typography variant="bodySmall">{dataTask?.description}</Typography>
        </div>
        {dataTask?.data_type === IncidentLogDataType.DURATION && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">Duration</Typography>
            <TimerInput
              modalTitle="Select duration"
              name="Incident duration"
              time={duration}
              setTime={setDuration}
              fullWidth
              placeholder="Select duration"
            />
          </FlexBox>
        )}
        {dataTask?.data_type === IncidentLogDataType.FREQUENCY && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">
              How many times did this happen?
            </Typography>
            <TextInput
              name="Frequency"
              maxLength={3}
              icon="code"
              value={frequency || ""}
              onChange={(e) => setFrequency(e.target.value)}
              fullWidth
            />
          </FlexBox>
        )}
        {dataTask?.data_type === IncidentLogDataType.ACCURACY && (
          <>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="caption">
                Count of successful attempts
              </Typography>
              <TextInput
                name="Count of successful attempts"
                maxLength={3}
                icon="code"
                value={numSuccesses?.toString() || ""}
                onChange={(e) => setNumSuccesses(e.target.value)}
                fullWidth
              />
            </FlexBox>
            <FlexBox direction="column" gap={SPACING.space1}>
              <Typography variant="caption">Count of total attempts</Typography>
              <TextInput
                name="Count of total attempts"
                maxLength={3}
                icon="code"
                value={numTotal?.toString() || ""}
                onChange={(e) => setNumTotal(e.target.value)}
                fullWidth
              />
            </FlexBox>
          </>
        )}
        {dataTask?.data_type === IncidentLogDataType.RATING && (
          <FlexBox direction="column" gap={SPACING.space1}>
            <Typography variant="caption">How did it go?</Typography>
            <Slider
              value={rating}
              setValue={setRating}
              minLabel="Needs work"
              maxLabel="Exceeded expectations"
              options={[0, 1, 2, 3, 4, 5, 6]}
            />
          </FlexBox>
        )}

        <FlexBox direction="column" gap={SPACING.space1}>
          <Typography variant="caption">
            Any reflection you'd like to share?
          </Typography>
          <TextArea
            name="Reflection"
            value={reflection || ""}
            onChange={(e) => setReflection(e.target.value)}
            placeholder={
              dataTask?.data_type === IncidentLogDataType.RATING
                ? "What went well? What could be better?"
                : "eg. We were watching TV then it’s dinner time and Kevin won’t want to go eat dinner."
            }
            fullWidth
          />
        </FlexBox>
      </FlexBox>
      <CatchUpTabBar>
        <FlexBox
          direction="row"
          justify="flex-end"
          gap={SPACING.space4}
          wrap={false}
          align="center"
          style={{ padding: SPACING.space2 }}
        >
          <TextButton onClick={onClose}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              Cancel
            </Typography>
          </TextButton>
          <Button
            text="Submit"
            fullWidth
            onClick={onSubmitIncidentReport}
            isLoading={createDataTaskMutation.isLoading}
          />
        </FlexBox>
      </CatchUpTabBar>
    </>
  )
}

export default DataTaskCompletionModal

const DataTaskSuccessView = () => {
  const { onClose } = useDataTaskCompletionModalStore()

  return (
    <FlexBox
      direction="column"
      wrap={false}
      style={{
        padding: SPACING.space12,
        background: `linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.8) 2.83%,
        rgba(255, 255, 255, 0) 51.53%,
        rgba(255, 255, 255, 0.8) 99.81%
      ),
      #e9eafa`,
        height: "100%",
      }}
      align="center"
      justify="flex-start"
      gap={SPACING.space12}
    >
      <div style={{ fontSize: "64px" }}>🫶</div>
      <FlexBox
        direction="column"
        gap={SPACING.space2}
        style={{ marginBottom: SPACING.space14, maxWidth: "300px" }}
      >
        <Typography variant="bodyBold" textAlign="center">
          Great job making progress towards your practice tasks!
        </Typography>
        <Typography
          variant="bodySmall"
          color={JoonUIColor.text.secondary}
          textAlign="center"
        >
          Practice makes progress and nothing will help progress your child’s
          development more than consistent practice.
          <Typography
            variant="bodySmall"
            textAlign="center"
            color={JoonUIColor.text.secondary}
          >
            Your progress data will inform next steps.
          </Typography>
        </Typography>
      </FlexBox>
      <FlexBox direction="column" gap={SPACING.space4} align="center">
        <Button text="Return home" fullWidth onClick={onClose} />
      </FlexBox>
    </FlexBox>
  )
}
