import { Typography } from "@joonapp/web-shared"
import { TextButton } from "@joonapp/web-shared"
import {
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
} from "@joonapp/web-shared"

const RewardsCounter = ({
  maxQuantity,
  quantity,
  setQuantity,
}: {
  maxQuantity: number
  quantity: number
  setQuantity: (quantity: number) => void
}) => {
  const increment = () =>
    quantity < maxQuantity ? setQuantity(quantity + 1) : null
  const decrement = () => (quantity > 1 ? setQuantity(quantity - 1) : null)

  const plusDisabled = maxQuantity === 0 || quantity >= maxQuantity
  const minusDisabled = quantity <= 1

  const onChangeReward = (value: number) => {
    if (isNaN(value) || value > maxQuantity) return
    if (value < 0) setQuantity(0)
    else setQuantity(value)
  }

  const onBlur = () => {
    if (quantity < 1) setQuantity(1)
    if (quantity > maxQuantity) setQuantity(maxQuantity)
  }

  return (
    <FlexBox
      direction="row"
      justify="space-between"
      align="center"
      gap={SPACING.space1}
      wrap={false}
      fullWidth
      style={{
        padding: `${SPACING.space3} ${SPACING.space2}`,
        borderRadius: SPACING.space2,
        border: `1px solid ${JoonUIColor.border.default}`,
      }}
    >
      <TextButton
        disabled={minusDisabled}
        onClick={decrement}
        style={{
          pointerEvents: minusDisabled ? "none" : undefined,
          padding: SPACING.space1,
        }}
      >
        <Typography
          variant="h1"
          color={
            minusDisabled
              ? JoonColorExpanded.neutral300
              : JoonUIColor.text.primary
          }
          style={{
            userSelect: "none",
            fontWeight: 400,
            lineHeight: SPACING.space6,
          }}
        >
          -
        </Typography>
      </TextButton>
      <input
        type="text"
        inputMode="numeric"
        pattern="[0-9]*"
        className="counter-count"
        value={quantity}
        onChange={(e) => onChangeReward(Number(e.target.value))}
        onBlur={onBlur}
        style={{
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: 600,
          lineHeight: "24px",
          width: "88%",
          backgroundColor: JoonUIColor.background.lightGray,
          padding: `${SPACING.space2} ${SPACING.space4}`,
          borderRadius: SPACING.space2,
        }}
      />
      <TextButton
        disabled={plusDisabled}
        onClick={increment}
        style={{
          pointerEvents: plusDisabled ? "none" : undefined,
          padding: SPACING.space1,
        }}
      >
        <Typography
          variant="h1"
          color={
            plusDisabled
              ? JoonColorExpanded.neutral300
              : JoonUIColor.text.primary
          }
          style={{
            userSelect: "none",
            fontWeight: 400,
            lineHeight: SPACING.space6,
          }}
        >
          +
        </Typography>
      </TextButton>
    </FlexBox>
  )
}

export default RewardsCounter
