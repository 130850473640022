import {
  ChevronRightIcon,
  CircleIcon,
  FlexBox,
  InfoSolidIcon,
  JoonColorExpanded,
  JoonUIColor,
  LockClosedIcon,
  SPACING,
  TextButton,
  Typography,
} from "@joonapp/web-shared"
import { useEffect, useState } from "react"

import Shine from "../../../components/coaching/Shine"
import PageWrapper from "../../../components/pageWrapper/PageWrapper"
import { CoachingStep } from "../../../constants"
import useMobile from "../../../hooks/useMobile"
import {
  cohorts,
  experimentNames,
  useGetExperimentCohort,
} from "../../../util/experiments"
import { useRouter } from "../../../util/router"
import useCareTeam from "../../care/useCareTeam"
import { useCoachingStore } from "../useCoachingStore"

const CoachPreviewPage = () => {
  const [isHovered, setIsHovered] = useState(false)
  const [isHoveredInfoIcon, setIsHoveredInfoIcon] = useState(false)
  const { setStep } = useCoachingStore()
  const { careTeam, isLoading, hasCoach, firstCoach } = useCareTeam()
  const valuePropVariant = useGetExperimentCohort(
    experimentNames.webParentValuePropTesting
  )
  const isMobile = useMobile()
  const router = useRouter()
  const credentials = ["Licensed MSW", "Certified Good Inside Parent Coach"]
  const imageSource = () => {
    switch (valuePropVariant) {
      case cohorts.variantA:
        return "/images/swappableValuePropImages/progress-over-time.webp"
      case cohorts.variantB:
        return "/images/swappableValuePropImages/this-coach-is-mine.webp"
      case cohorts.variantC:
        return "/images/swappableValuePropImages/new-skills.webp"
      case cohorts.variantD:
        return "/images/swappableValuePropImages/dm-video.webm"
      default:
        return ""
    }
  }

  const onLearnMoreClick = () => {
    setStep(CoachingStep.LANDING)
    router.push("/coaching?from=preview")
  }

  useEffect(() => {
    if (hasCoach) router.push(`/coach/${firstCoach?.user.id}`)
  }, [careTeam, hasCoach, firstCoach, router])

  if (isLoading || hasCoach) return <></>

  return (
    <PageWrapper
      style={{
        background:
          "linear-gradient(rgba(255, 255, 255, 0.8) 2.83%, rgba(255, 255, 255, 0) 51.53%, rgba(255, 255, 255, 0.8) 99.81%), rgb(233, 234, 250)",
        paddingBottom: "0px",
      }}
    >
      <FlexBox
        direction="column"
        align="center"
        wrap={false}
        style={{
          background: JoonUIColor.background.primaryNeutral,
          position: "relative",
          padding: SPACING.space0,
          minHeight: "100dvh",
          width: "min(100%, 480px)",
        }}
      >
        <FlexBox
          direction="row"
          gap={SPACING.space2}
          wrap={false}
          align="center"
          style={{
            minHeight: "90px",
            maxHeight: "90px",
            background: JoonColorExpanded.yellow200,
            width: "100%",
            padding: SPACING.space4,
            borderRadius: `0 0 ${SPACING.space4} ${SPACING.space4}`,
          }}
        >
          <div
            style={{
              minWidth: "48px",
              maxWidth: "48px",
              minHeight: "48px",
              maxHeight: "48px",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="/images/avatars/coach_amy.png"
              style={{ width: "100%", height: "100%", borderRadius: "50%" }}
              alt="Coach Profile"
            />
          </div>

          <FlexBox
            direction="row"
            justify="space-between"
            align="center"
            wrap={false}
          >
            <FlexBox direction="column" align="flex-start" wrap={false}>
              <Typography
                variant="bodyBold"
                style={{ lineHeight: "1", marginBottom: SPACING.space05 }}
              >
                Coaching preview with Amy
              </Typography>
              <FlexBox direction="column">
                {credentials.map((credential, index: number) => (
                  <FlexBox
                    key={index}
                    direction="row"
                    align="center"
                    gap={SPACING.space1}
                    wrap={false}
                  >
                    <CircleIcon size={4} color={JoonColorExpanded.yellow400} />
                    <Typography
                      variant="bodyXSmall"
                      color={JoonColorExpanded.purple600}
                      textAlign="left"
                    >
                      {credential}
                    </Typography>
                  </FlexBox>
                ))}
              </FlexBox>
            </FlexBox>

            <TextButton
              onClick={onLearnMoreClick}
              style={{
                borderRadius: "50%",
                padding: SPACING.space1,
              }}
              onMouseEnter={() => setIsHoveredInfoIcon(true)}
              onMouseLeave={() => setIsHoveredInfoIcon(false)}
            >
              <InfoSolidIcon
                size={20}
                color={
                  isHoveredInfoIcon
                    ? JoonColorExpanded.purple500
                    : JoonColorExpanded.purple600
                }
              />
            </TextButton>
          </FlexBox>
        </FlexBox>

        <button
          onClick={onLearnMoreClick}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          <FlexBox
            direction="column"
            justify={
              valuePropVariant === cohorts.variantD ? "flex-end" : "flex-start"
            }
            style={{
              flexGrow: 1,
              alignItems: "center",
              pointerEvents: "none",
              userSelect: "none",
            }}
            fullWidth
          >
            {valuePropVariant === cohorts.variantD ? (
              <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: "100%",
                }}
              >
                <source src={imageSource()} type="video/webm" />
              </video>
            ) : (
              <img
                src={imageSource()}
                alt="Coach Chat"
                style={{ width: "100%" }}
              />
            )}
          </FlexBox>

          <FlexBox
            direction="row"
            align="center"
            wrap={false}
            style={{
              pointerEvents: "none",
              userSelect: "none",
              position: "relative",
              width: "100%",
              padding: SPACING.space4,
              paddingTop:
                valuePropVariant === cohorts.variantD ? SPACING.space4 : 0,
            }}
          >
            <button
              style={{
                padding: 0,
                paddingTop: SPACING.space05,
                position: "absolute",
                left: SPACING.space7,
                pointerEvents: "none",
              }}
              onClick={() => {}}
            >
              <LockClosedIcon color={JoonUIColor.icon.neutral} size={14} />
            </button>
            <textarea
              value={"Get coaching to message your coach!"}
              onChange={() => {}}
              name="message"
              style={{
                pointerEvents: "none",
                color: JoonUIColor.text.secondary,
                backgroundColor: "#EEEEEE",
                borderRadius: "20px",
                padding: `10px ${SPACING.space0} 10px ${SPACING.space8}`,
                width: "100%",
                height: "36px",
                border: "none",
                outline: "none",
                resize: "none",
                fontSize: "14px",
                fontWeight: "500",
                overflow: "hidden",
              }}
            />
          </FlexBox>
        </button>

        <FlexBox
          direction="column"
          align="center"
          gap={SPACING.space2}
          style={{
            position: "relative",
            background: JoonColorExpanded.yellow100,
            boxShadow: "0px -2px 4px rgba(134, 119, 167, 0.24)",
            paddingBottom: isMobile ? "132px" : "72px",
          }}
        >
          <FlexBox
            direction="column"
            style={{ position: "relative", padding: SPACING.space3 }}
          >
            <FlexBox direction="column" gap={SPACING.space1}>
              <FlexBox
                direction="row"
                justify="left"
                align="center"
                gap={SPACING.space1}
              >
                <Typography variant="h3" color={JoonUIColor.text.primary}>
                  Joon
                </Typography>
                <Typography
                  variant="h3"
                  color={JoonUIColor.text.primaryAccent}
                  style={{ fontFamily: "Caveat" }}
                >
                  Plus
                </Typography>
                <Typography variant="h3" color={JoonUIColor.text.primary}>
                  Coach
                </Typography>
              </FlexBox>

              <Typography variant="bodySmall" color={JoonUIColor.text.primary}>
                Unlock messaging with a coach and get easy strategies you can
                use at home!
              </Typography>
            </FlexBox>
          </FlexBox>
          <button
            style={{
              position: "fixed",
              bottom: isMobile ? "80px" : SPACING.space4,
              width: "min(450px, 96%)",
              padding: 0,
            }}
            onClick={onLearnMoreClick}
          >
            <FlexBox
              direction="row"
              align="center"
              wrap={false}
              style={{
                background: JoonUIColor.background.accent,
                borderRadius: SPACING.space2,
                border: isHovered
                  ? `1px solid ${JoonUIColor.border.accent}`
                  : `1px solid ${JoonUIColor.border.inverted}`,
                transition: "border 0.2s ease-in-out",
                padding: `${SPACING.space3} ${SPACING.space2} ${SPACING.space3} ${SPACING.space4}`,
                height: "54px",
                boxShadow: "4px 4px 6px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Typography
                variant="bodyBold"
                color={JoonUIColor.text.inverted}
                textAlign="left"
                style={{ lineHeight: "1.4" }}
              >
                Learn more
              </Typography>
              <div style={{ minWidth: "36px" }}>
                <ChevronRightIcon color={JoonUIColor.icon.inverted} size={28} />
              </div>
            </FlexBox>
            <Shine />
          </button>
        </FlexBox>
      </FlexBox>
    </PageWrapper>
  )
}

export default CoachPreviewPage
