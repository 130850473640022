import {
  CaretLeftIcon,
  CaretRightIcon,
  FlexBox,
  JoonColorExpanded,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"
import { ReactNode, useState } from "react"

type Props = {
  onClickPrevious: () => void
  onClickNext: () => void
  options: {
    label: string | ReactNode
    value: any
    imageUrl?: string | null
  }[]
  selectedOption: {
    label: string | ReactNode
    value: any
    imageUrl?: string | null
  }
  isOnDnd?: boolean
}

const ToggleHeader = ({
  onClickPrevious,
  onClickNext,
  options,
  selectedOption,
  isOnDnd,
}: Props) => {
  return (
    <FlexBox
      direction="row"
      justify="center"
      align="center"
      wrap={false}
      style={{
        margin: "0 auto",
        minHeight: "60px",
        maxHeight: "60px",
        minWidth: isOnDnd ? "min(320px, 80%)" : "250px",
        width: "fit-content",
        maxWidth: isOnDnd ? "85%" : "auto",
      }}
      gap={isOnDnd ? undefined : SPACING.space6}
    >
      {options.length > 1 && (
        <IconButton
          onClick={onClickPrevious}
          icon={<CaretLeftIcon color={JoonUIColor.icon.neutral} size={12} />}
        />
      )}
      <FlexBox
        align="center"
        justify="center"
        gap={SPACING.space2}
        wrap={false}
      >
        {selectedOption.imageUrl && (
          <img
            src={selectedOption.imageUrl}
            style={{ width: "30px", height: "30px", borderRadius: "50%" }}
            alt="avatar"
          />
        )}
        <Typography variant="h3" color={JoonColorExpanded.indigo700}>
          {selectedOption.label}
        </Typography>
      </FlexBox>
      {options.length > 1 && (
        <IconButton
          onClick={onClickNext}
          icon={<CaretRightIcon color={JoonUIColor.icon.neutral} size={12} />}
        />
      )}
    </FlexBox>
  )
}

const IconButton = ({
  onClick,
  icon,
  style,
}: {
  onClick: () => void
  icon: JSX.Element
  style?: React.CSSProperties
}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <button
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: SPACING.space4,
        borderRadius: SPACING.space2,
        background: isHovered ? JoonColorExpanded.neutral100 : "transparent",
        ...style,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {icon}
    </button>
  )
}

export default ToggleHeader
