import { FlexBox, ParentTrainingStatus, SPACING } from "@joonapp/web-shared"
import { useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"

import CatchUpButton from "./CatchUpButton"
import CatchUpCompleteButton from "./CatchUpCompleteButton"
import DownloadAppBanner from "./DownloadAppBanner"
import InviteABASection from "./parentTasks/InviteABASection"
import ParentTasksSection from "./parentTasks/ParentTasksSection"
import QuickActionsSection from "./QuickActionsSection"
import TodaysProgressSection from "./TodaysProgressSection"
import ChildAuthCode from "../../components/childAuthCode/ChildAuthCode"
import PageContentWrapper from "../../components/pageContentWrapper/PageContentWrapper"
import PageHeaderLeft from "../../components/pageHeader/PageHeaderLeft"
import PageWrapper from "../../components/pageWrapper/PageWrapper"
import { SkeletonLoader } from "../../components/skeleton/Skeleton"
import { GRADIENTS, QUERY_KEYS } from "../../constants"
import useDocumentTitle from "../../hooks/useDocumentTitle"
import {
  useFamilyQuery,
  useTrainingsQuery,
  useUserQuery,
} from "../../networking/queries"
import { FamilyQuerySelect } from "../../types"
import { requireAuth } from "../../util/auth"
import { useRouter } from "../../util/router"
import { localStorageItems } from "../../util/storage"
import { noOnboardedChildren } from "../../util/util"
import TrainingsList from "../academy/TrainingsList"
import useCareTeam from "../care/useCareTeam"

const MePage = requireAuth(() => {
  useDocumentTitle("Joon")
  const { user } = useUserQuery()
  const { data: children, isLoading: isLoadingChildren } = useFamilyQuery(
    FamilyQuerySelect.CHILDREN
  )
  const {
    hasTherapist,
    firstTherapist,
    isLoading: isLoadingCareTeam,
  } = useCareTeam()
  const { data: trainingsIP, isLoading: isLoadingIP } = useTrainingsQuery(
    ParentTrainingStatus.IN_PROGRESS
  )
  const isLoading = isLoadingChildren || isLoadingCareTeam || isLoadingIP
  const queryClient = useQueryClient()
  const router = useRouter()
  const linkedAndNoOnboardedChildren =
    hasTherapist && noOnboardedChildren(children)
  const hasInProgressTrainings = trainingsIP && trainingsIP.length > 0

  useEffect(() => {
    localStorage.removeItem(localStorageItems.parentQuestionnaireAnswers)
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_BOARD])
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_REVIEW])
    queryClient.invalidateQueries([QUERY_KEYS.QUEST_INSTANCE_STATS])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (localStorage.getItem(localStorageItems.coachOnboardingComplete)) {
      localStorage.removeItem(localStorageItems.coachOnboardingComplete)
      router.push("/coach")
    }
    if (
      localStorage.getItem(localStorageItems.needsToSeeTherapistTour) &&
      firstTherapist
    ) {
      router.push(`/care/${firstTherapist.user.id}/academy`)
    }
  }, [router, firstTherapist])

  return (
    <PageWrapper style={{ background: GRADIENTS.gradient100 }}>
      <DownloadAppBanner />
      <PageContentWrapper mobileGutter>
        <PageHeaderLeft>Welcome back, {user?.name}!</PageHeaderLeft>
        {linkedAndNoOnboardedChildren || isLoading ? (
          <div style={{ minHeight: SPACING.space3 }} />
        ) : (
          <ChildAuthCode />
        )}
        <FlexBox direction="column" gap={SPACING.space6} align="unset">
          <CatchUpButton />
          <CatchUpCompleteButton />
          {!linkedAndNoOnboardedChildren &&
            (isLoading ? (
              <SkeletonLoader style={{ borderRadius: SPACING.space2 }} />
            ) : (
              <QuickActionsSection />
            ))}
          <ParentTasksSection />
          <InviteABASection />
          {!linkedAndNoOnboardedChildren &&
            (isLoading ? (
              <SkeletonLoader style={{ borderRadius: SPACING.space2 }} />
            ) : (
              <TodaysProgressSection />
            ))}
          {hasInProgressTrainings && <TrainingsList />}
        </FlexBox>
      </PageContentWrapper>
    </PageWrapper>
  )
})

export default MePage
