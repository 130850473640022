import {
  ChevronRightIcon,
  FlexBox,
  JoonUIColor,
  SPACING,
  Typography,
} from "@joonapp/web-shared"

import { useAddEditRewardModal } from "./AddEditRewardModal"
import { useRewardTemplatesModal } from "./RewardTemplates"
import { HoverCard } from "../../components/hoverCard/HoverCard"
import { TemplateFigure } from "../../components/templateFigure/TemplateFigure"
import { ANALYTIC_EVENTS, trackAnalyticEvent } from "../../util/analytics"

export const RewardsEmpty = () => {
  const { onOpen: openRewardTemplateModal } = useRewardTemplatesModal()
  const { onOpen: openAddRewardModal } = useAddEditRewardModal()

  const onCreateCustomRewardClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.EMPTY_STATE_CREATE_CUSTOM, {
      type: "custom_reward",
    })
    openAddRewardModal()
  }

  const onOpenTemplatesClick = () => {
    trackAnalyticEvent(ANALYTIC_EVENTS.EMPTY_STATE_OPEN_TEMPLATES, {
      type: "custom_reward",
    })
    openRewardTemplateModal()
  }

  return (
    <FlexBox
      align="center"
      justify="center"
      gap={SPACING.space3}
      direction="column"
    >
      <HoverCard fullWidth onClick={onCreateCustomRewardClick}>
        <FlexBox
          justify="space-between"
          direction="row"
          align="center"
          wrap={false}
        >
          <Typography variant="bodyBold" color={JoonUIColor.text.primaryAccent}>
            + Create a custom reward
          </Typography>
          <ChevronRightIcon color={JoonUIColor.semantic.primary} size={24} />
        </FlexBox>
      </HoverCard>
      <Typography variant="caption">Or..</Typography>
      <HoverCard fullWidth onClick={onOpenTemplatesClick}>
        <FlexBox
          justify="space-between"
          direction="row"
          align="center"
          wrap={false}
        >
          <FlexBox direction="column" gap={SPACING.space2} wrap={false}>
            <Typography
              variant="bodyBold"
              color={JoonUIColor.text.primaryAccent}
            >
              See what other parents are creating:
            </Typography>

            <FlexBox justify="space-around" gap={SPACING.space6}>
              <TemplateFigure
                text="Go out for ice cream"
                image="/images/icons/icecream.png"
              />
              <TemplateFigure
                text="Allowance"
                image="/images/icons/dollar-banknote.png"
              />
              <TemplateFigure
                text="Tablet screen time"
                image="/images/icons/phone-icon.png"
              />
            </FlexBox>
          </FlexBox>
          <ChevronRightIcon color={JoonUIColor.semantic.primary} size={24} />
        </FlexBox>
      </HoverCard>
    </FlexBox>
  )
}
