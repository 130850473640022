import { SPACING, Typography } from "@joonapp/web-shared"
import EmojiPicker from "emoji-picker-react"
import { useRef } from "react"
import { create } from "zustand"

import useOnClickOutside from "../../hooks/useOnClickOutside"

interface RewardEmojiPickerStore {
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export const useRewardEmojiPicker = create<RewardEmojiPickerStore>((set) => ({
  isOpen: false,
  onOpen: () => set({ isOpen: true }),
  onClose: () => set({ isOpen: false }),
}))

type Props = {
  emoji: string | null
}

const RewardEmojiPickerButton = ({ emoji }: Props) => {
  const { onOpen } = useRewardEmojiPicker()
  return (
    <div
      className="emoji-picker-button-group"
      style={{ alignItems: "flex-start" }}
    >
      <Typography variant="bodyBold">Reward Icon</Typography>
      <button
        className="emoji-picker-button"
        onClick={onOpen}
        style={{ marginTop: SPACING.space2 }}
      >
        {emoji ? (
          <div className="emoji">{emoji}</div>
        ) : (
          <div className="plus">+</div>
        )}
      </button>
    </div>
  )
}

const RewardEmojiPicker = ({ setEmoji }: { setEmoji: any }) => {
  const { isOpen, onClose } = useRewardEmojiPicker()
  const emojiPickerRef = useRef<any>()
  useOnClickOutside(emojiPickerRef, onClose)

  if (!isOpen) return <></>

  return (
    <>
      <div className="emoji-picker" ref={emojiPickerRef}>
        <EmojiPicker
          onEmojiClick={(val) => {
            onClose()
            setEmoji(val.emoji)
          }}
        />
      </div>
    </>
  )
}

export { RewardEmojiPicker, RewardEmojiPickerButton }
