import { FlexBox } from "@joonapp/web-shared"
import { useEffect } from "react"
import { isAndroid, isChrome } from "react-device-detect"
import TagManager from "react-gtm-module"

import CreatingAccountPageLoader from "./components/loading/CreatingAccountPageLoader"
import MobileNav from "./components/mobileNav/MobileNav"
import { useAddToHomescreenModal } from "./components/modals/AddToHomescreenModal"
import { useIOSModal } from "./components/modals/IOSModal"
import ModalProvider from "./components/modals/ModalProvider"
import { useSubModal } from "./components/modals/SubscriptionModal"
import Sidebar from "./components/sidebar"
import { CustomToaster } from "./components/toaster/Toaster"
import { useAnalytics } from "./hooks/useAnalytics"
import useMobile from "./hooks/useMobile"
import useOnboardContext, {
  usePaywall,
  useUpdateSubscriberAttributes,
} from "./hooks/useOnboardContext"
import { usePixelEvent } from "./hooks/usePixelEvent"
import {
  useFamilyQuery,
  useSubscriberInfo,
  useUserQuery,
} from "./networking/queries"
import NotFoundPage from "./pages/404"
import AcademyPage from "./pages/academy/AcademyPage"
import TrainingOverview from "./pages/academy/training/TrainingPage"
import AuthPage from "./pages/auth"
import BillingPage from "./pages/billing/BillingPage"
import CarePage from "./pages/care/CarePage"
import CheckoutPage from "./pages/checkout"
import CoachPage from "./pages/coach/CoachPage"
import SessionDetailsPage from "./pages/coach/sessions/SessionDetailsPage"
import CareMarketingPage from "./pages/coaching/careMarketing/CareMarketingPage"
import CoachingPage from "./pages/coaching/Coaching"
import FamilyMailboxPage from "./pages/family/familyMailbox/FamilyMailboxPage"
import FamilyPage from "./pages/family/FamilyPage"
import InvitePage from "./pages/invite/Invite"
import MePage from "./pages/me"
import OnboardAccountPage from "./pages/onboard"
import Paywall from "./pages/paywall/Paywall"
import PaywallMOF from "./pages/paywall/PaywallMOF"
import useDiscountPaywallRedirect from "./pages/paywall/useDailyPaywallRedirect"
import QuestBoard from "./pages/questBoard/QuestBoard"
import AllRewardTransactions from "./pages/rewards/AllRewardTransactions"
import CustomRewards from "./pages/rewards/CustomRewards"
import RewardLogDetailsPage from "./pages/rewards/redeem/RewardLogDetailsPage"
import SettingsPage from "./pages/settings/settings"
import SettingsAccount from "./pages/settings/SettingsAccount"
import SettingsSubscription from "./pages/settings/SettingsSubscription"
import WelcomePage from "./pages/welcome/Welcome"
import {
  ANALYTIC_EVENTS,
  FacebookPixelEvent,
  GAEvent,
  PinterestPixelEvent,
  TiktokPixelEvent,
  trackAnalyticEvent,
  trackGAEvent,
} from "./util/analytics"
import { useOnboardingExperiments } from "./util/experiments"
import { Route, Switch, useLocation, useRouter } from "./util/router"
import { localStorageItems, sessionManager } from "./util/storage"
import { getCurrentSubscription } from "./util/util"

const Routing = () => {
  const { user } = useUserQuery()
  const isLoggedIn = !!user
  const location = useLocation()
  const isMobile = useMobile()
  const isOnPaywallOrCoachingPage =
    location.pathname?.includes("/paywall") ||
    location.pathname?.includes("/coaching")
  const displayMobileNav = isLoggedIn && isMobile && !isOnPaywallOrCoachingPage
  const displaySidebar = isLoggedIn && !isMobile && !isOnPaywallOrCoachingPage
  const router = useRouter()
  const { onOpen: openSubModal } = useSubModal()
  const { onOpen: openIOSModal } = useIOSModal()
  const { onOpen: openAddToHomescreenModal } = useAddToHomescreenModal()
  const { data: family } = useFamilyQuery()
  const { isSubmittingData } = useOnboardContext()
  const { trackFBPixelEvent, trackTikTokPixelEvent, trackPinterestEvent } =
    usePixelEvent()
  const { data: subscriberInfo } = useSubscriberInfo()

  useAnalytics()
  usePaywall()
  useDiscountPaywallRedirect()
  useUpdateSubscriberAttributes()
  useOnboardingExperiments()

  const isOnMobileChrome = isChrome && isMobile && isAndroid

  // If the user has just paid, fire purchase complete event and clear query params
  // We may want to split up trial started and purchase completed --
  // TODO: see if there is a parameter that indicates this is a trial
  // Probably don't need this in routing file, and can add to routing hook
  useEffect(() => {
    if (!user || !family || !subscriberInfo) return
    if (router.query.paid) {
      const ttPixelParams = {
        content_type: "product",
        content_id: "100",
        quantity: 1,
        price: "89.99",
        value: "89.99",
        currency: "USD",
      }

      const currentSubscription = getCurrentSubscription(subscriberInfo)
      const hasTrial = currentSubscription.period_type === "trial"

      if (hasTrial) {
        trackFBPixelEvent(FacebookPixelEvent.START_TRIAL)
        trackTikTokPixelEvent(TiktokPixelEvent.START_TRIAL, ttPixelParams)
        trackPinterestEvent(PinterestPixelEvent.START_TRIAL)
        trackGAEvent(GAEvent.START_TRIAL, {
          value: 35,
          currency: "USD",
          transaction_id: "",
        })
      } else {
        trackFBPixelEvent(FacebookPixelEvent.PURCHASE_SUBSCRIPTION)
        trackTikTokPixelEvent(
          TiktokPixelEvent.PURCHASE_SUBSCRIPTION,
          ttPixelParams
        )
      }

      trackAnalyticEvent(ANALYTIC_EVENTS.PURCHASE_COMPLETED, {
        priceId: router.query.priceId,
        tpk: router.query.tpk,
        campaign: router.query.campaign,
      })

      TagManager.dataLayer({
        dataLayer: {
          event: "conversion",
          send_to: "AW-343454768/Nop5CLndlNUDELDo4qMB",
          value: 90.0,
          currency: "USD",
          transaction_id: "",
        },
      })
      sessionManager.clearNeedsToSeeVerifyFreemiumPopup()
      if (localStorage.getItem(localStorageItems.stripeFromPaywall)) {
        router.push("/coaching")
        localStorage.removeItem(localStorageItems.stripeFromPaywall)
      } else {
        router.push("/me")
        if (localStorage.getItem(localStorageItems.showDownloadAppBanner)) {
          if (isOnMobileChrome) openAddToHomescreenModal()
          else openIOSModal()
        }
      }
      openSubModal()
    }
    // If user cancels from stripe payment page
    else if (router.query.cancelledPayment) {
      trackAnalyticEvent(ANALYTIC_EVENTS.PURCHASE_CANCELLED, {
        priceId: router.query.priceId,
        tpk: router.query.tpk,
        campaign: router.query.campaign,
      })
      if (localStorage.getItem(localStorageItems.stripeFromPaywall)) {
        router.push("/coaching")
        localStorage.removeItem(localStorageItems.stripeFromPaywall)
      } else {
        router.push("/me")
        if (localStorage.getItem(localStorageItems.showDownloadAppBanner)) {
          if (isOnMobileChrome) openAddToHomescreenModal()
          else openIOSModal()
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    router,
    user,
    family,
    openSubModal,
    openIOSModal,
    subscriberInfo,
  ])

  if (isSubmittingData) return <CreatingAccountPageLoader />

  return (
    <>
      <ModalProvider />
      <CustomToaster />
      <FlexBox
        wrap={false}
        align="unset"
        justify="unset"
        direction={displayMobileNav || !isLoggedIn ? "column" : "row"}
        style={{ minWidth: "100vw" }}
      >
        {displaySidebar && <Sidebar />}
        {displayMobileNav && <MobileNav />}
        <div style={{ flexGrow: 1 }}>
          <Switch>
            <Route exact path="/" component={MePage} />
            <Route exact path="/care" component={CarePage} />
            <Route
              exact
              path="/care/:careTeamUserId/academy"
              component={AcademyPage}
            />
            <Route
              exact
              path="/care/:careTeamUserId/academy/:id"
              component={TrainingOverview}
            />
            <Route exact path="/coach/" component={CareMarketingPage} />
            <Route exact path="/coach/:careTeamUserId" component={CoachPage} />
            <Route
              exact
              path="/coach/:careTeamUserId/:coachTab"
              component={CoachPage}
            />
            <Route
              exact
              path="/coach/:careTeamUserId/:coachTab/:sessionId"
              component={SessionDetailsPage}
            />
            <Route exact path="/quests" component={QuestBoard} />
            <Route exact path="/family" component={FamilyPage} />
            <Route exact path="/family/mailbox" component={FamilyMailboxPage} />
            <Route exact path="/me" component={MePage} />
            <Route exact path="/auth/:type" component={AuthPage} />
            <Route exact path="/settings" component={SettingsPage} />
            <Route path="/settings/account" component={SettingsAccount} />
            <Route
              path="/settings/subscription"
              component={SettingsSubscription}
            />
            <Route exact path="/welcome" component={WelcomePage} />
            <Route exact path="/welcome-tt" component={WelcomePage} />
            <Route exact path="/welcome-fb" component={WelcomePage} />
            <Route exact path="/checkout" component={CheckoutPage} />
            <Route exact path="/billing" component={BillingPage} />
            <Route exact path="/coaching" component={CoachingPage} />
            <Route exact path="/invite" component={InvitePage} />
            <Route exact path="/onboard" component={OnboardAccountPage} />
            <Route exact path="/paywall/trial" component={Paywall} />
            <Route exact path="/paywall" component={PaywallMOF} />
            <Route exact path="/rewards" component={CustomRewards} />
            <Route
              exact
              path="/rewards/transactions"
              component={AllRewardTransactions}
            />
            <Route
              exact
              path="/rewards/details/:rewardId"
              component={RewardLogDetailsPage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </div>
      </FlexBox>
    </>
  )
}

export default Routing
