import {
  FamilyParentProfile,
  FamilyChildProfile,
  ChildUser,
  Family,
} from "@joonapp/web-shared"
import { useMutation, useQueryClient } from "@tanstack/react-query"

import { useManageGameplayModalStore } from "./useManageGameplayModalStore"
import { PREFERENCE_KEY, QUERY_KEYS } from "../../../constants"
import { useFamilyQuery } from "../../../networking/queries"
import {
  togglePause,
  toggleRoutineTimeLimits,
  updateGamePreference,
} from "../../../networking/user"
import { getUserFromFamily } from "../../../util/util"

export const useManageGameplay = () => {
  const { childId } = useManageGameplayModalStore()
  const { data: family } = useFamilyQuery()
  const child = getUserFromFamily(family!, childId || 0) as ChildUser

  const queryClient = useQueryClient()

  const updatePreferenceMutation = useMutation({
    mutationFn: updateGamePreference,
    onMutate: async ({ key }) => {
      // cancel the query to prevent it from updating with the optimistic response
      await queryClient.cancelQueries([QUERY_KEYS.FAMILY])
      // store the previous family data
      const previousFamily = queryClient.getQueryData([
        QUERY_KEYS.FAMILY,
      ]) as Family
      // create new family data with the new preference
      const newFamily = {
        ...previousFamily,
        profiles: previousFamily?.profiles?.map(
          (profile: FamilyParentProfile | FamilyChildProfile) => {
            if (profile?.user?.id !== child?.id) return profile
            profile = profile as FamilyChildProfile
            return {
              ...profile,
              user: {
                ...profile.user,
                preferences: {
                  ...profile.user.preferences,
                  [key]: !child?.preferences[key],
                },
              },
            }
          }
        ),
      }
      // update the query data with the new family data
      queryClient.setQueryData([QUERY_KEYS.FAMILY], newFamily)
      return { previousFamily }
    },
    // if there is an error, revert the query data to the previous family data
    onError: (_err, _family, context) => {
      queryClient.setQueryData([QUERY_KEYS.FAMILY], context?.previousFamily)
    },
    // invalidate the query to refetch the data
    onSettled: () => queryClient.invalidateQueries([QUERY_KEYS.FAMILY]),
  })

  const updatePreference = async (
    key: (typeof PREFERENCE_KEY)[keyof typeof PREFERENCE_KEY],
    value: any,
    onSuccess?: () => void
  ) => {
    return updatePreferenceMutation.mutate(
      { childId: child.id, key, value },
      {
        onSuccess,
      }
    )
  }

  const toggleRoutineTimeLimitsMutation = useMutation({
    mutationFn: toggleRoutineTimeLimits,
    onMutate: async ({ value }) => {
      await queryClient.cancelQueries([QUERY_KEYS.FAMILY])
      const previousFamily = queryClient.getQueryData([
        QUERY_KEYS.FAMILY,
      ]) as Family
      const newFamily = {
        ...previousFamily,
        profiles: previousFamily?.profiles?.map(
          (profile: FamilyParentProfile | FamilyChildProfile) => {
            if (profile?.user?.id !== child?.id) return profile
            profile = profile as FamilyChildProfile
            return {
              ...profile,
              user: {
                ...profile.user,
                preferences: {
                  ...profile.user.preferences,
                  afternoon_limit: value,
                  morning_limit: value,
                  night_limit: value,
                },
              },
            }
          }
        ),
      }
      queryClient.setQueryData([QUERY_KEYS.FAMILY], newFamily)
      return { previousFamily }
    },
    onError: (_err, _family, context) => {
      queryClient.setQueryData([QUERY_KEYS.FAMILY], context?.previousFamily)
    },
    onSettled: () => queryClient.invalidateQueries([QUERY_KEYS.FAMILY]),
  })

  const togglePauseMutation = useMutation({
    mutationFn: togglePause,
    onMutate: async () => {
      await queryClient.cancelQueries([QUERY_KEYS.FAMILY])
      const previousFamily = queryClient.getQueryData([
        QUERY_KEYS.FAMILY,
      ]) as Family
      const newFamily = {
        ...previousFamily,
        profiles: previousFamily?.profiles?.map(
          (profile: FamilyParentProfile | FamilyChildProfile) => {
            if (profile?.user?.id !== child?.id) return profile
            profile = profile as FamilyChildProfile
            return {
              ...profile,
              user: {
                ...profile.user,
                active_doter: {
                  ...profile.user.active_doter,
                  is_paused: !profile.user.active_doter.is_paused,
                },
              },
            }
          }
        ),
      }
      queryClient.setQueryData([QUERY_KEYS.FAMILY], newFamily)
      return { previousFamily }
    },
    onError: (_err, _family, context) => {
      queryClient.setQueryData([QUERY_KEYS.FAMILY], context?.previousFamily)
    },
    onSettled: () => queryClient.invalidateQueries([QUERY_KEYS.FAMILY]),
  })

  const hasAnyRoutineTimeLimits =
    child?.preferences.morning_limit ||
    child?.preferences.afternoon_limit ||
    child?.preferences.night_limit

  return {
    child,
    hasAnyRoutineTimeLimits,
    toggleRoutineTimeLimitsMutation,
    togglePauseMutation,
    updatePreferenceMutation,
    updatePreference,
  }
}
